import { useState } from 'react';

const usePasswordVisibility = (fields) => {
  const [showPasswordValues, setShowPasswordValues] = useState(fields);

  const handlePasswordVisible = (fieldName) => {
    setShowPasswordValues((prevState) => ({
      ...prevState,
      [fieldName]: !prevState[fieldName],
    }));
  };

  return { showPasswordValues, handlePasswordVisible };
};

export default usePasswordVisibility;
