import { Navigate } from 'react-router-dom';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const RegistrationList = Loader(
  retryLazy(() =>
    import('src/pages/maintenance/DeviceMaintenance/layout/RegistrationList'),
  ),
);

const RegistrationDetail = Loader(
  retryLazy(() =>
    import('src/pages/maintenance/DeviceMaintenance/layout/RegistrationDetail'),
  ),
);

const ResultList = Loader(
  retryLazy(() =>
    import('src/pages/maintenance/DeviceMaintenance/layout/ResultList'),
  ),
);

const ResultDetail = Loader(
  retryLazy(() =>
    import('src/pages/maintenance/DeviceMaintenance/layout/ResultDetail'),
  ),
);

const RegularMaintenanceList = Loader(
  retryLazy(() =>
    import('src/pages/maintenance/RegularMaintenance/layout/List'),
  ),
);

const RegularMaintenanceDetail = Loader(
  retryLazy(() =>
    import('src/pages/maintenance/RegularMaintenance/layout/Detail'),
  ),
);

const PartsList = Loader(
  retryLazy(() => import('src/pages/maintenance/PartsList')),
);

const maintenanceRoutes = [
  {
    path: '',
    element: <Navigate to="dashboard" replace />,
  },
  {
    path: 'device-maintenance/registration/list',
    element: <RegistrationList />,
  },
  {
    path: 'device-maintenance/registration',
    element: <RegistrationDetail />,
  },
  {
    path: 'device-maintenance/registration/:type/:serial/:logId',
    element: <RegistrationDetail />,
  },
  {
    path: 'device-maintenance/result/list',
    element: <ResultList />,
  },
  {
    path: 'device-maintenance/result/:type/:serial/:logId',
    element: <ResultDetail />,
  },
  {
    path: 'regular-maintenance/list',
    element: <RegularMaintenanceList />,
  },
  {
    path: 'regular-maintenance/:type/:serial/:historyId',
    element: <RegularMaintenanceDetail />,
  },
  {
    path: 'parts',
    element: <PartsList />,
  },
];

export default maintenanceRoutes;
