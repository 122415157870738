import { AiFillAlert, AiFillNotification } from 'react-icons/ai';
import { FaCheck, FaExclamation } from 'react-icons/fa';

export const AlertIconList = {
  alert: <AiFillAlert />, // 경고
  error: <AiFillAlert />, // 에러
  warning: <FaExclamation />, // 주의
  confirm: <FaCheck />, // 확인
  success: <FaCheck />, // 성공
  notice: <AiFillNotification />, // 알림
};

export default AlertIconList;
