import { styled } from '@mui/material';
import Hero from './components/sections/Hero';
import FeaturesTiles from './components/sections/FeaturesTiles';
import Footer from './components/layout/Footer';

export const InfoHero = styled(Hero)(
  ({ theme }) => `
    background: ${theme.palette.background.paper};
      `,
);

export const InfoFeaturesTiles = styled(FeaturesTiles)(
  ({ theme }) => `
      background: ${theme.palette.background.paper};
        `,
);

export const InfoFooter = styled(Footer)(
  ({ theme }) => `
        background: ${theme.palette.background.paper};
          `,
);

export const InfoMain = styled(`main`)(
  ({ theme }) => `
    background: ${theme.palette.background.paper};
      `,
);
