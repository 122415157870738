import axios from 'src/utils/axios';

export const getCompanyInfo = async () => {
  const response = await axios.get(`evss/company`);

  const companyInfo = response.data;
  return companyInfo;
};

export const createNewCompany = async (
  name,
  businessNumber,
  contactEmail,
  contactNumber,
  contactNumberCountry,
  exContactNumber,
  exContactNumberCountry,
  address,
  addressDetail,
  zipCode,
  lat,
  lon,
  homepageUrl,
  companyPassword,
  countryCode,
) => {
  const response = await axios.post(`evss/company`, {
    name,
    contactNumber,
    contactNumberCountryCode: contactNumberCountry,
    exContactNumber,
    exContactNumberCountryCode: exContactNumberCountry,
    contactEmail,
    businessNumber,
    location: {
      address,
      addressDetail,
      zipCode,
      lat,
      lon,
      countryCode,
    },
    confirmPassword: companyPassword,
    newPassword: companyPassword,
    homepageUrl,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const inviteUser = async (userToken) => {
  const response = await axios.post(`evss/company/check/invite-url`, {
    token: userToken,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const addEmployeeToCompany = async (companyId) => {
  const response = await axios.patch(`evss/user/join/company`, {
    keyValue: companyId,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const getCompanyInviteLink = async () => {
  const response = await axios.get(`evss/company/invite-url`);
  return response.data.inviteUrl;
};

export const updateCompanyInfo = async (
  name,
  businessNumber,
  contactEmail,
  contactNumber,
  contactNumberCountry,
  exContactNumber,
  exContactNumberCountry,
  address,
  addressDetail,
  zipCode,
  lat,
  lon,
  sido,
  sigun,
  countryCode,
  homepageUrl,
  password,
) => {
  await axios.patch('evss/company', {
    name,
    businessNumber,
    contactEmail,
    contactNumber,
    contactNumberCountryCode: contactNumberCountry,
    exContactNumber,
    exContactNumberCountryCode: exContactNumberCountry,
    location: {
      address,
      addressDetail,
      zipCode,
      lat,
      lon,
      countryCode,
      sido,
      sigun,
    },
    homepageUrl,
    password: password,
  });
};

export const updateCompanyPassword = async (
  companypassword,
  newCompanyPassword,
) => {
  await axios.patch('evss/company/password', {
    password: companypassword,
    newPassword: newCompanyPassword,
    confirmPassword: newCompanyPassword,
  });
};

export const generateResetCompanyPasswordEmail = async (language) => {
  await axios.post('evss/user-service/find/company-password', {
    language: language,
  });
};

export const updateCompanyPasswordByEmail = async (
  password,
  verificationCode,
) => {
  await axios.patch('evss/user-service/check/company-password', {
    newPassword: password,
    confirmPassword: password,
    verificationCode: verificationCode,
  });
};

export const deleteCompanyUser = async (userId, password) => {
  const response = await axios.post(`evss/company/user/delete/${userId}`, {
    password: password,
  });

  if (password) {
    const accessToken = response.data.accessToken;
    return accessToken;
  }

  return true;
};

export const updateCompanyUserRole = async (userId, role) => {
  await axios.post('evss/company/role', {
    id: userId,
    role: role,
  });
};

export const getCompanyUsers = async () => {
  const response = await axios.get(`evss/user/list`);
  const userList = response.data;
  return userList;
};

export const deleteCompany = async (userPassword, companyPassword) => {
  const response = await axios.post(`evss/company/delete`, {
    password: companyPassword,
    userPassword: userPassword,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const postNewCompanyUserApi = async (
  username,
  email,
  phone,
  countryCode,
  timeZone,
  newPassword,
  confirmPassword,
) => {
  const response = await axios.post(`evss/company/signup/user`, {
    username,
    email,
    phone,
    countryCode,
    timeZone,
    newPassword,
    confirmPassword,
  });

  return response;
};

export const postNewCompanyAndAdminApi = async (group, user, password) => {
  const response = await axios.post(`evss/company/generate/group`, {
    group,
    user,
    password,
  });

  return response;
};
