import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { HeroH1, HeroH5, HeroSpan } from '../style';
import { observer } from 'mobx-react-lite';
import {
  Box,
  styled,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const FeaturesIcon = styled(Box)(
  () => `
        height: auto;
        width: 100%;
        text-align: center;
    
        img {
          max-width: 158px;
        }
    `,
);

const FeaturesIconPage = observer(
  ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div
              className="hero-content reveal-from-bottom"
              data-reveal-delay="450"
            >
              <HeroH1 className="mt-0 mb-8 ">
                The most flexible <HeroSpan>energy platform</HeroSpan> for your
                business
              </HeroH1>
              <Grid
                container
                spacing={mobile ? 2 : 5}
                style={{ marginTop: '3rem' }}
              >
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/collect-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  <HeroH5>Collect and control</HeroH5>
                  <Typography variant="subtitle1" wrap>
                    Connect and manage IoT devices via the cloud using graphical
                    UI or REST API. Collect and visualize telemetry using a
                    built-in dashboard or 3rd party tools like Grafana.
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/fast-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  <HeroH5>Fast, scalable, modern</HeroH5>
                  <Typography variant="subtitle1" wrap>
                    With IoT microservices architecture baked by Kubernetes, you
                    can scale infinitely, scale only what is needed, all with
                    extreme performance and fault-tolerance.
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/get-insight-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  <HeroH5>Get insights from your data</HeroH5>
                  <Typography variant="subtitle1" wrap>
                    Stream data from your IoT devices to any data analytics
                    system via pre-integrated Kafka channel. Change device
                    behavior based on processing results.
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/created-expert-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  <HeroH5>Created by experts</HeroH5>
                  <Typography variant="subtitle1" wrap>
                    The company behind the platform has over 25 years of
                    experience in enegry, Telecom and Big Data. We used all our
                    profound knowledge to create the best Enegry Platform that
                    can serve big enterprises as well as small startups.
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/security-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  <HeroH5>Security & User Management</HeroH5>
                  <Typography variant="subtitle1" wrap>
                    Your data is encrypted in transit and at rest. Out-of-box
                    authentication & authorization for users with open OAuth2 /
                    UMA interface.
                  </Typography>
                </Grid>
                <Grid item xs={6} sm={6} md={4}>
                  <FeaturesIcon>
                    <img
                      style={{
                        width: '8rem',
                        height: '8rem',
                      }}
                      src={'/static/images/info/full-control-1.svg'}
                      alt="FeaturesIcon"
                    />
                  </FeaturesIcon>
                  <HeroH5>Full control</HeroH5>
                  <Typography variant="subtitle1" wrap>
                    You choose where to host the enegry platform: in the public
                    cloud, on your premises or even in both places at the same
                    time (multicloud).
                  </Typography>
                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </section>
    );
  },
);

FeaturesIconPage.propTypes = propTypes;
FeaturesIconPage.defaultProps = defaultProps;

export default FeaturesIconPage;
