import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/mobx-store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  Card,
} from '@mui/material';
import AlertIconList from 'src/assets/icons/AlertIconList';
import { AlertDialog } from './style';

const ModalAlert = observer(({ message }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { alertStore } = useStore();
  const { type, linkTo, setAlertClose } = alertStore;

  const handleModalAlertClose = () => {
    setAlertClose();
    if (linkTo) navigate(linkTo);
  };

  return (
    <AlertDialog open fullWidth minWidth="lg" type={type}>
      <DialogContent dividers>
        <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
          <Card
            component={'span'}
            className="alert_type_icon"
            variant="outlined"
          >
            {AlertIconList[type]}
          </Card>
          <Typography variant="h4" className="alert_message">
            {message}
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleModalAlertClose}>
          {t('확인')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );
});

export default ModalAlert;
