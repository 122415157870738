export const COMM_STATUS = {
  TIMEOUT: 0,
  NORMAL: 1,
};

export const CONTROL_STATUS = {
  NOT_RECEIVED: -1,
  LOADING: 0,
  TIMEOUT: 1,
  IDLE: 2,
};

export const AUTH_STATUS = {
  UNVERIFIED: 0,
  VERIFIED: 1,
  EXPIRED: 2,
};

export const CONTROL_VALUE = {
  DISABLED: -1,
  REQUEST: 1,
};

export const COLORS = {
  red: '#FF0000',
  blue: '#0000FF',
  yellow: '#FFFF00',
  green: '#00FF00',
  orange: '#FFA500',
};

export const EXTERNAL_LINK = {
  TERMS_OF_SERVICE_URL:
    'https://real-blade-30f.notion.site/b1461b898f0d4bdd862252e9f3319bfe?pvs=4',

  PRIVACY_POLICY_URL:
    'https://real-blade-30f.notion.site/7e2fc4901f4144d18948830eb48536ad?pvs=4',
};
