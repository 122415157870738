import { createContext, useContext } from 'react';
import AlertStore from './alert/alertStore';
import AccountStore from './account/accountStore';
import CompanyStore from './company/companyStore';
import DeviceStore from './device/deviceStore';
import EdmuStore from './device/edmuStore';
import DashBoardStore from './dashboard/dashboardStore';
import DeviceSummaryStore from './deviceSummary/deviceSummaryStore';
import DeviceDrawingStore from './deviceDrawing/deviceDrawingStore';
import UtilStore from './util/utilStore';
import WeatherStore from './weather/weatherStore';
import LocationStore from './location/locationStore';
import ManufacturingStore from './manufacturing/manufacturingStore';
import MaintenanceStore from './maintenance/maintenanceStore';
import ProductStore from './product/productStore';
import InspectionStore from './inspection/inspectionStore';

const utilStore = new UtilStore();
const alertStore = new AlertStore();
const accountStore = new AccountStore({ alertStore });
const companyStore = new CompanyStore({ alertStore });
const deviceStore = new DeviceStore({ accountStore, alertStore, utilStore });
const edmuStore = new EdmuStore({ alertStore, utilStore, deviceStore });
const dashboardStore = new DashBoardStore({ alertStore, utilStore });
const deviceSummaryStore = new DeviceSummaryStore({ alertStore, deviceStore });
const deviceDrawingStore = new DeviceDrawingStore({
  accountStore,
  alertStore,
  deviceStore,
  utilStore,
});
const weatherStore = new WeatherStore({ alertStore });
const locationStore = new LocationStore({ alertStore });
const manufacturingStore = new ManufacturingStore({ alertStore });
const maintenanceStore = new MaintenanceStore({ accountStore, alertStore });
const productStore = new ProductStore({ accountStore, alertStore });
const inspectionStore = new InspectionStore({ accountStore, alertStore });

export const store = {
  utilStore,
  alertStore,
  accountStore,
  companyStore,
  deviceStore,
  edmuStore,
  dashboardStore,
  deviceSummaryStore,
  deviceDrawingStore,
  weatherStore,
  locationStore,
  manufacturingStore,
  maintenanceStore,
  productStore,
  inspectionStore,
};

export const StoreContext = createContext(store);

export const getStore = () => {
  return StoreContext._currentValue;
};

export const useStore = () => {
  return useContext(StoreContext);
};

export const resetStore = () => {
  accountStore.initializeAccount();
  companyStore.initializeCompany();
  deviceSummaryStore.initializeSummary();
  deviceStore.initializeDevices();
  dashboardStore.initializeDashboard();
};

export const authRefresh = () => {
  accountStore.getProfile();
  companyStore.initializeCompany();
  deviceSummaryStore.initializeSummary();
  deviceStore.initializeDevices();
  dashboardStore.initializeDashboard();
};
