import {
  IconButton,
  Avatar,
  MenuList,
  Box,
  Typography,
  styled,
  alpha,
} from '@mui/material';

export const UserBoxButton = styled(IconButton)(
  ({ theme }) => `
    width: ${theme.spacing(4)};
    padding: 0;
    height: ${theme.spacing(4)};
    border-radius: ${theme.general.borderRadiusLg};
    
    &:hover {
      background: ${theme.colors.primary.main};
    }
  `,
);

export const UserAvatar = styled(Avatar)(
  ({ theme }) => `
    height: 90%;
    width: 90%;
    border-radius: ${theme.general.borderRadiusLg};
  `,
);

export const HeaderButtonsBox = styled(Box)(
  ({ theme }) => `
    display: flex !important;
    
    .desktop {
      @media(max-width : 599px) {
        display: none !important;
      }
    }

    .mobile {
      display: none !important;
      @media(max-width : 599px) {
        display: inline-block !important;
      }
    }

    .trial_msg_box {
      display: flex;
      padding: 8px 18px;
      margin-right: 18px;
      height: 36px;
      background: ${
        theme.palette.mode === 'dark'
          ? theme.colors.alpha.trueWhite[10]
          : alpha(theme.colors.alpha.black[100], 0.9)
      };
      border-radius: ${theme.general.borderRadiusLg};
      color: ${theme.palette.primary.contrastText};
    }

    .upgrade_button {
      margin-left: 9px;
      border-radius: 5px;
      :active {
        opacity: 0.7;
      }
      @media(max-width : 599px) {
        height: 36px;
        padding: 6px 18px;
        margin-right: 18px;
        border-radius: ${theme.general.borderRadiusLg};
      }
    }
  `,
);

export const MenuUserBox = styled(Box)(
  ({ theme }) => `
    min-width: 210px;
    background: ${alpha(theme.colors.alpha.black[100], 0.08)};
    padding: ${theme.spacing(2)};
  `,
);

export const UserBoxText = styled(Box)(
  ({ theme }) => `
    text-align: left;
    padding-left: ${theme.spacing(1)};

    a {
      text-decoration: none;
      &:hover {
        text-decoration: underline;
      }
      &:active {
        color: ${theme.palette.secondary.light}
      }
    }
  `,
);

export const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
    font-weight: ${theme.typography.fontWeightBold};
    color: ${theme.palette.secondary.main};
    display: block;
  `,
);

export const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
    color: ${theme.palette.secondary.light}
  `,
);

export const MenuListWrapper = styled(MenuList)(
  ({ theme }) => `
  padding: ${theme.spacing(1)};

  & .MuiMenuItem-root {
      border-radius: 50px;
      padding: ${theme.spacing(1, 1, 1, 2.5)};
      min-width: 200px;
      margin-bottom: 2px;
      position: relative;
      color: ${theme.colors.alpha.black[100]};

      &.Mui-selected,
      &:hover,
      &.MuiButtonBase-root:active {
          background: ${theme.colors.primary.lighter};
          color: ${theme.colors.primary.main};
      }

      &:last-child {
          margin-bottom: 0;
      }
    }

  .MuiSvgIcon-root {
    color: ${theme.colors.alpha.black[30]};
    opacity: 0.8;
  }
`,
);
