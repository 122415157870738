import { useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
// import { Link as RouterLink } from 'react-router-dom';
import { Button, TextField, CircularProgress } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import useRefMounted from 'src/hooks/useRefMounted';
import usePasswordVisibility from 'src/utils/customHooks/usePasswordVisibility';
import { useTranslation } from 'react-i18next';
import { useStore } from 'src/mobx-store';
import { validationMessage } from 'src/utils/validationMessage';
import { LoginHelperText } from '../../style';
import PasswordField from 'src/components/PasswordField';

function LoginFirebaseAuth() {
  const { signInCustomToken, getToken } = useAuth();
  const isMountedRef = useRefMounted();
  const { t } = useTranslation();
  const [loginErrorMessage, setLoginErrorMessage] = useState('');
  const { accountStore } = useStore();
  const { submitSignIn } = accountStore;
  const { showPasswordValues, handlePasswordVisible } = usePasswordVisibility({
    userPassword: false,
  });

  const handleFormSubmit = async (
    values,
    { setErrors, setStatus, setSubmitting, setFieldValue },
  ) => {
    try {
      const token = await submitSignIn(values.phone, values.password);
      if (token) {
        await signInCustomToken(token);
        const idToken = await getToken();
        localStorage.setItem('accessToken', idToken);
      }

      if (isMountedRef.current) {
        setStatus({ success: true });
        setSubmitting(false);
      }
    } catch (error) {
      if (error.response.data[0].code === '20020') {
        setFieldValue('password', '', false);
      } else {
        setFieldValue('email', '', false);
        setFieldValue('password', '', false);
      }
      setLoginErrorMessage(validationMessage(error.response?.data?.[0]?.code));

      if (isMountedRef.current) {
        setStatus({ success: false });
        setErrors({ submit: error.message });
        setSubmitting(false);
      }
    }
  };

  const handleTextFieldChange =
    (name, setTouched, touched, handleChange) => (e) => {
      setTouched({ ...touched, [name]: true });
      handleChange(e);
    };

  const handleTextFieldBlur = (name, handleBlur, touched) => (e) => {
    if (touched[name]) {
      handleBlur(e);
    }
  };

  // const { signInWithGoogle } = useAuth();
  // const handleGoogleClick = async () => {
  //   try {
  //     await signInWithGoogle();
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <>
      {/* <Button
        fullWidth
        onClick={handleGoogleClick}
        size="large"
        variant="outlined"
      >
        <ImgWrapper alt="Google" src="/static/images/logo/google.svg" />
        Sign in with Google
      </Button>
      <Divider
        sx={{
          mt: 4,
          mb: 2,
        }}
      >
        {t('or')}
      </Divider> */}
      <Formik
        initialValues={{
          // email: '',
          phone: '',
          password: '',
          submit: null,
        }}
        validationSchema={Yup.object().shape({
          // email: Yup.string()
          //   .email('올바른 이메일을 입력해 주세요')
          //   .max(50, '이메일 주소의 길이가 제한된 길이를 초과하였습니다')
          //   .required('필수 정보입니다'),
          phone: Yup.string().max(11, '유효한 휴대 전화번호를 입력해 주세요'),
          password: Yup.string().required('필수 정보입니다'),
        })}
        onSubmit={handleFormSubmit}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          isSubmitting,
          touched,
          values,
          setTouched,
          status,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {/* <TextField
              className="text_content"
              error={Boolean(touched.email && errors.email)}
              fullWidth
              helperText={t(touched.email && errors.email)}
              label={t('이메일')}
              placeholder={t('이메일을 입력해 주세요')}
              margin="normal"
              name="email"
              onBlur={handleTextFieldBlur('email', handleBlur, touched)}
              onChange={handleTextFieldChange(
                'email',
                setTouched,
                touched,
                handleChange,
              )}
              type="email"
              value={values.email}
              variant="outlined"
              autoComplete="username"
              InputLabelProps={{
                shrink: true,
              }}
            /> */}
            <TextField
              className="text_content"
              error={Boolean(touched.phone && errors.phone)}
              fullWidth
              helperText={t(touched.phone && errors.phone)}
              label={t('휴대전화 번호')}
              placeholder={t('휴대전화 번호를 입력해 주세요')}
              margin="normal"
              name="phone"
              onBlur={handleTextFieldBlur('phone', handleBlur, touched)}
              onChange={handleTextFieldChange(
                'phone',
                setTouched,
                touched,
                handleChange,
              )}
              type="text"
              value={values.phone}
              variant="outlined"
              autoComplete="username"
              InputLabelProps={{
                shrink: true,
              }}
            />
            <PasswordField
              key={t('password-helper-text')}
              className="text_content"
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={t(touched.password && errors.password)}
              label={t('비밀번호')}
              placeholder={t('비밀번호를 입력해 주세요')}
              margin="normal"
              name="password"
              onBlur={handleTextFieldBlur('password', handleBlur, touched)}
              onChange={handleTextFieldChange(
                'password',
                setTouched,
                touched,
                handleChange,
              )}
              value={values.password}
              variant="outlined"
              autoComplete="current-password"
              InputLabelProps={{
                shrink: true,
              }}
              showPassword={showPasswordValues.userPassword}
              handleToggle={() => handlePasswordVisible('userPassword')}
            />
            {/* <Box alignItems="center" display="flex">
              <Link
                className="find_button"
                component={RouterLink}
                to="/account/recover-email"
              >
                <b className="text_content">{t('이메일 찾기')}</b>
              </Link>
              <Typography className="link_divider">{'|'}</Typography>
              <Link
                className="find_button"
                component={RouterLink}
                to="/account/recover-password"
              >
                <b className="text_content">{t('비밀번호 찾기')}</b>
              </Link>
            </Box> */}
            <LoginHelperText error>{t(loginErrorMessage)}</LoginHelperText>
            <Button
              className="text_content submit_button"
              color="primary"
              startIcon={
                isSubmitting || status?.success ? (
                  <CircularProgress size="1rem" />
                ) : null
              }
              disabled={isSubmitting || status?.success}
              size="large"
              fullWidth
              type="submit"
              variant="contained"
            >
              {t('로그인')}
            </Button>
          </form>
        )}
      </Formik>
    </>
  );
}

export default LoginFirebaseAuth;
