import { ParameterInfo as ThreePhaseSolarParameterInfo } from './ThreePhaseSolar';
import { ParameterInfo as TemperatureControllerParameterInfo } from './TemperatureController';

import { FirebaseConfig as ThreePhaseSolarFirebaseConfig } from './ThreePhaseSolar';
import { FirebaseConfig as TemperatureControllerFirebaseConfig } from './TemperatureController';

export const GetDataConfig = {
  'three-phase-solar': {
    sensors: [
      {
        statDate: { type: 'current' },
        endDate: { type: 'current' },
        parameter: 'CVI,AGE,EFF',
        period: 5,
        processType: 'apexChart',
        limit: 13,
        sortType: 'desc',
        includeNull: false,
        reverse: true,
      },
      {
        statDate: { type: 'start' },
        endDate: { type: 'end' },
        parameter: 'PGE',
        period: 'day',
        processType: 'apexChart',
        limit: 31,
        sortType: 'asc',
        includeNull: true,
        reverse: false,
      },
    ],
  },
  'temperature-controller': {
    sensors: [
      {
        statDate: { type: 'current' },
        endDate: { type: 'current' },
        parameter: 'S1,S2,S3,S4',
        period: 5,
        processType: 'apexChart',
        limit: 13,
        sortType: 'desc',
        includeNull: false,
        reverse: true,
      },
    ],
  },
};

export const ParameterInfo = {
  'three-phase-solar': ThreePhaseSolarParameterInfo,
  'temperature-controller': TemperatureControllerParameterInfo,
};

export const FirebaseConfig = {
  'three-phase-solar': ThreePhaseSolarFirebaseConfig,
  'temperature-controller': TemperatureControllerFirebaseConfig,
};

export default ParameterInfo;
