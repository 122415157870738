import { makeAutoObservable, runInAction } from 'mobx';
import {
  postNewInspectionHistory,
  getAllInspectionHistories,
  getInspectionHistoryById,
  patchInspectionHistory,
  deleteInspectionHistory,
  postInspectionImage,
  deleteInspectionImage,
  patchCheckListApi,
} from 'src/api/inspection';

class inspectionStore {
  accountStore;

  alertStore;

  inspectionHistories = [];

  inspectionInfo = {
    id: null,
    title: null,
    userId: null,
    deviceId: null,
    deviceData: {
      id: null,
      nickname: null,
      serialNumber: null,
      location: {
        id: null,
        name: null,
        type: null,
        address: null,
        addressDetail: null,
        lat: null,
        lon: null,
        zipCode: null,
      },
      type: {
        id: null,
        name: null,
      },
    },
    reporter: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countryCode: null,
      code: null,
    },
    worker: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countryCode: null,
      code: null,
    },
    maintenanceDate: null,
    reportDate: null,
    description: null,
    images: [],
  };

  startIndex = 0;

  endIndex = 1;

  selectedReportDate = [null, null];

  selectedMaintenanceDate = [null, null];

  constructor({ accountStore, alertStore }) {
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
  }

  initializeInspectionInfo = () => {
    this.inspectionInfo = {
      id: null,
      userId: null,
      title: null,
      deviceId: null,
      deviceData: {
        id: null,
        nickname: null,
        serialNumber: null,
        location: {
          id: null,
          name: null,
          type: null,
          address: null,
          addressDetail: null,
          lat: null,
          lon: null,
          zipCode: null,
        },
        type: {
          id: null,
          name: null,
        },
      },
      workerName: null,
      workerEmail: null,
      workerPhone: null,
      workerCountryCode: null,
      serviceType: null,
      reportDate: null,
      dispatchDate: null,
      issueDescription: null,
      actionTaken: null,
      description: null,
      images: [],
    };
  };

  createNewInspectionHistories = async (newHistories) => {
    try {
      const createMaintenanceLogs = newHistories.map((eachHistoryInfo) => {
        return postNewInspectionHistory({
          deviceId: eachHistoryInfo.id,
          reporterId: this.accountStore.account.id,
          completeDate: eachHistoryInfo.completeDate,
        });
      });
      return Promise.all(createMaintenanceLogs)
        .then(() => {
          this.alertStore.setAlertOpen(
            'success',
            '새로운 점검 이력 추가가 완료되었습니다.',
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  initInspectionHistories = () => {
    this.inspectionHistories = [];
  };

  getInspectionHistories = async (requestInfor) => {
    try {
      let res;
      if (requestInfor?.historyId) {
        res = await getInspectionHistoryById({
          ...requestInfor,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.inspectionInfo = res?.[0];
        });
      } else {
        res = await getAllInspectionHistories({
          ...requestInfor,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.inspectionHistories = res;
        });
      }
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateInspectionHistory = async (modifyInfo, refresh = true) => {
    try {
      if (modifyInfo?.editCheckItems?.length) {
        const checklistEditRes = await patchCheckListApi(
          modifyInfo.editCheckItems,
        );

        if (checklistEditRes !== true) throw checklistEditRes;
      }

      if (modifyInfo?.deleteImages?.length) {
        const deleteImagesRes = await this.deleteInspectionImageById(
          modifyInfo.deleteImages,
        );

        if (deleteImagesRes !== true) throw deleteImagesRes;
      }

      if (modifyInfo?.newImages?.length) {
        const createImagesRes = await this.createNewInspectionImage(
          modifyInfo.newImages,
        );

        if (createImagesRes !== true) throw createImagesRes;
      }

      await patchInspectionHistory({
        ...modifyInfo,
        timeZone: this.accountStore.account.timeZone,
      }).then((res) => {
        if (res && refresh) {
          this.getInspectionHistories({
            historyId: modifyInfo.id,
            deviceId: modifyInfo.deviceId,
            start: this.selectedMaintenanceDate[this.startIndex],
            end: this.selectedMaintenanceDate[this.endIndex],
          });
        }
        this.alertStore.setAlertOpen('success', '이력 수정이 완료되었습니다');
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteInspectionHistoryById = async (selectedMaintenanceLogs, deviceId) => {
    try {
      const deleteInspectionLogs = selectedMaintenanceLogs.map(
        (manufacturerInfo) => {
          return deleteInspectionHistory(manufacturerInfo.id);
        },
      );
      return Promise.all(deleteInspectionLogs)
        .then(() => {
          this.getInspectionHistories({
            deviceId,
          });
          this.alertStore.setAlertOpen(
            'success',
            `정기 점검 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewInspectionImage = async (newImageData) => {
    try {
      const createImageTasks = newImageData.map((eachImageData) => {
        return postInspectionImage(eachImageData);
      });
      return Promise.all(createImageTasks)
        .then(() => {
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return error;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteInspectionImageById = async (deleteImageData) => {
    try {
      const deleteImageTasks = deleteImageData.map((eachImageData) => {
        return deleteInspectionImage(eachImageData.id);
      });
      return Promise.all(deleteImageTasks)
        .then(() => {
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return error;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  setSelectedReportDate = (date) => {
    this.selectedMaintenanceDate = date;
  };

  setSelectedCompleteDate = (date) => {
    this.selectedReportDate = date;
  };
}

export default inspectionStore;
