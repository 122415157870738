const enJSON = {
  /**
   * 사용 범위 : Helmet
   * 업데이트 : 23.07.19
   * 작성자 : 서연주
   */
  소개: 'Information',
  '충전기 상세': 'Device Detail',
  '장비 세부정보': 'Device Detail Information',
  '초대 인증': 'Invite Verification',
  '제어 비밀번호 재설정': 'Update CU Key',
  '통합 설비 관리의 시작': 'IoT management services',
  '페이지 없음': 'Not Found',
  '네트워크 에러': 'Network Error',
  '페이지 보수 중': 'Maintenance',
  'Okomo 서버에 연결할 수 없습니다.\n사용자 네트워크 환경 문제나 방화벽 등이 문제가 될 수 있습니다.\n확인 후 이 오류가 반복되면 관리자에게 문의하세요':
    'Cannot connect to Okomo Server.\nCheck your network or firewall with your device.\nIf this error repeats, please contact the administrator',
  '네트워크 연결이 원활하지 않습니다.\n인터넷 연결을 확인해주세요.':
    'Something went wrong with your network.\nPlease check your network connection.',
  '서버에 연결할 수 없습니다': 'Something went wrong with connection to server',
  '네트워크 및 방화벽 확인 후 이 오류가 반복되면 관리자에게 문의하세요.':
    'Check network or firewall first, and if this error repeats, please contat the administrator',
  디테일탭: {
    '': '',
    main: 'main',
    status: 'status',
    control: 'control',
    measurement: 'measurement',
    ai: 'ai',
    log: 'log',
    setting: 'setting',
  },
  /**
   * 사용 범위 : 우측 사이드바
   * 업데이트 : 23.03.10
   * 작성자 : 정태원
   */

  // Register
  '이미 사용 중인 휴대 전화번호입니다.':
    'This mobile phone number that is already in use.',
  '이미 사용 중인 충전기 이름입니다':
    'This device name that is already in use.',

  // SidebarMenu
  모니터링: 'Monitoring',
  대시보드: 'Dashboard',
  '장비 요약': 'Device Summary',
  '환영합니다!': 'Welcome!',
  '장비 관리': 'Device management',
  '제품 목록': 'Product list',
  '제품 관리': 'Product management',
  '업체 관리': 'Manufacturer management',
  '모니터링 설정': 'Monitoring setting',
  '기타 설정': 'Setting',
  '장비 설정': 'Device Setting',
  '대시보드 설정': 'DashBoard',
  '고급 설정': 'Advanced',
  '개인 설정': 'Account',
  '그룹 설정': 'Group',
  일반: 'General',
  장비제어컴포넌트: 'Device control component',
  메뉴: 'Menu',
  '새로운 장비 등록': 'Create New Device',
  '새로운 제조업체 등록': 'Create New Manufacturer',
  '새로운 수리 이력 등록': 'Create New Repair History',
  '새로운 정기 점검 등록': 'Create New Regular Inspection History',
  '새로운 점검항목 등록': 'Create New Inspection Item',
  '새로운 제품 등록': 'Create New Product',
  '새로운 사용자 등록': 'Create New User',
  '이미지 등록': 'Add image',
  '이미지 수정': 'Edit image',
  '데이터 수정': 'Edit data',
  '장비를 등록해 주세요': 'Please register the device',
  '충전기 삭제를 완료했습니다.': 'Successfully deleted the device',
  '고장 이력 삭제를 완료했습니다.': 'Successfully deleted the repair history',
  '정기 점검 삭제를 완료했습니다.':
    'Successfully deleted the regular inspection history',
  새로고침: 'Refresh',
  /**
   * 사용 범위 : 장비 리스트 목록 Key변환
   * 업데이트 : 23.05.04
   * 작성자 : 정태원
   */
  // SidebarMenu
  default: 'Default Device',
  'one-phase-solar': 'One Phase Solar',
  'three-phase-solar': 'Three Phase Solar',
  fs600r: 'FS-600R',
  router: 'EDMU',
  'temperature-controller': 'Temperature Controller',
  thermohygrometer: 'Thermohygrometer',
  'solar-thermal': 'Solar thermal',
  'solar-water-heater': 'Solar water header',
  geothermal: 'Geothermal',
  wind: 'Wind',
  'fuel-cell': 'Fuel Cell',
  ess: 'ESS',
  'electric-vehicle-charger': 'Electric Vehicle Charger',

  // Ag Grid Device Info Column
  id: 'Device ID',
  type: 'Type',
  nickname: 'Nickname',
  isConnected: 'Connection',
  modelName: 'Model name',
  serial: 'Serial',
  firmwareVersion: 'Firmware version',
  'model.name': 'Model name',
  'location.name': 'Location',
  createdAt: 'Created date',
  routerSerial: 'Router serial',
  function: 'Function',
  lastConnectionTime: 'Last communication time',
  operationState: 'Operation state',
  /**
   * 사용 범위 : Playground 내부 컴포넌트
   * 업데이트 : 23.01.12
   * 작성자 : 정태원
   */

  // Playground
  정지: 'Stop',
  에러: 'Error',
  정상: 'Normal',
  가동중: 'Running',
  '불러오는 중...': 'Loading...',
  // ChartDataDisplayCard
  // CircularProgessDisplayCard

  '데이터 카드': {
    '월 ': 'last month ago',
    '일 ': '{{dayValue}}day ',
    '시간 ': '{{hourValue}}hour ',
    '분 ': '{{minValue}}min ',
    '초 ': '{{secValue}}sec ',
    '시간 에러': 'time error',
    '전 대비': 'ago',
  },
  /**
   * 사용 범위 : 상단 메뉴, 언어변환
   * 업데이트 : 23.01.02
   * 작성자 : 정태원
   */
  '업그레이드 메세지': 'You have {{day}} days left in your trial',
  '에러가 발생했습니다.': 'An error has occurred.',
  '에러코드 처리가 필요합니다.':
    'Error code processing required. [CODE : {{errorCode}}]',
  // LanguageSwitcher
  '언어 변환': 'Language switcher',
  한국어: 'Korean',
  영어: 'English',

  // Buttons/Chat
  // Sidebar/SidebarFooter
  메신저: 'Messenger',

  /**
   * 사용 범위 : 테마 변경
   * 업데이트 : 23.01.12
   * 작성자 : 정태원
   */

  // ThemeSettingsButton
  '테마 설정': 'Theme Settings',
  '밝은 테마': 'LIGHT COLOR SCHEMES',
  '어두운 테마': 'DARK COLOR SCHEMES',
  '레이아웃 선택': 'Choose layout',
  하늘: 'Pure Light',
  파랑: 'Grey Goose',
  보라: 'Purple Flow',
  성운: 'Nebula Fighter',
  초록: 'Green Fields',
  빨강: 'Dark Spaces',

  /**
   * 사용 범위 : 하단 좌측 메뉴버튼
   * 업데이트 : 23.01.02
   * 작성자 : 정태원
   */

  // SidebarFooter
  캘린더: 'Events Calendar',
  로그아웃: 'Logout',

  /**
   * 사용 범위 : 우측 프로필 정보
   * 업데이트 : 23.08.24
   * 작성자 : 서연주
   */

  // IconCards
  '계정 정보': 'My account',

  // Userbox
  '계정 연동': 'Social Connection',
  '비밀번호 변경': 'Password Update',
  '환경 설정': 'Preference',
  '결제 정보': 'Subscription',
  마켓: 'Market',
  이용약관: 'Terms',
  도움말: 'Help',

  // AgGrid
  페이지: 'Page',
  '페이지 당': 'Per page',
  항목: 'Item',
  '수정할 내용을 입력해주세요.': 'Please enter the contents to be modified.',
  '주소 수정': 'Location Update',
  삭제: 'Delete',
  상세보기: 'View',
  즐겨찾기: 'Bookmark',
  '즐겨찾기 등록에 성공하였습니다': 'Successfully bookmarked',
  '즐겨찾기 해제에 성공하였습니다': 'Successfully unbookmarked',
  '요청중...': 'Requesting...',

  // ActionListButton
  '개 항목 선택': 'Items selected',
  '추가 작업': 'Action List',

  // Modal
  확인: 'Confirm',

  // Device Setting
  '장비 추가': 'Create device',
  '장비 일괄 추가(엑셀)': 'Create devices(Excel)',
  '장비 수정': 'Modify device',
  '충전기 삭제': 'Delete device',
  '선택된 장비를 삭제하겠습니까?': 'Are you sure delete the selected device?',
  가져오기: 'Import',

  '파일을 업로드하여 장비를 추가해주세요(파일 형식: xlsx)':
    'Please upload a file to add a device (file format: xlsx)',
  '양식 다운로드': 'Download Form',
  '파일 업로드 & 드래그': 'File Upload & Drag',
  '업로드한 엑셀 파일의 내용을 다시 확인해주세요':
    'Please check the contents of the uploaded excel file again',
  추가하기: 'Add',
  '파일의 일부 필드 값이 양식에 맞지않습니다.':
    'Some field values in the file do not match the form.',
  '유효하지 않은 필드 리스트': 'Invalid field list',
  '엑셀 형식이 업로드 형식과 맞지 않습니다. 양식을 다운로드 하여 사용해주세요.':
    'The excel format does not match the upload format. Please download and use the form.',
  '파일을 처리중입니다': 'Processing file',
  행: 'Row',
  수정: 'Edit',

  '추가할 장비 정보를 입력해주세요':
    'Please enter the device information to add',

  '다중 장비 등록 에러':
    'Errors occurred in rows [number {{invalidRow}}], resulting in a failure to register the equipment. Please click on the row with the error field to proceed with the correction.',

  '50개 이하의 장비 데이터를 엑셀에 입력해주세요.':
    'Please enter the device data in the excel file.',

  '이미 사용중인 시리얼 번호 입니다': 'This serial number is already in use.',
  '이미 사용중인 충전기 이름 입니다': 'This device name is already in use.',

  // NoData Page
  '서비스 이용에 불편을 드려 죄송합니다.':
    'We apologize for the inconvenience caused during the service.',
  '알 수 없는 오류가 발생하였습니다': 'An unknown error has occurred',
  '장비 데이터가 존재하지 않습니다': 'No Device Data Exists',
  '찾으려는 페이지의 데이터가 존재하지 않습니다.':
    'The content does not exist.',
  '찾으려는 페이지의 이미지가 존재하지 않습니다.': 'The image does not exist.',
  '장비 제어 데이터가 존재하지 않습니다. 관리자에게 문의해주세요.':
    'Device control data does not exist. Please contact administrator.',
  'EDMU 데이터가 존재하지 않습니다. 관리자에게 문의해주세요.':
    'EDMU data does not exist. Please contact administrator.',
  '잘못된 요청입니다.': 'Invalid request.',
  '존재하지 않는 페이지입니다.': 'This page does not exist.',
  '존재하지 않는 장비입니다': 'Device does not exist',
  '잠시 후 다시 시도해 주세요...': 'Please try again later...',
  또는: 'OR',
  '대시보드로 이동': 'Go to dashboard',
  '장비 목록으로 이동': 'Go to device list',
  '그룹 설정 이동': 'Go to group setting',
  '동일한 오류가 반복되면 관리자에게 문의하세요.':
    'If the same error repeats, contact your administrator.',
  '모니터링 하기 위한 장비를 추가해주세요': 'Please add devices for monitoring',
  '모니터링 하기 위한 이미지를 추가해주세요': 'Please add image for monitoring',
  메인으로: 'Back to main',

  // ChartCardDisplayCard
  '표현할 데이터가 없습니다.': 'No data to express.',
  '데이터 없음': 'No data',
  '이전 데이터 없음': 'No previous data',
  '업데이트 시간': 'Update time',

  // Dashboard
  '장비들의 중요 데이터를 확인할 수 있습니다.':
    'Can check the important data of the device.',
  '새로운 기능을 위해 \n 작업 중인 페이지입니다':
    "We're working on implementing the new features",

  // Device Detail
  시리얼: 'Serial',
  태그: 'Tag',
  '장비 세부 모니터링': 'Device detail monitoring',
  '장비 시리얼': 'Device serial',
  동작상태: 'Status',
  '마지막 통신시간': 'Last Communication time',
  통신시간: 'Communication time',
  '통신상태 확인 중..': 'Checking communication status..',
  '통신확인 설정 필요': 'Communication check setting need',

  '권한 확인': 'Permission Check',
  '권한 확인을 위해 비밀번호를 입력해 주세요':
    'Please enter the group password',
  '장비 제어 명령을 요청했습니다.': 'Control request has been sent.',
  '장비 제어 명령이 실패했습니다.': 'Control request failed.',
  '현장 제어 시 비밀번호 사용': 'Use a password for field control',
  '장비에 연결할 수 없습니다. EDMU 연결을 확인해주세요.':
    'Cannot connect to the device. Please check the EDMU connection.',
  '장비의 통신이 원활하지 않습니다. 통신 상태를 점검해주세요':
    'Device communication is not normal. Please check the communication status',
  '접근이 거부되었습니다. 관리자 권한이 필요합니다':
    'Access denied. Admin privileges required',
  '잘못된 장비 시리얼입니다. \n해당 장비 시리얼과 라우터 시리얼을 확인해주세요.':
    'Invalid device serial. \nPlease check device serial number and router serial number.',
  '카드 이름으로 검색하기 (예: 예약시간 설정)':
    'Search by card name...(e.g. Time Reservation)',
  '검색 결과가 없습니다.': 'No search results found',
  // ThreePhaseSolar
  '실시간 모니터링': 'Realtime monitoring',
  '계측 데이터': 'Measurement Data',
  '설비 상태': 'Device status',
  제어: 'Control',
  'AI 진단기': 'Field device prediction(AI)',
  '로그 이력': 'Device log',
  '태양광 발전량 현황': 'Solar capacity current status',
  '태양광 패널 현황': 'Solar panel current status',
  '계통 현황 - 태양광 삼상': 'System status - Solar three phase',
  '계통 현황 - 세부 데이터': 'System status - Detail data',
  '현재 전력': 'Current power',
  'PV 전압': 'PV voltage',
  'PV 전류': 'PV current',
  '금일 누적 발전량': 'Cumulative capacity(today)',
  '금월 누적 발전량': 'Cumulative capacity(month)',
  '기간별 누적 발전량': 'Cumulative capacity(by period)',
  '총 누적 발전량': 'Cumulative capacity(total)',
  '인버터 효율': 'Inverter efficiency',
  '현재 발전력': 'Current capacity',
  '인버터 통신 상태': 'Inverter communication status',
  '장비 통신 상태': 'Device communication status',
  '계통 이상 상태': 'System operation status',
  '인버터 가동 상태': 'Inverter operation status',
  '시간별 PV 전력': 'Hourly PV power',
  '시간별 발전 전력량': 'Hourly capacity',
  일사량: 'Insolation',
  '인버터 Safety 보호 회로': 'Inverter safety protection circuit',
  'PV 전력': 'PV power',
  '계통 R-S 전압': 'System R-S voltage',
  '계통 S-T 전압': 'System S-T voltage',
  '계통 T-R 전압': 'System T-R voltage',
  '계통 R 전류': 'System R current',
  '계통 S 전류': 'System S current',
  '계통 T 전류': 'System T current',
  '계통 전력': 'System power',
  '계통 전압': 'System voltage',
  '계통 전류': 'System current',
  역률: 'Power factor',
  주파수: 'Frequency',
  '마지막 통신': 'Last Communication',
  '날짜 및 시간 표시 12시계': '{{dateFormatString}} hh:mm:ss A',
  '날짜 및 시간 표시 24시계': '{{dateFormatString}} HH:mm:ss',
  'YYYY-MM-DD hh:mm:ss A': 'MM/DD/YYYY hh:mm:ss A',
  'YYYY-MM-DD HH:mm:ss': 'MM/DD/YYYY HH:mm:ss',
  'yyyy-MM-dd hh:mm:ss TT': 'MM/dd/yyyy hh:mm:ss TT',
  'YYYY년 MM월 ': 'YYYY MMM ',
  '금월 일별 발전량': 'Daily capacity this month',
  'MM월 DD일': 'MMM/Do',
  'M월 DD일': 'MMM/Do',
  DD일: 'Do',
  D일: 'Do',
  'YYYY-MM-DD': 'MM/DD/YYYY',
  'yyyy-MM': 'MM/yyyy',
  'MM-dd': 'MM/dd',

  '발전소 상태': 'Power plant status',
  '인버터 1 상태': 'Inverter 1 status',
  '인버터 2 상태': 'Inverter 2 status',
  '현장 상태': 'Field status',
  '현장 예측 (AI)': 'Field prediction (AI)',
  '접속반 온도': 'Connecting board temp',
  '통신/전원 상태': 'Communication/Power status',
  'RTU_INV 통신 상태': 'RTU_INV Communication status',
  '서버 통신 상태': 'Server Communication status',
  '통신망 상태': 'Communication network status',
  '현장 전원': 'Field power',
  '인버터 전원': 'Inverter power',
  '배터리 전원': 'Battery power',
  '12V (정상)': '12V (Normal)',
  '접속반 상태': 'Connecting board status',
  '어레이 전압': 'Array voltage',
  '어레이 전류': 'Array current',
  '현장 데이터': 'Field data',
  '온도 1': 'Temp 1',
  '온도 2': 'Temp 2',
  '온도 3': 'Temp 3',
  '온도 4': 'Temp 4',
  '온도 5': 'Temp 5',
  '온도 6': 'Temp 6',
  '온도 7': 'Temp 7',
  '온도 8': 'Temp 8',
  '온도 9': 'Temp 9',
  '온도 10': 'Temp 10',
  '온도 11': 'Temp 11',
  '온도 12': 'Temp 12',
  '태양광 패널 상태': 'Solar panel status',
  '경사 일사량': 'Gradient solar radiation',
  '수평 일사량': 'Horizontal solar radiation',
  '외기 온도': 'Ambient air temp',
  '모듈 온도': 'Module temp',
  '태양전지 전압': 'Solar cell voltage',
  '스트링 상태': 'String status',
  '스트링 1 전류': 'String 1 current',
  '스트링 2 전류': 'String 2 current',
  '스트링 3 전류': 'String 3 current',
  '스트링 4 전류': 'String 4 current',
  '스트링 5 전류': 'String 5 current',
  '스트링 6 전류': 'String 6 current',
  '스트링 1 전압': 'String 1 voltage',
  '스트링 2 전압': 'String 2 voltage',
  '스트링 3 전압': 'String 3 voltage',
  '스트링 4 전압': 'String 4 voltage',
  '스트링 5 전압': 'String 5 voltage',
  '스트링 6 전압': 'String 6 voltage',

  '발전소 상태 제어': 'Power plant status control',
  'TRIP 제어': 'TRIP control',
  '통신/전원 상태 제어': 'Communication/Power status control',
  '인버터 ON/OFF': 'Inverter ON/OFF',
  '통신 ON/OFF': 'Communication ON/OFF',
  '접속반 상태 제어': 'Connecting board status control',
  'EDMU 제어': 'EDMU control',
  '예지보전 그래프': 'Prediction conservation graph',
  '설비 유지율': 'Retention rate',
  '전일 대비 0.02% 증가할 예정입니다.':
    'It will increase 0.02% compared to the previous day.',
  '인버터 효율 예측': 'Inverter efficiency prediction',
  '전일 대비 6% 증가할 예정입니다.':
    'It will increase by 6% compared to the previous day.',
  '발전력 예측': 'Power generation prediction',
  '오후 2시쯤 가장 많은 발전력을 보일 예정입니다.':
    'It will show the most development around 2 p.m.',
  '일사량 지수': 'Solar radiation index',
  중간: 'Middle',
  '오후 12시부터 3시까지는': 'From 12pm to 3pm',
  '발전량이 큰 폭으로 증가합니다.': 'Power generation increases significantly.',
  습도: 'Humidity',
  '현재 이슬점 온도는': 'The current dew point temp is',
  '입니다.': 'it is',
  강수량: 'Precipitation',
  '최근 1시간 강수량': 'Last one hours of precipitation',
  '내일까지 0.75mm가': 'It is expected to fall',
  '더 내릴것으로 예상됩니다.': '0.75mm more by tomorrow.',
  기압: 'Atmospheric pressure',
  오늘: 'Today',
  '구름 많음': 'Cloudy',
  '지난 10일간의 날씨 보기': 'Looking at the weather over the past 10 days.',
  'EDMU SoC 상태': 'EDMU SoC status',

  // Temperature controller

  '예약 설정': 'Reservation time setting [{{keyName}}]',
  '시간 설정': 'Time setting [{{keyName}}]',

  '설정할 시간을 선택해 주세요': 'Please select the time',
  '예약운전하려는 시작 시간과 종료 시간을 선택해 주세요':
    'Please select the date for the range you want to reserve',

  '제어 요청': 'Control',

  '예약 관리': 'Reservation management',
  예약운전: 'Reservation',
  시작시간: 'start time',
  종료시간: 'end time',
  '강제 제상': 'Forced defrost',
  '최대 제상 시간': 'Max defrost time',
  '온도 제어 편차': 'Temperature Variance',
  '희망 온도': 'Desired temperature',
  '제어 모드': 'Control mode',

  '예약 취소': 'cancel',
  '실행 중': 'running',
  예약하기: 'reserve',

  '온도 제어': 'Temperature control',
  자동: 'auto',
  수동: 'manual',

  '시작 시간을 설정해주세요': 'Please select a start time',
  '종료 시간을 설정해주세요': 'Please select a end time',
  '현재 시간 이후의 시작 시간을 선택해주세요':
    'Please choose a start time after the current time',
  '시작 시간과 종료 시간을 다르게 설정해주세요':
    'Please choose the start time and end time differently',

  '이미 제어 요청이 진행 중입니다.':
    'A control request is already in progress.',
  '유효하지 않은 제어 키 입니다.': 'The control key provided is not valid.',

  '시간 설정 미사용': 'Deactivate the time setting',
  '시간 설정이 비활성화되었습니다': 'The Time Setting is Deactivated',

  // TwoDateTimePicker

  '시작 시간': 'start time',
  '종료 시간': 'end time',

  // MultiChart

  그래프: 'Chart',
  '전체 데이터': 'All data',
  '차트 설정': 'Chart setting',
  시작: 'Start',
  끝: 'End',
  '데이터 수집 주기': 'Interval',
  '날짜 설정': 'The date range selection',

  '기간과 데이터 수집 주기를 모두 선택해 주세요':
    'Please select both the date range and interval information.',

  '기간을 선택해 주세요': 'Please select the date range information',

  '최대 4개의 필드값만 선택 가능합니다': 'You can select up to 4 field values',

  '출력하고 싶은 데이터의 기간과 데이터 수집 주기를 선택해 주세요':
    'Please select the date range and time interval you would like to display',

  '출력하고 싶은 데이터들을 선택해 주세요\n(최대 4개 선택 가능)':
    'Please select the data you would like to display \n(up to 4 fields)',

  '출력하려는 차트 데이터의 기간 및 데이터 수집 주기를 선택해 주세요':
    'Please select the date range and time interval of the chart data you wish to display',

  '출력하려는 차트 데이터의 기간을 선택해 주세요':
    'Please select the date range of the chart data you wish to display',

  /**
   * 사용 범위 : 타임라인 컴포넌트
   * 업데이트 : 23.02.27
   * 작성자 : 정태원
   * 이슈 : Notifications/index.js 에서 사용 중인 "알림" 키가 겹쳐  기존의 "Notification을 Notify로 통일"
   */

  // Timeline
  알림: 'Notice',
  주의: 'Warning',
  경보: 'Alert',
  '날짜를 선택해 주세요': 'Select a date',

  // TimelineContent
  날짜: 'date',
  '집계된 데이터가 존재하지 않습니다': 'Data does not exist',
  'hh시 mm분': 'hh:mm',

  /**
   * 사용 범위 : DeviceSetting 내부 컴포넌트
   * 업데이트 : 23.07.28
   * 작성자 : 서연주
   */

  // CreateDeviceModal
  '새로운 장비 추가': 'Create New Device',
  '아래 장비 정보를 입력하여 새로운 장비를 추가하세요':
    'Fill in the fields below to create a new device',
  제품: 'Product',
  '장비 종류': 'Device Type',
  '장비 태그': 'Device Tag',
  모두: 'all',
  태양광: 'one phase solar',
  지열: 'geothermal',
  풍력: 'wind',
  '장비 시리얼 넘버': 'Device Serial Number',
  '충전기 이름': 'Device Name',
  '설치 일자': 'Installed Date',
  '설치 환경': 'Installed Environment',
  '실외 (태양 노출)': 'Outdoor (Sun Exposure)',
  '실외 (캐노피)': 'Outdoor (Canopy)',
  '지하 (그늘)': 'Underground (Shaded)',
  '장비 위치': 'Device Location',
  '그룹 주소로 설정': 'Set to group address',
  '새로 추가하기': 'Add ',
  '주소 별명 선택 및 새로운 별명 입력':
    'Select address nickname or Type new nickname',
  '새로운 별명은 아래 입력되는 주소들과 함께 저장됩니다':
    'Address input below will be saved with new nickname',
  주소: 'Main Address',
  '상세 주소': 'Detail Address',
  취소: 'Cancel',
  추가: 'Create',
  등록: 'Create',
  '허가되지 않은 데이터 항목이 포함되있습니다.\n 관리자에게 문의해주세요':
    'Not allowed value exist.\n please contact admin',
  '장비 종류 선택은 필수 항목입니다.': 'Device type is required.',
  '장비 시리얼은 필수 항목입니다.': 'Device serial is required.',
  '장비 위치는 필수 항목입니다.': 'Device location is required.',
  '충전기 이름은 필수 항목입니다.': 'Device name is required.',
  '제품 이름은 필수 항목입니다.': 'Product name is required.',
  '장비 타입이 유효하지 않습니다.': 'Invalid device type.',
  '제품 ID가 유효하지 않습니다.': 'Invalid product id.',
  '해당 장비 위치가 존재하지 않습니다.':
    'The corresponding device location does not exist.',
  '장비 번호가 유효하지 않습니다.': 'Invalid device id.',
  '이미 존재하는 장비 시리얼 입니다.': 'Device serial already exists.',
  '사용자 ID는 숫자로 입력해주세요.': 'Please enter the user ID in numeric.',
  '장비 ID는 숫자로 입력해주세요.': 'Please enter the device ID in numeric.',
  '장비 위치 ID는 숫자로 입력해주세요':
    'Please enter the device location ID in numeric.',
  '라우터 시리얼은 숫자로 입력해주세요.':
    'Please enter the router serial in numeric.',
  '등록되지 않은 장비 ID입니다.': 'Unregistered device ID.',
  '이미 존재하는 주소 별명 입니다.': "The location's name already exists.",
  '이미 존재하는 제품명 입니다.': 'Product name already exists.',
  '이미 존재하는 업체명 입니다.': 'Manufacturing company name already exists.',
  '12자 이하의 충전기 이름을 입력해 주세요':
    'Too many characters. Maximum 12 characters',
  '40자 이하의 주소 별명을 입력해 주세요':
    'Too many characters. Maximum 40 characters',
  '255자 이하의 상세 주소를 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 그룹 상세 주소를 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 직함을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 우편번호를 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 그룹 우편번호를 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 그룹 홈페이지를 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 점검 항목을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 점검 내용을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 제품 이름을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 모델명을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '30자 이하의 정보 이름을 입력해 주세요':
    'Too many characters. Maximum 30 characters',
  '장비 현재값이 입력되지 않았습니다.':
    'No device current values have been entered.',
  '장비 제어값이 입력되지 않았습니다.':
    'No device control values have been entered.',
  '적용 가능한 태그가 없습니다': 'No tag data exist',
  '주소 별명은 필수 항목입니다.': 'Location name is required.',
  '기존 주소의 정보를 변경하면 해당 주소를 참조하는 대상에도 반영됩니다.':
    'Updating the existing address will also update the address of the object sharing same address.',
  '장비 수정을 완료하였습니다':
    'Updating device information has been completed.',
  '제품 수정을 완료하였습니다':
    'Updating product information has been completed.',
  '새로운 태그를 입력한 뒤 Enter를 눌러 추가할 수도 있습니다':
    'You can create and add new tags with Enter key',
  '올바르지 않은 태그가 포함되어있습니다.\n 태그는 문자, 숫자, _(언더바), 띄어쓰기만 가능합니다.':
    'Invalid tag is included.\n You can add the tag only with characters, numbers, _(underscore), space',
  '주소 찾기': 'Find Address',
  '주소 별명 선택': 'Select location nickname',
  '새로운 건물 추가': 'Create new location',
  '주소 별명': 'Location nickname',
  우편번호: 'Postal Code',
  '새로운 건물 추가가 완료되었습니다.':
    'New location has been created successfully.',
  '새로운 수리 이력 추가가 완료되었습니다.':
    'New replacement/repair history has been created successfully.',
  '새로운 점검 이력 추가가 완료되었습니다.':
    'New inspection history has been created successfully.',
  '현장 사진은 최대 4장까지 가능합니다': 'You can upload to 4 images',
  '이미지 크기는 최대 5MB 입니다': 'You can image size can be up to 5MB',
  검색: 'Search',
  '지도 검색...': 'Search map...',
  '결과 없음': 'No results',
  '수리 이력': 'Repair History Management',
  '정기 점검': 'Inspection List',
  '이력 상세보기': 'History detail view',
  /**
   * 사용범위 : 로그인 , 회원가입
   * 업데이트 : 23.03.16
   * 작성자 : 정태원
   */

  // signUpForm , signInForm
  '올바른 이메일을 입력해 주세요': 'Please enter an accurate email address',
  '이메일 주소의 길이가 제한된 길이를 초과하였습니다':
    'The length of the email address exceeds the maximum allowed limit',
  '계정 이메일과 다른 보조 이메일을 입력해 주세요':
    'Please enter an account email and a different sub email',
  '필수 정보입니다': 'This field is required',
  '잘못된 비밀번호입니다. 다시 시도해 주세요.':
    'Incorrect password. Please try again.',
  '이메일이 인증되지 않았습니다. 발송된 인증 메일을 확인해 주세요.':
    'Pending Account. Please Verify Your Email',
  '가입되지 않은 회원입니다. 정보를 다시 확인해 주세요.':
    'No account with this info, Please try again.',
  '기타 에러가 발생하였습니다.': 'An unexpected error has occurred.',
  '이메일 인증 요청 횟수가 제한되었습니다.\n1분 후 다시 시도해주세요.':
    'Email verification requests have been limited.\nPlease try again in 1 minute.',
  '유효하지 않은 국가정보 입니다.': 'The country information is invalid.',
  국가: 'Country',

  이메일: 'Email',
  '보조 이메일': 'Sub Email',
  '이메일 찾기': 'Find email',
  '보조 이메일 삭제': 'Delete sub email',
  '보조 이메일을 삭제하시겠습니까?':
    'Are you sure you want to delete this sub email?',
  '보조 이메일 삭제가 정상적으로 처리되었습니다.':
    'Sub email deletion has been processed successfully.',
  '이메일을 입력해 주세요': 'Enter your email',
  '이미 사용 중인 이메일입니다.': 'Email is already in use.',
  '이미 사용 중인 번호입니다.': 'PhoneNumber is already in use.',
  비밀번호: 'Password',
  '비밀번호를 입력해 주세요': 'Enter your password',
  '비밀번호 찾기': 'Find password',
  '비밀번호 재확인': 'Confirm password',
  '비밀번호를 다시 입력해 주세요': 'Enter your confirm password',
  '비밀번호가 일치하지 않습니다': 'Passwords must match',
  '8~16자의 비밀번호를 입력해 주세요':
    'Please enter a password between 8 and 16 characters',
  로그인: 'Log in',
  체험하기: 'Live preview',
  '약관에 동의해 주세요': 'You must agree to our terms and conditions',
  '개인정보 수집 및 이용에 동의해 주세요':
    'You must agree to our privacy policy',
  '프로필 이미지': 'Profile image',
  '사용자 ID': 'Account ID',
  이름: 'Name',
  직함: 'Job Title',
  '이름을 입력해 주세요': 'Enter your name',
  '16자 이하의 이름을 입력해 주세요':
    'Too many characters. Maximum 16 characters',
  '휴대 전화번호': 'Phone Number',
  '올바른 휴대 전화번호를 입력해 주세요': 'Enter your valid phone number',
  '휴대 전화번호를 입력해 주세요': 'Enter your phone number',
  '인증 코드': 'Verification code',
  '보조 이메일 인증 코드': 'Sub email verification code',
  '인증 코드를 입력해 주세요': 'Enter your verification code',
  인증하기: 'Verify',
  '인증 요청': 'Request Verification',
  '이메일로 발송된 인증 코드를 입력해 주세요':
    'Enter your verification code sent to your email',
  '보조 이메일로 발송된 인증 코드를 입력해 주세요':
    'Enter your verification code sent to your sub email',
  '이메일이 인증되지 않았습니다 인증코드를 입력해 주세요':
    'Your email has not been verified. Please enter the verification code',
  '보조 이메일이 인증되지 않았습니다 인증코드를 입력해 주세요':
    'Your sub email has not been verified. Please enter the verification code',
  '유효한 인증 코드를 입력해 주세요': 'Please enter a valid verification code',
  '유효하지 않은 인증코드입니다.': 'Invalid verification code',
  '존재하지 않는 인증코드입니다.': 'This verification code does not exist',
  '만료된 인증코드입니다.': 'This verification code has expired',
  '6자리의 인증코드를 입력해주세요.':
    'Please enter a 6-digit verification code',
  '그룹 정보가 없습니다': 'No group information',
  '휴대 전화번호를 입력하지 않으면 이메일 찾기가 불가합니다': `The phone number will be used when you find your email`,
  '유효한 휴대 전화번호를 입력해 주세요': 'Please enter a valid phone number',
  // signUp , signIn
  '계정에 로그인하려면 각 필드의 정보를 입력해 주세요':
    'Fill in the fields below to sign into your account',
  '회원가입을 진행하려면 각 필드를 입력해 주세요':
    'Fill in the fields below to sign up for an account',
  '계정이 없으신가요?': 'Don’t have an account, yet?',
  '계정이 있으신가요?': 'Already have an account?',
  가입하기: 'Sign up here',
  로그인하기: 'Sign in here',
  회원가입: 'Sign up',
  '회원가입이 정상처리 되었습니다':
    'Your registration has been successfully processed',

  // RecoverEmail
  '찾고자 하는 이메일 가입에 사용된 이름과 전화번호를 입력해 주세요':
    'Please enter your email ID and password to find your account',
  찾기: 'Find',
  '비밀번호를 잊으셨나요?': 'Forget your password?',
  '입력하신 정보로 등록된 이메일을 찾을 수 없습니다. 다시 입력해 주세요.':
    'We were unable to locate your email using the information provided. Please confirm that you have entered your ID and password correctly.',
  '이메일 인증 처리가 정상적으로 처리되었습니다.':
    'Your email has been successfully verified.',
  '로그인 페이지로 이동': 'Move to the login page',
  '이메일 인증에 실패하였습니다.\n 유효하지 않은 접근이거나 인증 시간이 만료되었습니다.\n 인증 메일을 다시 적어 주세요.':
    'Email verification has failed. \nYour access may be invalid or the verification time may have expired. \nPlease enter your email address again to receive a new verification email.',
  '인증 메일 다시 보내기': 'Resend verification email',
  '이메일 인증': 'Email verification',

  // RecoverPassword
  '찾고자 하는 비밀번호의 이메일 주소를 입력해 주세요':
    'Please enter your email ID to find your password',
  '비밀번호 재설정 링크 보내기': 'Send Password Reset Link ',
  '이메일을 잊으셨나요?': 'Forget your email?',

  // ResetPassword
  '비밀번호 재설정': 'Reset Password',
  '비밀번호 재설정을 위해 새로운 비밀번호를 입력해 주세요.':
    'Please provide a new password for your account',
  플레이스홀더: 'Enter your new {{keyName}}',
  ICCID: 'ICCID',
  제조번호: 'manufacturing number',
  제조일자: 'manufacturing date',
  'QR1(카카오)': 'QR1(kakao)',
  'QR2(티맵)': 'QR2(TMAP)',
  HW1버전: 'HW1 version',
  HW2버전: 'HW2 version',
  'FW버전(계량)': 'FW version(measurement)',
  'FW버전(MCU1)': 'FW version(MCU1)',
  'FW버전(MCU2)': 'FW version(MCU2)',
  건물명: 'building name',
  배전반: 'switchboard',
  상세위치: 'detail address',
  '설치작업자(ID)': 'installation worker(ID)',
  '새로 설정할 보조 이메일을 입력해 주세요': 'Enter your new sub email',
  '단말기 시리얼(정보용)': 'device serial (for information)',

  '새로 설정할 비밀번호를 입력해 주세요': 'Enter your new password',
  '입력한 정보와 일치하는 이메일입니다.':
    'This is an email that matches the information you entered',
  '현재 비밀번호와 다른 비밀번호를 입력해 주세요.':
    'Your new password cannot be the same as your current password',

  // ResetCompanyPassword
  '제어 비밀번호[CU Key] 재설정': 'Reset Control Password[CU Key]',
  '제어 비밀번호[CU Key] 재설정을 위해 새로운 비밀번호를 입력해 주세요.':
    'Please enter your new control password[CU Key]',
  'CU Key 찾기': ' Find CU key',
  '제어 비밀번호[CU Key]를 잊으셨나요?':
    ' Forget your control password[CU Key]?',
  '제어 비밀번호[CU Key] 초기화 메일 요청':
    'Send Control Password[CU Key] Reset Email',
  '제어 비밀번호[CU Key] 초기화 메일을 보내시겠습니까?':
    'Do you want to send a control password[CU Key] reset email?',
  '새 제어 비밀번호[CU key]': 'New Control Password[CU Key]',
  /**
   * 사용범위 : Snackbar 메세지
   * 업데이트 : 23.03.22
   * 작성자 : 정태원
   */

  // SnackbarAlert
  '이메일 인증이 정상적으로 처리되었습니다.':
    'Email authentication has been processed successfully.',
  '정상적으로 인증 처리가 수행되지 않았습니다.\n 다시 수행해 주세요.':
    'Email authentication process was not completed successfully.\n Please try the process again.',
  '정상적으로 인증 메일이 발송되었습니다.\n 인증 메일을 눌러 회원가입을 진행해 주세요.':
    'An email verification link has been successfully sent to your address',
  '예기치 않은 오류가 발생하여 인증 메일 발송에 실패하였습니다.\n 다시 수행해 주세요.':
    'The email verification failed to send due to an unexpected error.\n Please try again or contact us for help.',
  '회원 가입 시 입력한 정보와 일치하는 이메일 주소를 입력해야합니다.\n 다시 수행해 주세요.':
    'You need to enter the email address that matches the information you provided during registration. Please try again.',
  '비밀번호 초기화를 위한 인증 코드가 이메일로 발송되었습니다.\n받으신 인증 코드를 입력하여 비밀번호를 재설정해 주세요.':
    'An authentication code for password reset has been sent to your email.\nPlease enter the received code to reset your password.',
  '비밀번호가 정상적으로 수정되었습니다.': 'Password successfully updated.',
  '비밀번호 변경에 실패했습니다.\n 다시 시도해 주세요.':
    'The password modification failed.\n Please ensure that your current password is correct and try again.',
  '유효하지 않은 접근입니다.\n 다시 시도해 주세요.':
    'Invalid access. \n Please try again with a valid request.',
  '잘못된 사용자 정보입니다. \n다시 시도해 주세요':
    'Invalid user. \nPlease try again with a valid request.',
  '인증 정보가 잘못되었거나 만료되어 인증처리에 실패하였습니다. \n다시 시도해 주세요.':
    'Invalid authentication information or expired \n Please try again with a valid request.',
  '이미 처리된 계정입니다.': 'This account has already been processed',

  /**
   * 사용범위 : 프로필 페이지
   * 업데이트 : 23.04.04
   * 작성자 : 정태원
   */

  // Profile Setting
  프로필: 'profile',
  '프로필 정보 수정이 정상적으로 처리되었습니다.':
    'Profile information modification was processed successfully.',
  '프로필 정보 수정중 오류가 발생하였습니다.':
    'An error occurred while updating your profile information.',
  설정: 'Settings',
  '기존 비밀번호': 'Current Password',
  '새 비밀번호': ' New Password',
  '새 비밀번호 재확인': 'Re-enter Password',
  '사용자 권한': 'Role',
  '날짜 표시 형식': 'Date Format',
  구분자: 'Delimiter',
  '타임 존': 'Time Zone',
  '기존 비밀번호를 입력해 주세요': 'Enter your current password',
  '새로 설정할 비밀번호를 다시 입력해 주세요': 'Enter your confirm password',
  '새로 설정할 이름을 입력해 주세요': 'Enter your name',
  '새로 설정할 휴대 전화번호를 입력해 주세요': 'Enter your phone number',
  '휴대전화번호가 입력되지 않았습니다': 'No Phone number entered',
  수정하기: 'Edit',
  '[수정하기] 개인 정보 수정을 위해 필요한 내용을 입력해주세요':
    '[Edit] Please fill out the necessary information to update your personal details',
  '사용자 정보': 'Account Information',
  '[수정하기]를 통해 비밀번호를 변경할 수 있습니다':
    'You can change the password clicking [Edit] button',
  '사용자 비밀번호': 'User Password',
  '사용자 비밀번호를 입력해 주세요': 'Enter your password',
  '제어 비밀번호[CU Key]를 입력해 주세요':
    'Enter your control password[CU Key]',
  '회원 탈퇴 중 오류가 발생하였습니다.':
    'An error occurred while withdrawing from the account',
  '이 작업을 수행하면 사용자 정보가 영구적으로 삭제됩니다.\n계정을 정말 삭제하시겠습니까?':
    'This action will remove your account information.\nAre you sure you want to delete your account?',
  '회원 탈퇴': 'Withdrawal',
  '회원 탈퇴가 정상적으로 처리되었습니다.':
    'Your account has been successfully closed.',
  '이메일이 인증되지 않았습니다': 'Your email has not been verified',
  '이미 가입처리된 이메일입니다.': 'This email has already been registered.',
  '존재하지 않는 이메일입니다.': 'This email does not exist.',
  '일시적으로 인증메일을 보낼 수 있는 횟수가 초과되었습니다.\n잠시 후에 다시 시도해주세요.':
    'The number of times you can send authentication emails has been exceeded temporarily.\nPlease try again later.',

  '발송된 이메일의 인증코드를 확인하여 계정 인증을 진행해 주세요':
    'Please check the verification code in the email we sent to authenticate your account',
  '인증 재요청': 'Reauthenticate',
  '인증 메일 요청': 'Send Verification Email',
  '인증메일 요청을 보내시겠습니까?':
    'Do you want to send a verification email?',

  // ProfilePrictureModal
  '프로필 이미지 업로드': 'Upload Profile Image',
  '사용할 프로필 이미지의 범위를 선택 후 저장해 주세요':
    'Select the range of profile images to use and save them.',
  '이미지 저장': 'Save',

  /**
   * 사용범위 : 그룹 설정 페이지
   * 업데이트 : 23.04.25
   * 작성자 : 정태원
   */

  // AG-GRID
  '등록된 데이터가 없습니다': 'No data exists',
  '데이터가 존재하지 않습니다': 'No data exists',

  // Guest
  '그룹 가입': 'Join',
  '그룹 생성': 'Create',
  '원하시는 서비스를\n선택해주세요': 'Please select the service',
  '장비 등록을 위해선\n그룹에 소속되어야 합니다':
    'You must be a member of the group\nto create a device',

  // JoinCompany
  '그룹 가입처리가 정상적으로 수행되었습니다.':
    'You have joined the group successfully.',
  '유효하지 않은 그룹코드입니다.': 'Incorrect Group Code. Please try again.',
  '이미 가입된 그룹가 존재합니다.': "There's the group you've already joined.",
  '그룹에 가입하기 위한 코드를 입력해 주세요':
    'Please enter the code to join with the group',
  '가입할 그룹의 코드를 입력해 주세요': 'Enter the code to join the group',
  '그룹 코드': 'Group Code',

  // CreateCompany
  '그룹를 생성하기 위해 필요한 내용을 입력해주세요':
    'Please enter the required information to create a new group',

  '그룹에 가입하기 위해서는 해당 그룹의 관리자에게 그룹 코드를 요청하고, 가입 페이지에서 코드를 입력해야 합니다.':
    'To join a group, you must request a group code from the administrator of the group and enter the code on the join page.',

  '그룹가 정상적으로 생성되었습니다.':
    'The group has been created successfully.',
  '그룹 생성 중 오류가 발생하였습니다.':
    'An error occurred during group creation.',
  '유효하지 않은 그룹코드이거나 이미 가입된 회원입니다.':
    'Invalid group code or member already registered.',
  '이미 존재하는 그룹입니다.\n가입을 희망한다면 관리자에게 링크를 요청해주세요':
    'The group already exists.\nIf you want to join the group, please request admin for a link',

  // Admin
  '그룹 정보': 'Group Info',
  '관리자 정보': 'Admin Info',
  '사용자 목록': 'User List',
  '관리자 생성': 'Create Admin',

  // ManageCompany
  '그룹 정보를 수정하기 위해 필요한 내용을 입력해주세요':
    'Please fill the necessary information to update your group details',
  '그룹 삭제': 'Delete',
  '그룹를 정말 삭제하시겠습니까?': 'Are you sure you want to delete the group?',
  '그룹 삭제가 정상적으로 처리되었습니다.':
    'Group deletion has been processed successfully.',
  '그룹 삭제 중 오류가 발생했습니다.':
    'An error occurred during group deletion.',
  '제어 비밀번호[CU Key] 수정이 정상적으로 처리되었습니다.':
    'Control password[CU Key] modification has been processed successfully.',
  '그룹 정보 수정이 정상적으로 처리되었습니다.':
    'Group information modification has been processed successfully.',
  '그룹 정보 수정중 오류가 발생하였습니다.':
    'An error occurred during group information modification.',
  '제어 비밀번호[CU Key]를 입력해주세요.':
    'Enter your control password[CU Key]',

  '그룹 탈퇴': 'Leave',
  '이 작업을 수행하면 그룹와 관련된 모든 권한과 정보가 영구적으로 삭제됩니다.\n정말로 그룹를 탈퇴하시겠습니까?':
    'This action will remove all permissions and information related to the group permanently.\nAre you sure you want to leave the group?',
  '그룹에서 탈퇴하시겠습니까?': 'Are you sure you want to leave the group?',
  '그룹 탈퇴가 정상적으로 처리되었습니다.':
    'You have successfully left the group.',

  '선택된 장비를 삭제하려면 제어 비밀번호[CU Key]를 입력하세요.':
    'Enter your control password[CU Key] to delete the selected device.',
  '그룹 생성 및 가입을 먼저 진행해주세요':
    'You need to create or join the group.',
  '그룹 이름이 너무 깁니다. 다시 입력해주세요':
    'Group name is too long. Please enter a shorter name',

  '이 작업을 수행하면 그룹 정보가 영구적으로 삭제됩니다. 그룹를 정말 삭제하시겠습니까?':
    'This action will remove your group information.\nAre you sure you want to delete the group?',

  // ManageUser
  '유저 삭제': 'Delete user',
  '선택한 유저들을 그룹에서 일괄 삭제하시겠습니까?':
    'Are you sure you want to delete these users from the group?',
  '선택한 유저를 그룹에서 삭제하시겠습니까?':
    'Are you sure you want to delete this user from the group?',
  '유저 삭제가 정상적으로 처리되었습니다.':
    'User deletion has been processed successfully.',
  '유저 삭제에 실패했습니다.': 'User deletion has failed.',
  '사용자 권한 수정이 완료되었습니다.':
    'User role modification has been completed.',
  '이력 수정이 완료되었습니다': 'history modification has been completed',
  '사용자 권한 수정 중 오류가 발생하였습니다.':
    'An error occurred during user role modification.',
  '잘못된 제어 비밀번호[CU Key]입니다. 다시 시도해 주세요.':
    'Incorrect control password[CU Key]. Please try again.',
  '자신의 권한은 수정할 수 없습니다.': 'Can not modify your own role.',
  '그룹 내에는 관리자 권한을 소유한 계정이 적어도 하나 이상 필요합니다.':
    'The group must have at least one admin user.',

  '그룹 내의 유일한 관리자 계정은 탈퇴할 수 없습니다.':
    'The only administrator account within the group cannot be deleted.',

  '사용자 초대하기': 'View group invitation link',
  '초대하고 싶은 사용자에게 아래의 초대링크를 전달해주세요':
    'Please provide the following invitation link to the user you want to invite',
  초대링크: 'invite link',
  닫기: 'close',
  '사용자 초대': 'Invite user',

  // InviteVerificationResult

  '비정상적인 접근입니다.\n로그인을 진행 후 그룹 가입 링크를 눌러주세요':
    'This is an unauthorized access attempt.\nPlease proceed with login and click on the group registration link.',

  '그룹 가입 성공': 'Group registration successful',
  '그룹 가입 실패': 'Group registration failed.',

  '그룹 가입이 정상적으로 처리되었습니다.':
    'Group registration has been successfully completed.',

  // Formik 공통 요소
  필수항목: 'Required',
  '그룹 ID': 'Group ID',
  그룹명: 'Group Name',
  '사업자 등록번호': 'BRN',
  '그룹명을 입력해 주세요 (예시: 유니로보틱스)':
    'Enter your group name (example: Unirobotics)',
  '사업자 등록번호를 입력해 주세요 (예시: 1348709872)':
    'Enter your business registration number (example: 91-1144442)',
  '제어 비밀번호[CU Key]': 'Control Password[CU Key]',
  '그룹 이메일': 'Group Email',
  '그룹 이메일을 입력해 주세요 (예시: okomo@unirobotics.co.kr)':
    'Enter your group email (example: okomo@unirobotics.co.kr)',
  '그룹 연락처': 'Group Phone',
  '추가 연락처': 'Extra Phone',
  '그룹 연락처를 입력해 주세요 (예시: 010-1234-5678)':
    'Enter your group phone (example: 555-555-1234)',
  '추가 연락처를 입력해 주세요 (예시: 010-1234-5678)':
    'Enter your extra phone (example: 555-555-1234)',
  '주소 설정': 'Address Setting',
  '그룹 주소 설정': 'Group Address Setting',
  '그룹 주소': 'Group Main Address',
  '그룹 주소를 입력해 주세요 (예시: 대한민국 서울특별시 용산구 용산동4가 1-8)':
    'Enter your group main address (example: Mountain View, CA 94041, USA)',
  '그룹 우편번호': 'Group Postal Code',
  '그룹 우편번호를 입력해 주세요 (예시: 15451)':
    'Enter your group postal code (example: 94041)',
  '그룹 상세 주소': 'Group Detail Address',
  '그룹 상세 주소를 입력해 주세요 (예시: 한양아파트 107동 703호)':
    'Enter your group detail address (example: 220 View St. Landels Elementary)',
  '그룹 홈페이지': 'Group Homepage',
  '그룹 홈페이지를 입력해 주세요 (예시: unirobotics.co.kr)':
    'Enter your group homepage (example: unirobotics.co.kr)',
  '현재 비밀번호': 'Current Password',
  '사용할 제어 비밀번호[CU Key]를 입력해 주세요':
    'Enter your control password[CU Key]',
  등록하기: 'Create',
  '새 제어 비밀번호[CU Key]': 'New Control Password[CU Key]',
  '그룹 초대 코드': 'Invite Code',
  '그룹 초대 링크가 클립보드에 복사되었습니다.':
    'The group invitation link has been copied to the clipboard.',
  '그룹 초대 코드가 클립보드에 복사되었습니다.':
    'The group invitation code has been copied to the clipboard.',
  '클립보드 복사에 실패하였습니다': 'Failed to copy to clipboard',
  '새로 설정할 그룹명을 입력해 주세요': 'Enter your new group name',
  '새로 설정할 사업자 등록번호를 입력해 주세요':
    'Enter your new business registration number',
  '새로 설정할 그룹 이메일을 입력해 주세요': 'Enter your new email',
  '새로 설정할 그룹 연락처를 입력해 주세요': 'Enter your new group phone',
  '새로 설정할 추가 연락처를 입력해 주세요': 'Enter your new extra phone',
  '새로 설정할 주소를 선택해 주세요': 'Select your new group main address',
  '새로 설정할 우편번호를 입력해 주세요': 'Select your new group postal code',
  '새로 설정할 상세 주소를 입력해 주세요':
    'Enter your new group detail address',
  '새로 설정할 제어 비밀번호[CU Key]를 입력해 주세요':
    'Enter your new control password[CU Key]',
  '새로 설정할 직함을 입력해 주세요': 'Enter your new job title',
  '새로 설정할 그룹 홈페이지를 입력해 주세요': 'Enter your new group homepage',
  '기존 제어 비밀번호[CU Key]를 입력해 주세요':
    'Enter your current control password[CU Key]',
  '3~16자의 그룹명을 입력해 주세요':
    'Please enter a group name between 3 and 16 characters',
  '사용할 제어 비밀번호[CU Key]를 다시 입력해 주세요':
    'Enter your confirm password[CU Key]',
  '새로 설정할 제어 비밀번호[CU Key]를 다시 입력해 주세요':
    'Enter your confirm control password',

  // AgGrid
  '드롭다운 제목': 'Change {{field}}',
  '(내 계정)': '(My account)',
  /**
   * 사용 범위 : AgGrid 내부에서 사용중인 필드 번역처리
   * 업데이트 : 23.04.05
   * 작성자 : 정태원
   */
  그룹: {
    그룹: 'Group',
    id: 'User ID',
    username: 'Name',
    phone: 'Phone',
    email: 'Email',
    role: 'Role',
    admin: 'Admin',
    user: 'User',
    function: 'Fuction',
  },
  제조업체: {
    id: 'ID',
    name: 'Manufacturer name',
    businessNumber: 'BRN',
    contactNumber: 'Head office contact number',
    'location.name': 'Head office address',
    exContactNumber: 'Factory contact number',
    'exLocation.name': 'Factroy address',
    description: 'Description',
    삭제: 'Delete',
    수정: 'Edit',
  },
  '교체/수리': {
    id: 'ID',
    title: 'Title',
    'deviceData.nickname': 'Device nickname',
    reporterName: 'Reporter name',
    reporterPhone: 'Reporter email',
    reporterEmail: 'Reporter email',
    workerName: 'Worker name',
    workerPhone: 'Worker contact number',
    workerEmail: 'Worker email',
    serviceType: 'Service type',
    reportDate: 'Report date',
    dispatchDate: 'Dispatch date',
    maintenanceDate: 'Maintenance date',
    issueDescription: 'Issue description',
    actionTaken: 'Action taken',
    description: 'Description',
    삭제: 'Delete',
    bs: 'Before service',
    as: 'After service',
    images: 'Field photos',
    reporterId: 'Reporter',
    workerId: 'Worker',
    'reporter.name': 'Reporter',
    'worker.name': 'Worker',
    status: 'Status',
    pending: 'pending',
    'in progress': 'in progress',
    completed: 'completed',
    failed: 'failed',
    수정: 'Edit',
  },
  정기점검: {
    id: 'ID',
    title: 'Title',
    'deviceData.nickname': 'Device nickname',
    reporterName: 'Reporter name',
    reporterPhone: 'Reporter contact number',
    reporterEmail: 'Reporter email',
    workerName: 'Worker name',
    workerPhone: 'Worker contact number',
    workerEmail: 'Worker email',
    serviceType: 'Service type',
    maintenanceDate: 'Maintenance date',
    description: 'Description',
    삭제: 'Delete',
    images: 'Field photos',
    checklist: 'Inspection list',
    checklistItem: 'Inspection name',
    checkContent: 'Inspection content',
    checkResult: 'result',
    imageKey: 'Inspection photo',
    normal: 'normal',
    warning: 'warning',
    error: 'error',
    reporterId: 'reporter',
    'reporter.name': 'reporter',
    workerId: 'worker',
    'worker.name': 'Worker',
    status: 'Status',
    pending: 'pending',
    'in progress': 'in progress',
    completed: 'completed',
    failed: 'failed',
    수정: 'Edit',
  },
  제품모델: {
    id: 'ID',
    name: 'Product name',
    'manufacturingCompany.name': 'Manufacturer',
    'deviceType.name': 'Device type',
    model: 'Model',
    description: 'Description',
    삭제: 'Delete',
    newManufacturingCompany: 'New Create Manufacturer',
    수정: 'Edit',
  },
  /** 사용 범위 : DeviceDetail 내부 컴포넌트
   * 업데이트 : 23.03.03
   * 작성자 : 서연주
   */

  // LogTable
  '장비 로그': 'Device Log',
  타입: 'Type',
  제목: 'Title',
  내용: 'Content',
  시간: 'Time',
  분: 'minute',
  일: 'day',
  '장비 타입': 'Device type',
  '시리얼 번호': 'Serial',
  '로그 타입': 'Log type',

  // LogType

  deviceConnection: 'connect',
  deviceConnectionTimeout: 'connect timeout',
  deviceConnectionError: 'disconnect',
  deviceConnectionUnknown: 'unknown connect',
  deviceControlApply: 'control apply',
  deviceControlTimeout: 'control timeout',
  deviceControl: 'device control',
  deviceBroken: 'device broken',
  deviceBrokenRestore: 'device restore',
  routerInvalidTimestamp: 'invalid timestamp',
  routerInvalidJson: 'invalid json',
  routerDataError: 'EDMU data Error',

  /** 사용 범위 : 에러 메세지 처리
   * 업데이트 : 23.04.07
   * 작성자 : 정태원
   */

  // NotFound

  '찾으려는 페이지가 존재하지 않습니다': 'Page Not Found',
  '요청하신 페이지를 찾을 수 없습니다.\n주소를 다시 한 번 확인해 주시거나 해당 페이지가 삭제 혹은 이전되었는지 확인해 주세요.':
    'The page you requested could not be found.\nPlease double-check the address or confirm if the page has been deleted or moved.',
  '이전 페이지': 'Previous Page',
  '메인 페이지': 'Main Page',

  // UnexpectedErrorPage

  '에러 발생': 'Error Occurred',
  '예기치 못한 에러가 발생했습니다.': 'An unexpected error has occurred.',
  '서비스에 불편을 드려 대단히 죄송합니다.\n잠시 후 다시 시도해 주시거나 같은 문제가 반복될 경우 관리자에게 문의해 주세요.':
    'We apologize for the inconvenience.\nPlease try again later or contact us if the problem persists.',

  // countries

  '나라 선택': 'Select a country',

  안도라: 'Andorra',
  아랍에미리트: 'United Arab Emirates',
  아프가니스탄: 'Afghanistan',
  '앤티가 바부다': 'Antigua and Barbuda',
  앵귈라: 'Anguilla',
  알바니아: 'Albania',
  아르메니아: 'Armenia',
  앙골라: 'Angola',
  남극: 'Antarctica',
  아르헨티나: 'Argentina',
  아메리칸사모아: 'American Samoa',
  오스트리아: 'Austria',
  호주: 'Australia',
  아루바: 'Aruba',
  '올란드 제도': 'Aland Islands',
  아제르바이잔: 'Azerbaijan',
  '보스니아 헤르체고비나': 'Bosnia and Herzegovina',
  바베이도스: 'Barbados',
  방글라데시: 'Bangladesh',
  벨기에: 'Belgium',
  부르키나파소: 'Burkina Faso',
  불가리아: 'Bulgaria',
  바레인: 'Bahrain',
  브룬디: 'Burundi',
  베냉: 'Benin',
  '세인트 바르탤르미': 'Saint Barthélemy',
  버뮤다: 'Bermuda',
  브루나이: 'Brunei',
  볼리비아: 'Bolivia',
  보네르: 'Bonaire',
  브라질: 'Brazil',
  바하마: 'Bahamas',
  부탄: 'Bhutan',
  보츠와나: 'Botswana',
  벨라루스: 'Belarus',
  벨리즈: 'Belize',
  캐나다: 'Canada',
  '코코스 제도': 'Cocos (Keeling) Islands',
  '콩고 민주 공화국': 'Democratic Republic of the Congo',
  '중앙아프리카 공화국': 'Central African Republic',
  콩고: 'Republic of the Congo',
  스위스: 'Switzerland',
  코트디부아르: "Côte d'Ivoire",
  '쿡 제도': 'Cook Islands',
  칠레: 'Chile',
  카메룬: 'Cameroon',
  중국: 'China',
  콜롬비아: 'Colombia',
  코스타리카: 'Costa Rica',
  쿠바: 'Cuba',
  카보베르데: 'Cabo Verde',
  큐라소: 'Curaçao',
  크리스마스섬: 'Christmas Island',
  사이프러스: 'Cyprus',
  체코공화국: 'Czech Republic',
  독일: 'Germany',
  지부티: 'Djibouti',
  덴마크: 'Denmark',
  도미니카: 'Dominica',
  '도미니카 공화국': 'Dominican Republic',
  알제리: 'Algeria',
  에콰도르: 'Ecuador',
  에스토니아: 'Estonia',
  이집트: 'Egypt',
  서사하라: 'Western Sahara',
  에리트레아: 'Eritrea',
  스페인: 'Spain',
  이디오피아: 'Ethiopia',
  핀란드: 'Finland',
  피지: 'Fiji',
  포클랜드섬: 'Falkland Islands',
  미크로네시아: 'Micronesia',
  '페로 군도': 'Faroe Islands',
  프랑스: 'France',
  가봉: 'Gabon',
  영국: 'United Kingdom',
  그레나다: 'Grenada',
  구루지아: 'Georgia',
  '프랑스령 기아나': 'French Guiana',
  건지: 'Guernsey',
  가나: 'Ghana',
  지브랄타: 'Gibraltar',
  그린랜드: 'Greenland',
  감비아: 'Gambia',
  기니: 'Guinea',
  과들루프: 'Guadeloupe',
  '적도 기니': 'Equatorial Guinea',
  그리스: 'Greece',
  '사우스조지아 사우스샌드위치 제도':
    'South Georgia and the South Sandwich Islands',
  과테말라: 'Guatemala',
  괌: 'Guam',
  기네비쏘: 'Guinea-Bissau',
  가이아나: 'Guyana',
  홍콩: 'Hong Kong',
  '허드 맥도날드 군도': 'Heard Island and McDonald Islands',
  온두라스: 'Honduras',
  크로아티아: 'Croatia',
  아이티: 'Haiti',
  헝가리: 'Hungary',
  인도네시아: 'Indonesia',
  아일랜드: 'Ireland',
  이스라엘: 'Israel',
  맨섬: 'Isle of Man',
  인도: 'India',
  '영인도 제도': 'British Indian Ocean Territory',
  이라크: 'Iraq',
  이란: 'Iran',
  아이슬랜드: 'Iceland',
  이탈리아: 'Italy',
  저지: 'Jersey',
  자메이카: 'Jamaica',
  요르단: 'Jordan',
  일본: 'Japan',
  케냐: 'Kenya',
  키르기스스탄: 'Kyrgyzstan',
  캄보디아: 'Cambodia',
  키리바시: 'Kiribati',
  코모로: 'Comoros',
  '세인트키츠 네비스': 'Saint Kitts and Nevis',
  북한: 'North Korea',
  대한민국: 'South Korea',
  쿠웨이트: 'Kuwait',
  '케이맨 제도': 'Cayman Islands',
  카자흐스탄: 'Kazakhstan',
  라오스: 'Laos',
  레바논: 'Lebanon',
  세인트루시아: 'Saint Lucia',
  리히텐슈타인: 'Liechtenstein',
  스리랑카: 'Sri Lanka',
  라이베리아: 'Liberia',
  레소토: 'Lesotho',
  리투아니아: 'Lithuania',
  룩셈부르크: 'Luxembourg',
  라트비아: 'Latvia',
  리비아: 'Libya',
  모로코: 'Morocco',
  모나코: 'Monaco',
  몰도바: 'Moldova',
  몬테네그로: 'Montenegro',
  '세인트 마틴': 'Saint Martin',
  마다가스카르: 'Madagascar',
  마샬군도: 'Marshall Islands',
  마케도니아: 'Macedonia',
  말리: 'Mali',
  미얀마: 'Myanmar',
  몽골: 'Mongolia',
  마카오: 'Macao',
  '북마리아나 제도': 'Northern Mariana Islands',
  마르티니크: 'Martinique',
  모리타니: 'Mauritania',
  몬트세라트: 'Montserrat',
  말타: 'Malta',
  모리셔스: 'Mauritius',
  몰디브: 'Maldives',
  말라위: 'Malawi',
  멕시코: 'Mexico',
  말레이시아: 'Malaysia',
  모잠비크: 'Mozambique',
  나미비아: 'Namibia',
  뉴칼레도니아: 'New Caledonia',
  니제르: 'Niger',
  노퍽섬: 'Norfolk Island',
  나이지리아: 'Nigeria',
  니카라과: 'Nicaragua',
  네덜란드: 'Netherlands',
  노르웨이: 'Norway',
  네팔: 'Nepal',
  나우루: 'Nauru',
  니우에: 'Niue',
  뉴질랜드: 'New Zealand',
  오만: 'Oman',
  파나마: 'Panama',
  페루: 'Peru',
  '프랑스령 폴리네시아': 'French Polynesia',
  파푸아뉴기니: 'Papua New Guinea',
  필리핀: 'Philippines',
  파키스탄: 'Pakistan',
  폴란드: 'Poland',
  '생피에르 미클롱': 'Saint Pierre and Miquelon',
  '핏케언 제도': 'Pitcairn Islands',
  푸에르토리코: 'Puerto Rico',
  팔레스타인: 'Palestine',
  포르투갈: 'Portugal',
  팔라우: 'Palau',
  파라과이: 'Paraguay',
  카타르: 'Qatar',
  레위니옹: 'Réunion',
  루마니아: 'Romania',
  세르비아: 'Serbia',
  러시아: 'Russia',
  르완다: 'Rwanda',
  사우디아라비아: 'Saudi Arabia',
  '솔로몬 제도': 'Solomon Islands',
  세이셸: 'Seychelles',
  수단: 'Sudan',
  스웨덴: 'Sweden',
  싱가포르: 'Singapore',
  세인트헬레나: 'Saint Helena',
  슬로베니아: 'Slovenia',
  '스발바르와 얀 마옌': 'Svalbard and Jan Mayen',
  슬로바키아: 'Slovakia',
  시에라리온: 'Sierra Leone',
  산마리노: 'San Marino',
  세네갈: 'Senegal',
  소말리아: 'Somalia',
  수리남: 'Suriname',
  남수단: 'South Sudan',
  '상투메 프린시페': 'São Tomé and Príncipe',
  엘살바도르: 'El Salvador',
  신트마르턴: 'Sint Maarten',
  시리아: 'Syria',
  에스와티니: 'Eswatini',
  트리스탄다쿠냐: 'Tristan da Cunha',
  '터크스 케이커스 제도': 'Turks and Caicos Islands',
  차드: 'Chad',
  토고: 'Togo',
  태국: 'Thailand',
  타지키스탄: 'Tajikistan',
  토켈라우: 'Tokelau',
  동티모르: 'East Timor',
  투르크메니스탄: 'Turkmenistan',
  튀니지: 'Tunisia',
  통가: 'Tonga',
  터키: 'Turkey',
  트리니다드토바고: 'Trinidad and Tobago',
  투발루: 'Tuvalu',
  대만: 'Taiwan',
  탄자니아: 'Tanzania',
  우크라이나: 'Ukraine',
  우간다: 'Uganda',
  '미국령 군소 제도': 'United States Minor Outlying Islands',
  미국: 'United States',
  우루과이: 'Uruguay',
  우즈베키스탄: 'Uzbekistan',
  바티칸: 'Vatican',
  '세인트빈센트 그레나딘': 'Saint Vincent and the Grenadines',
  베네수엘라: 'Venezuela',
  '영국령 버진아일랜드': 'British Virgin Islands',
  '미국령 버진아일랜드': 'U.S. Virgin Islands',
  베트남: 'Vietnam',
  바누아투: 'Vanuatu',
  '왈리스 퓌티나': 'Wallis and Futuna',
  사모아: 'Samoa',
  코소보: 'Kosovo',
  예멘: 'Yemen',
  마요트: 'Mayotte',
  남아프리카: 'South Africa',
  잠비아: 'Zambia',
  짐바브웨: 'Zimbabwe',

  // router page
  '기본 정보': 'Default information',
  'EDMU 정보': 'EDMU Information',
  'EDMU 전원': 'EDMU Power',
  '통신 장비 현황': 'Communication device status',
  '통신 장비 설정': 'Communication device setting',
  '미연결 장비 목록': 'Disconnection device list',
  '연결 장비 목록': 'Connection device list',
  protocol: 'Protocol',
  protocolId: 'Protocol ID',
  portNo: 'Port Num',
  '연결 안됨': 'Not connected',
  '통신 설정': 'Communication setting',
  '선택된 장비의 통신을 설정하려면 \n제어 비밀번호[CU Key]를 입력하세요.':
    'Enter your control password[CU Key] \nto update setting of the selected device.',
  '선택된 장비의 통신을 해제하려면 \n제어 비밀번호[CU Key]를 입력하세요.':
    'Enter your control password[CU Key] \nto disconnect the selected device.',
  '선택된 장비의 통신을 연결하려면 \n제어 비밀번호[CU Key]를 입력하세요.':
    'Enter your control password[CU Key] \nto connect selected device.',
  '라우터 통신 설정 (Master)': 'Router communication setting (Master)',
  '라우터 통신 설정 (Slave)': 'Router communication setting (Slave)',
  serverUrl: 'Server URL',
  serverCommPeriod: 'Server communication period',
  serverTimeout: 'Server timeout',
  serverRetryCount: 'Server communication retry',
  slaveCommPeriod: 'Slave communication period',
  slaveTimeout: 'Slave timeout',
  slaveRetryCount: 'Slave communication retry',
  회: 'times',
  '통신포트 1': 'Communication Port 1',
  '통신포트 2': 'Communication Port 2',
  '통신포트 3': 'Communication Port 3',
  포트: 'Port {{portNo}}',
  '국번 미할당': 'Protocol id unassigned',
  '통신 연결 해제중': 'Disconnecting {{deviceNickname}} device...',
  '통신 연결': 'Connection',
  '통신 해제': 'Disconnection',
  '실패 항목': 'Failed list',
  timeoutTooltip: 'Port {{portNumber}} {{keyName}}',
  '설정값을 입력해주세요.': 'Please enter the parameters.',
  '기존 설정값과 동일합니다': 'Same as the previous setting value',
  '기존 설정값과 동일합니다.\n새로운 값을 포함하여 시도해주세요':
    'Please try to update including new parameters',
  '현재 포트에 중복된 국번을 가진 장비가 존재합니다':
    'This protocol ID with the current port is already taken',
  '관리자 외에는 권한이 없습니다': 'Only admin account has access.',
  '포트 정보가 할당되지 않았습니다': 'Port information is not assigned',

  // device seonsor key
  '고내 온도센서 (S1)': 'Temp Sensor (S1)',
  '제상 온도센서 (S2)': 'Defrost Sensor (S2)',
  '보조 온도센서 (S3)': 'Aux Temp Sensor (S3)',
  '디지털 입력 (DIG.IN1)': 'Digital Input (DIG.IN1)',
  '디지털 입력 (DIG.IN2)': 'Digital Input (DIG.IN2)',
  '보조 온도센서 (S4)': 'Aux Temp Sensor (S4)',
  '콤프운전 누적시간': 'COMP Run Time',
  '콤프운전 누적횟수': 'COMP Run Count',
  '디스플레이 상태 표시': 'Display Status',
  'COMP 출력 상태': 'COMP Output',
  'FAN 출력 상태': 'FAN Output',
  'DEF 출력 상태': 'DEF Output',
  'AUX 출력 상태': 'AUX Output',
  '고온 알람': 'High Temp Alarm',
  '저온 알람': 'Low Temp Alarm',
  '기계 이상 알람': 'Machine Alarm',
  'Door 알람': 'Door Alarm',
  '사용자 알람': 'User Alarm',
  '센서 1 이상 알람': 'Sensor 1 Alarm',
  '센서 2 이상 알람': 'Sensor 2 Alarm',
  '센서 3 이상 알람': 'Sensor 3 Alarm',
  '센서 4 이상 알람': 'Sensor 4 Alarm',

  단위: {
    월: 'month',
    일: 'day',
    시간: 'hour',
    분: 'minute',
  },
  날씨: {
    'thunderstorm with light rain': 'thunderstorm with light rain',
    'thunderstorm with rain': 'thunderstorm with rain',
    'thunderstorm with heavy rain': 'thunderstorm with heavy rain',
    'light thunderstorm': 'light thunderstorm',
    thunderstorm: 'thunderstorm',
    'heavy thunderstorm': 'heavy thunderstorm',
    'ragged thunderstorm': 'ragged thunderstorm',
    'thunderstorm with light drizzle': 'thunderstorm with light drizzle',
    'thunderstorm with drizzle': 'thunderstorm with drizzle',
    'thunderstorm with heavy drizzle': 'thunderstorm with heavy drizzle',
    'light intensity drizzle': 'light intensity drizzle',
    drizzle: 'drizzle',
    'heavy intensity drizzle': 'heavy intensity drizzle',
    'light intensity drizzle rain': 'light intensity drizzle rain',
    'drizzle rain': 'drizzle rain',
    'heavy intensity drizzle rain': 'heavy intensity drizzle rain',
    'shower rain and drizzle': 'shower rain and drizzle',
    'heavy shower rain and drizzle': 'heavy shower rain and drizzle',
    'shower drizzle': 'shower drizzle',
    'light rain': 'light rain',
    'moderate rain': 'moderate rain',
    'heavy intensity rain': 'heavy intensity rain',
    'very heavy rain': 'very heavy rain',
    'extreme rain': 'extreme rain',
    'freezing rain': 'freezing rain',
    'light intensity shower rain': 'light intensity shower rain',
    'shower rain': 'shower rain',
    'heavy intensity shower rain': 'heavy intensity shower rain',
    'ragged shower rain': 'ragged shower rain',
    'light snow': 'light snow',
    snow: 'snow',
    'heavy snow': 'heavy snow',
    sleet: 'sleet',
    'light shower sleet': 'light shower sleet',
    'shower sleet': 'shower sleet',
    'light rain and snow': 'light rain and snow',
    'rain and snow': 'rain and snow',
    'light shower snow': 'light shower snow',
    'shower snow': 'shower snow',
    'heavy shower snow': 'heavy shower snow',
    mist: 'mist',
    smoke: 'smoke',
    haze: 'haze',
    'sand/dust whirls': 'sand/dust whirls',
    fog: 'fog',
    sand: 'sand',
    dust: 'dust',
    'volcanic ash': 'volcanic ash',
    squalls: 'squalls',
    tornado: 'tornado',
    'clear sky': 'clear sky',
    'few clouds': 'few clouds',
    'scattered clouds': 'scattered clouds',
    'broken clouds': 'broken clouds',
    'overcast clouds': 'overcast clouds',
  },
  /**
   * 장비 제어 카드와 관련된 번역키
   */
  // device control

  '범위 내의 정수값을 입력해주세요':
    'Please enter an integer value within the range',
  '장비 제어 성공': 'Control Success : [{{keyName}}]',
  '장비 제어 실패': 'Control Fail: [{{keyName}}]',

  '제어 요청 중': 'Control requesting...',
  '요청 중 문제가 발생했습니다. 다시 시도해주세요.':
    'Something went wrong, please try again',
  '장비 데이터 수신 대기 중': 'Waiting for the device response',

  배율: 'Step',

  // device Key

  '전원 ON/OFF': 'Power ON/OFF',
  '전원 인가 시간': 'Power supply time',
  '운전 ON/OFF': 'Run ON/OFF',
  '운전 시간': 'Run time',
  온도: 'Temperature',
  이산화탄소: 'Carbon dioxide',
  조도: 'Illumination',
  통과량: 'Pass count',
  이물량: 'Alarm count',
  '고내온도센서 B1 오프셋': 'High internal temperature sensor B1 offset',
  '제상복귀센서 B2 오프셋': 'Defrost return sensor B2 offset',
  '보조온도센서 B3 오프셋': 'Auxiliary temperature sensor B3 offset',
  'AUX1 출력기능 선택': 'AUX1 output function selection',
  '보조온도센서 B4 오프셋': 'Auxiliary temperature sensor B4 offset',
  '디지털 입력 1 접점 종류': 'Digital input 1 contact type',
  '디지털 입력 2 접점 종류': 'Digital input 2 contact type',
  '고온알람 온도 설정값': 'High temperature alarm limit',
  '저온알람 온도 설정값': 'Low temperature alarm limit',
  '고온알람 지연시간': 'High temperature alarm delay time',
  '저온알람 지연시간': 'Low temperature alarm delay time',
  '디지털입력 DIG.IN1 설정': 'Digital input DIG.IN1',
  '디지털입력 DIG.IN2 설정': 'Digital input DIG.IN2',
  '센서 타입 설정': 'Sensor type',
  '온도 표시 단위': 'Temperature display unit',
  '온도조절 모드': 'Temperature control mode',
  '센서 수량 설정': 'Sensor quantity',
  '야간운전 사용 여부': 'Use of night run',
  '야간운전 설정 델타값': 'Night run set delta value',
  '야간운전 제어편차': 'Night run control deviation',
  '평시 희망온도': 'Normal desired temperature',
  '평시 제어편차': 'Normal control deviation',
  '난방운전 희망온도': 'Heating operation desired temperature',
  '난방운전 제어편차': 'Heating operation control deviation',
  '통신 ID': 'Communication ID',
  '제상 종료 방법': 'Defrosting termination method',
  '펌프다운 시간': 'Pump down time',
  '배수 시간': 'Drainage time',
  '제상 종료 온도': 'Defrosting termination temperature',
  '제상 스케쥴 타입': 'Defrosting schedule type',
  '제상 주기': 'Defrosting cycle',
  '제상/배수 후 온도표시지연': 'Temperature display delay after defrost/drain',
  '1회 제상 시각': '1st defrost time',
  '2회 제상 시각': '2nd defrost time',
  '3회 제상 시각': '3rd defrost time',
  '4회 제상 시각': '4th defrost time',
  '5회 제상 시각': '5th defrost time',
  '6회 제상 시각': '6th defrost time',
  '7회 제상 시각': '7th defrost time',
  '8회 제상 시각': '8th defrost time',
  '야간운전 시작시간': 'Night run start time',
  '야간운전 종료시간': 'Night run end time',
  '예약운전 시작시간': 'Reserved run start time',
  '예약운전 종료시간': 'Reserved run end time',
  '예약운전 사용여부': 'Use of reserved run',
  '예약 시간 설정': 'Reserved time setting',
  'SV 정지 시 팬 동작': 'Fan operation when SV stops',
  '팬 정지 지연시간': 'Fan stop delay time',
  '제상시 팬 운전모드': 'Fan operation mode during defrosting',
  '제상 후 팬 운전지연': 'Fan operation delay after defrosting',
  'SV 출력 지연시간': 'SV output delay time',
  '장비 리부팅': 'Device rebooting',
  '공장 초기화': 'Factory reset',
  '강제 제상 시작': 'Forced defrosting start',
  '강제 제상 종료': 'Forced defrosting end',
  항온설정온도: 'Constant temperature',
  '자동제상 온도편차 설정값':
    'Automatic defrosting temperature deviation set value',
  '자동제상 SV 지속시간': 'Automatic defrosting SV duration',
  'SV 누적에 의한 제상 누적시간':
    'Accumulated defrosting time by SV accumulation',

  // device card group name
  '예약/야간 운전 설정': 'Reservation/Night run',
  '디스플레이 설정': 'Display',
  '제어 설정': 'Control',
  '센서 설정': 'Sensor',
  '출력 설정': 'Output',
  '디지털 설정': 'Digital',
  '알람 설정': 'Alarm',
  '제상 설정': 'Defrost',
  '릴레이 설정': 'Relay setting',

  // device card name (임시)
  '야간 운전 온도 제어 편차': 'Night run temperature control deviation',
  '야간 운전 시간 설정': 'Night run time',
  '야간 운전 온도 보정': 'Night run temperature correction',
  '현재 시간': 'Current time',
  'AUX 출력 기능': 'AUX output function',
  '제상 제어 방법': 'Defrosting control method',
  'AI 온도 편차': 'AI temperature deviation',
  'SV 누적 제상 설정': 'SV accumulated defrosting',
  'SV 지속시간': 'SV duration',
  '릴레이 1 ON/OFF': 'Relay 1 ON/OFF',
  '릴레이 2 ON/OFF': 'Relay 2 ON/OFF',
  '릴레이 3 ON/OFF': 'Relay 3 ON/OFF',
  '릴레이 4 ON/OFF': 'Relay 4 ON/OFF',
  '릴레이 5 ON/OFF': 'Relay 5 ON/OFF',
  '릴레이 6 ON/OFF': 'Relay 6 ON/OFF',
  '릴레이 7 ON/OFF': 'Relay 7 ON/OFF',
  '릴레이 8 ON/OFF': 'Relay 8 ON/OFF',
  '릴레이 9 ON/OFF': 'Relay 9 ON/OFF',

  // dropdown

  섭씨: 'Celsius',
  화씨: 'Fahrenheit',
  '알람 출력': 'Alarm output',
  '난방 제어 출력': 'Heating control output',
  '조명 제어 출력': 'Lighting control output',
  '디지털 입력 1 출력': 'Digital input 1 output',
  '디지털 입력 2 출력': 'Digital input 2 output',
  A접점: 'A contact',
  B접점: 'B contact',
  미사용: 'Not used',
  '제상시각 입력': 'Defrost time input',
  '기계이상 알림': 'Machine anomaly alert',
  '사용자 알람 입력': 'User alarm input',
  '열림 경고': 'Open warning',
  '운전 ON/OFF 제어': 'Run ON/OFF control',
  '냉각운전(희망온도 + 제어편차, 초기값)':
    'Cooling (set temp. + control deviation, initial value)',
  '냉각운전(희망온도 ± 제어편차)': 'Cooling (set temp. ± control deviation)',
  '난방운전(난방운전온도-난방운전온도편차)':
    'Heating (set heating temp. - heating deviation)',
  '상태 모니터링(최대 센서 3개)': 'Status monitoring (up to 3 sensors)',
  '설정시간 동안 제상': 'Defrosting during set time',
  '설정시간 또는 제상종료 온도 도달 후 종료':
    'Ends after reaching the set time or defrost termination temperature',
  '제상 설정시간 동안 제상(제상종료온도 도달시 히터출력 OFF, 제상종료온도보다 3℃이하시 히터 재출력 반복)':
    'Defrosting during set time (Heater OFF at defrost termination temp, restarts when 3℃ or less)',
  제상없음: 'No defrost',
  주기제상: 'Periodic defrost',
  '설정시간에 의한 제상': 'Defrost by set time',
  인공지능제상: 'Artificial Intelligence defrost',
  '콤프 누적설정값에 의한 제상':
    'Defrost by compressor cumulative setting value',
  '냉각운전시 SV 운전연동(항상 팬정지 지연시간 적용 후 동작)':
    'SV sync during cooling (Always run after fan stop delay)',
  '냉각운전시 항상 운전(제상시 설정에 따라 운전)':
    'Always run during cooling (Operates based on defrosting settings)',
  '1~65535의 값을 입력해 주세요': 'Please enter a value between 1 and 65535',
  '0~255의 값을 입력해 주세요': 'Please enter a value between 1 and 255',
  '서버 통신 주기보다 작은 값을 입력해주세요':
    'Please enter a value smaller than the server communication period',
  'Slave 통신 주기보다 작은 값을 입력해주세요':
    'Please enter a value smaller than the Slave communication period',
  '국번은 0~255의 값을 입력해 주세요':
    'Please enter a value between 0 and 255 for the protocol ID',
  '유효하지 않은 언어입니다': 'Invalid language',
  '토큰은 필수 항목입니다': 'Token is required',
  '비밀번호는 필수 항목입니다': 'Password is required',
  '그룹 이름은 필수 항목입니다': 'Group name is required',
  '제어 비밀번호[CU Key]는 필수 항목입니다':
    'Control Password[CU Key] is required',
  '사용자 ID는 필수 항목입니다': 'User ID is required',
  '장비 ID는 필수 항목입니다': 'Device ID is required',
  '장비 시리얼은 필수 항목입니다': 'Device serial is required',
  '장비 추가에 성공하였습니다': 'Device registration successful',
  '제품 추가에 성공하였습니다': 'Product registration successful',
  'EDMU 연결이 필요합니다': 'EDMU connection is required',
  '헤더는 필수 항목입니다': 'Header is required',
  '제목은 필수 항목입니다.': 'Title is required.',
  '고장 접수일은 필수 항목입니다.': 'Report date is required.',
  '점검일자는 필수 항목입니다.': 'Maintenance date is required.',
  '서비스 종류는 필수 항목입니다.': 'Service type is required.',
  '최대 4장': 'Maximum 4 images',
  '용량 제한 5MB': 'Capacity limit 5MB',
  /**
   * 장비 설비 상태와 관련된 키 (DB)
   */

  // group name
  '항목 출력 상태': 'Device output status',
  '알람 상태': 'Alarm status',

  // management Key
  '센서 1 이상알람': 'Sensor 1 Alarm',
  '센서 2 이상알람': 'Sensor 2 Alarm',
  '센서 3 이상알람': 'Sensor 3 Alarm',
  '센서 4 이상알람': 'Sensor 4 Alarm',

  // dashboard

  '총 장비 수': 'Total',
  '구동 장비 수': 'Active',
  '통신불량 장비 수': 'Bad Connection',
  '미연결 장비 수': 'Unconnected',
  '장비 유지율': 'Utilization Rate',

  // status board
  '장비 현황판': 'Device Status Board',
  '현황판 로그': 'Status board log',
  '등록된 현황판 정보가 없습니다': 'No status board information exists',
  '현황판 삭제': 'Delete board',
  '데이터 이름': 'Data name',
  '데이터 종류': 'Data type',
  '서버 주소': 'Server Url',
  '서버 통신 주기': 'Server communication period',
  '서버 타임아웃': 'Server timeout',
  '서버 통신 재시도': 'Server communication retry',
  'Slave 통신 주기': 'Slave communication period',
  'Slave 타임아웃': 'Slave timeout',
  'Slave 통신 재시도': 'Slave communication retry',
  'Slave 포트 번호': 'Slave port no',
  '포트 번호': 'Port no',
  프로토콜: 'Protocol',
  릴레이1: 'Relay1',
  릴레이2: 'Relay2',
  릴레이3: 'Relay3',
  릴레이4: 'Relay4',
  릴레이5: 'Relay5',
  릴레이6: 'Relay6',
  릴레이7: 'Relay7',
  릴레이8: 'Relay8',
  릴레이9: 'Relay9',
  '장비를 선택해주세요': 'Please select a device',
  '데이터를 선택해주세요': 'Please select a data',
  '이 작업을 수행하면 현황판 정보가 영구적으로 삭제됩니다. 현황판을 정말 삭제하시겠습니까?':
    'This action will remove your device status board information.\nAre you sure you want to delete the device status board?',
  '선택된 장비를 현황판에서 삭제하겠습니까?':
    'Are you sure delete the selected device from the status board?',
  '데이터 개수는 최대 10개까지 가능합니다': 'You can select up to 10 data',
  '등록된 장비': 'Registered device',
  시: 'Hour',

  // Manufacturer Setting

  '제조업체 추가': 'Create manufacturer',
  '정기 점검 추가': 'Create regular inspection history',
  '점검항목 추가': 'Add inspection item',
  '제품 추가': 'Create product',
  '새로운 제품 추가': 'New Create Product',
  '아래 제조업체 정보를 입력하여 새로운 제조업체를 추가하세요':
    'Fill in the fields below to create a new manufacturer',
  '아래 정보를 입력하여 새로운 이력을 추가하세요':
    'Fill in the fields below to create a new history',
  '아래 정보를 입력하여 새로운 점검항목을 추가하세요':
    'Fill in the fields below to create a new inspection item',
  '제조업체 이름': 'Manufacturer Name',
  '아래 정보를 입력하여 새로운 제품을 추가하세요':
    'Fill in the fields below to create a new product',
  '아래 정보를 입력하여 새로운 사용자를 추가하세요':
    'Fill in the fields below to create a new user',
  비고: 'Description',
  본사: 'Head Office',
  '본사 정보': 'Head Office Info',
  '본사 연락처': 'Head Office Contact Number',
  '본사 위치': 'Head Office Main Address',
  '본사 우편번호': 'Head Office Postal Code',
  '본사 상세주소': 'Head Office Detail Address',
  공장: 'Factory',
  '공장 정보': 'Factory Info',
  '공장 연락처': 'Factory Contact Number',
  '공장 위치': 'Factory Main Address',
  '공장 우편번호': 'Factory Postal Code',
  '공장 상세주소': 'Factory Detail Address',
  '제조업체 이름은 필수 항목입니다.': 'Manufacturer name is required.',
  '사업자 등록번호는 항목입니다.': 'BRN is required.',
  '본사 연락처는 필수 항목입니다.': 'Head office contact number is required.',
  '유효한 본사 연락처를 입력해 주세요':
    'Please enter a valid head office contact number',
  '유효한 공장 연락처를 입력해 주세요':
    'Please enter a valid factory contact number',
  '본사 주소는 필수 항목입니다.': 'Head office main address is required.',
  '필수 항목입니다.': 'Required.',
  '점검 항목은 필수 항목입니다.': 'Inspection name is required.',
  '점검 내용은 필수 항목입니다.': 'Inspection content is required.',
  '작업자는 필수 항목입니다.': 'Worker is required.',
  '255자 이하의 비고를 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 제조업체 이름을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 사업자 등록번호를 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '20자 이하의 본사 연락처를 입력해 주세요':
    'Too many characters. Maximum 20 characters',
  '20자 이하의 공장 연락처를 입력해 주세요':
    'Too many characters. Maximum 20 characters',
  '255자 이하의 문제 현상을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 조치 내용을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 제목을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '255자 이하의 내용을 입력해 주세요':
    'Too many characters. Maximum 255 characters',
  '이력 삭제': 'Delete history',
  '사진 삭제': 'Delete photo',
  '제품 삭제': 'Delete product',
  '선택된 사진을 삭제하겠습니까?': 'Are you sure delete the selected photo?',
  '선택된 이력을 삭제하겠습니까?': 'Are you sure delete the selected history?',
  '선택된 제품을 삭제하겠습니까?': 'Are you sure delete the selected product?',
  '사진 삭제를 완료했습니다': 'Successfully deleted the photo',
  '제품 삭제를 완료했습니다': 'Successfully deleted the product',
  '사진 추가가 완료되었습니다': 'Successfully created the photo',
  '삭제됐거나 존재하지 않는 교체/수리 이력입니다.':
    'Deleted or non-existent replacement/repair history.',
  '새로 설정할 제목을 입력해 주세요': 'Enter your new title',
  '새로 설정할 접수일자를 선택해 주세요': 'Enter your new report date',
  '새로 설정할 출동일자를 선택해 주세요': 'Enter your new dispatch date',
  '새로 설정할 점검일자를 선택해 주세요': 'Enter your new maintenance date',
  '새로 설정할 문제 현상을 입력해 주세요': 'Enter your new issue description',
  '새로 설정할 조치 내용을 입력해 주세요': 'Enter your new action taken',
  '새로 설정할 비고를 입력해 주세요': 'Enter your new issue description',
  '새로 설정할 정보 이름을 입력해 주세요': 'Enter your new information name',
  '새로 설정할 정보 내용을 입력해 주세요': 'Enter your new information content',
  '이전 값으로 원복하였습니다': 'Restored to previous value',
  '정기 점검 삭제': 'Delete regular inspection history',
  '정보 추가': 'Create information',
  '상세 데이터 정보 이름은 필수 항목입니다': 'Information name is required.',
  '이브이 세상': 'EVSS',
  '장비 연결 정보가 서버와 일치하지 않습니다.':
    'Device connection information does not match the server.',
  '관리자가 소속될 그룹 정보를 입력해주세요':
    'Please enter the group information to which the admin belongs',
  '관리자 계정 정보를 입력해주세요': 'Please enter admini account information',
  '관리자 이름': 'Admin name',
  '관리자 연락처': 'Admin contact number',
  '관리자 이메일': 'Admin email',
  '계정 비밀번호': 'Account password',
  '계정 비밀번호 재확인': 'Account confirm password',
  '관리자 이름을 입력해 주세요 (예시: 홍길동)':
    'Enter your admin name (example: John Doe)',
  '관리자 연락처를 입력해 주세요 (예시: 010-1234-5678)':
    'Enter your admin phone (example: 555-555-1234)',
  '관리자 이메일을 입력해 주세요 (예시: okomo@unirobotics.co.kr)':
    'Enter your admin email (example: okomo@unirobotics.co.kr)',
  '계정 비밀번호를 입력해 주세요': 'Enter your account password',
  '계정 비밀번호를 다시 입력해 주세요': 'Enter your confirm account password',
  '관리자 계정을 생성하려면 제어 비밀번호[CU Key]를 입력하세요.':
    'Enter your control password[CU Key] to create admin account.',
  '새로운 관리자 계정 추가가 완료되었습니다.':
    'New admin account has been created successfully.',
  '사용자 추가': 'Create user',
};

export default enJSON;
