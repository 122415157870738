import Authenticated from 'src/components/Authenticated';
import { Navigate } from 'react-router-dom';
import SidebarLayout from 'src/components/SidebarLayout';
import accountRoutes from './account';
import playgroundRoutes from './general';
import monitoringRoutes from './monitoring';
import manufacturingRoutes from './manufacturing';
import settingRoutes from './setting';
import maintenanceRoutes from './maintenance';
import { Loader, retryLazy } from 'src/utils/lazyUtil';
import locationRoutes from './location';

const NotFound = Loader(
  retryLazy(() => import('src/pages/exception/Notfound')),
);

const router = [
  {
    path: '',
    element: (
      <Authenticated>
        <SidebarLayout />
      </Authenticated>
    ),
    children: [
      {
        path: '',
        element: <Navigate to="monitoring" replace />,
      },
      {
        path: 'monitoring',
        children: monitoringRoutes,
      },
      {
        path: 'maintenance',
        children: maintenanceRoutes,
      },
      {
        path: 'location',
        children: locationRoutes,
      },
      {
        path: 'manufacturing',
        children: manufacturingRoutes,
      },
      {
        path: 'setting',
        children: settingRoutes,
      },
      {
        path: 'playground',
        children: playgroundRoutes,
      },
    ],
  },
  {
    path: 'account',
    children: accountRoutes,
  },
  // {
  //   path: 'pricing',
  //   element: <Pricing />,
  // },
  {
    path: '*',
    element: <NotFound replace />,
  },
];

export default router;
