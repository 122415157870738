import { styled, TextField } from '@mui/material';

export const PasswordFieldWrapper = styled(TextField)(
  () => `
  .MuiOutlinedInput-root {
    padding-right: 0px;

    .MuiInputBase-input {
      padding-right: 50px;
    }

    .MuiInputAdornment-root {
      position: absolute;
      right: 20px;

      .MuiSvgIcon-root {
        width: 1.3rem;
        height: 1.3rem;
        cursor: pointer;

        @media (max-width: 599px) {
          width: 1rem;
          height: 1rem;
        }
      }
    }
  }
`,
);
