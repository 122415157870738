import { Box, Typography } from '@mui/material';
import PageHelmet from 'src/components/PageHelmet';
import FirebaseAuthLogin from './components/LoginFirebaseAuth';
import { useTranslation } from 'react-i18next';
import {
  Content,
  MainContent,
  FormWrapper,
  ContentWrapper,
  FooterWrapper,
} from '../style';
import Header from 'src/components/Header';

function LoginCover() {
  const { t } = useTranslation();

  return (
    <>
      <PageHelmet message={'로그인'} />
      <div id="information-container">
        <Header navPosition="right" className="reveal-from-bottom" />
      </div>
      <Content>
        <MainContent>
          <FormWrapper maxWidth="sm">
            <ContentWrapper variant="outlined">
              <Box textAlign="center">
                <Typography
                  style={{
                    justifyContent: 'flex-start',
                  }}
                  className="main_title"
                  variant="h2"
                >
                  {t('로그인')}
                </Typography>

                <Typography
                  variant="h4"
                  color="text.secondary"
                  fontWeight="normal"
                  className="sub_title"
                >
                  {t('계정에 로그인하려면 각 필드의 정보를 입력해 주세요')}
                </Typography>
              </Box>
              <FirebaseAuthLogin />
              <FooterWrapper> </FooterWrapper>
            </ContentWrapper>
          </FormWrapper>
        </MainContent>
      </Content>
    </>
  );
}

export default LoginCover;
