import React from 'react';
import { Card, Grid, styled, Button, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { HeroH2, HeroP } from '../style';

const TemporaryInfoBox = styled(Card)(
  ({ theme }) => `
      overflow: hidden;
      position: relative;
      padding: ${theme.spacing(4)};
      @media (max-width: 599px) {
        padding: ${theme.spacing(0.5)};
     }
`,
);

const AdvantagesPage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );
  const mobile = useMediaQuery('(max-width: 1319px)');

  return (
    <section {...props} className={outerClasses}>
      <div className="reveal-from-bottom" data-reveal-delay="200">
        <TemporaryInfoBox>
          {mobile ? (
            <div
              style={{
                marginTop: '-0.25rem',
              }}
            >
              <Grid container justifyContent={'center'}>
                <HeroH2>Platform advantages</HeroH2>
              </Grid>
              <img
                style={{
                  width: '100%',
                  height: '100%',
                  marginBottom: '3rem',
                }}
                src={'/static/images/info/advantages_1.png'}
                alt="FeaturesIcon"
              />
              <HeroP style={{ padding: '1rem' }}>
                The Okomo Enterprise Enegry Platform has been designed with
                heavy-duty, enterprise-grade enegry solutions in mind. It
                banishes a monolithic approach to architecture in favour of
                highly portable microservices, which allow for flexible
                rearrangement and customization even in the middle of the
                solution's lifecycle. It enables cloud-native scalability and
                resilience while offering full freedom in regard to the type of
                deployment. Combined with its technology-agnostic attitude
                towards development and DevOps, Okomo Enterprise puts you in
                charge of your enegry innovations by holding out extensive
                freedom of technology, deployment models, and customiza
              </HeroP>
              <div className="reveal-from-bottom" data-reveal-delay="200">
                <div>
                  <Grid container justifyContent={'center'}>
                    <Button
                      style={{
                        padding: '1rem',
                        marginTop: '2rem',
                        marginBottom: '2.5rem',
                      }}
                      variant="contained"
                      wideMobile
                    >
                      Learn more
                    </Button>
                  </Grid>
                </div>
              </div>
            </div>
          ) : (
            <Grid container justifyContent={'center'}>
              <div style={{ display: 'flex' }}>
                <div
                  style={{
                    marginTop: '-3rem',
                    marginBottom: '3rem',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    maxWidth: '400px',
                  }}
                >
                  <HeroH2>Platform advantages</HeroH2>
                  <HeroP>
                    The Okomo Enterprise Enegry Platform has been designed with
                    heavy-duty, enterprise-grade enegry solutions in mind. It
                    banishes a monolithic approach to architecture in favour of
                    highly portable microservices, which allow for flexible
                    rearrangement and customization even in the middle of the
                    solution's lifecycle. It enables cloud-native scalability
                    and resilience while offering full freedom in regard to the
                    type of deployment. Combined with its technology-agnostic
                    attitude towards development and DevOps, Okomo Enterprise
                    puts you in charge of your enegry innovations by holding out
                    extensive freedom of technology, deployment models, and
                    customiza
                  </HeroP>
                  <div className="reveal-from-bottom" data-reveal-delay="200">
                    <div>
                      <Button
                        style={{ padding: '1rem', marginTop: '5rem' }}
                        variant="contained"
                        wideMobile
                      >
                        Learn more
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <img
                style={{
                  width: '50rem',
                  height: '52.5rem',
                  marginLeft: '3rem',
                }}
                src={'/static/images/info/advantages_1.png'}
                alt="FeaturesIcon"
              />
            </Grid>
          )}
        </TemporaryInfoBox>
      </div>
    </section>
  );
};

export default AdvantagesPage;
