import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postNewInspectionHistory = async (newInspectionInfor) => {
  const res = await axios.post('evss/device/regular-maintenance-history', {
    deviceId: newInspectionInfor.deviceId,
    reporterId: newInspectionInfor.reporterId,
    reportDate: newInspectionInfor.completeDate,
  });

  return res;
};

export const getAllInspectionHistories = async (requestInfor) => {
  const res = await axios.get('evss/device/regular-maintenance-history', {
    params: {
      'device-id': requestInfor?.deviceId || null,
      'report-date[start]': requestInfor?.reportStartDate || null,
      'report-date[end]': requestInfor?.reportEndDate || null,
      'maintenance-date[start]': requestInfor?.maintenanceStartDate || null,
      'maintenance-date[end]': requestInfor?.maintenanceEndDate || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
      status: requestInfor?.status || null,
    },
  });
  return res.data;
};

export const getInspectionHistoryById = async (requestInfor) => {
  const res = await axios.get('evss/device/regular-maintenance-history', {
    params: {
      id: requestInfor?.historyId,
      'device-id': requestInfor?.deviceId || null,
      start: requestInfor?.start || null,
      end: requestInfor?.end || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return res.data;
};

export const patchInspectionHistory = async (modifyInfo) => {
  const res = await axios.patch(
    `evss/device/regular-maintenance-history?id=${modifyInfo.id}`,
    { ...modifyInfo.data, timeZone: modifyInfo.timeZone },
  );
  return res;
};

export const deleteInspectionHistory = async (historyId) => {
  const res = await axios.delete(
    `evss/device/regular-maintenance-history?id=${historyId}`,
  );
  return res;
};

export const postInspectionImage = async (imageInfor) => {
  const formData = new FormData();

  if (imageInfor.images?.length) {
    imageInfor.images.forEach((eachImageData) => {
      formData.append(
        'image',
        imageToBlob(eachImageData.image),
        eachImageData.name,
      );
    });
  }

  const res = await axios.post(
    `evss/image/regular-maintenance-history?type-id=${imageInfor.id}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res;
};

export const deleteInspectionImage = async (imageId) => {
  const res = await axios.delete(`evss/image?id=${imageId}`);
  return res;
};

export const patchCheckListApi = async (modifyInfo) => {
  try {
    await axios.patch(`evss/device/regular-maintenance-history/check/list`, {
      checkList: modifyInfo,
    });
    return true;
  } catch (error) {
    return error;
  }
};
