import { styled, Box } from '@mui/material';

export const MainContent = styled(Box)(
  () => `
          padding: 18px;
          height: 100%;
          display: flex;
          flex: 1;
          overflow: auto;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .card_wrapper {
            text-align: center;
            margin-top : 27px;
            padding : 36px;

            .content {
              margin-bottom : 36px;
            }
          }

          .image_box{
            img {
              height : 180px;
              width : 180px;
            }
          }

          .title {
            marginTop: 16px;
            marginBottom: 16px;
            white-space: pre-line;
            word-break: keep-all;
            font-size : 1.3rem;
          }

          .text_content {
            word-break: keep-all;
            font-size : 1rem;
          }


          .divider {
            margin-top: 36px;
            margin-bottom: 36px;
          }


          @media (max-width: 599px) {

            
            .image_box{
              img {
                height : 150px;
                width : 150px;
              }
            }

            .title {
              font-size: 0.9rem;
            }
      
            .text_content {
              font-size: 0.7rem;
            }
          }

      `,
);
