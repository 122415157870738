import { Navigate } from 'react-router-dom';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const Account = Loader(retryLazy(() => import('src/pages/setting/Account')));
const Company = Loader(retryLazy(() => import('src/pages/setting/Company')));
const Help = Loader(retryLazy(() => import('src/pages/setting/Help')));
const Market = Loader(retryLazy(() => import('src/pages/setting/Market')));
const PasswordUpdate = Loader(
  retryLazy(() => import('src/pages/setting/PasswordUpdate')),
);
const Preference = Loader(
  retryLazy(() => import('src/pages/setting/Preference')),
);
const SocialConnection = Loader(
  retryLazy(() => import('src/pages/setting/SocialConnection')),
);
// const Subscription = Loader(
//   retryLazy(() => import('src/pages/setting/Subscription')),
// );
const Terms = Loader(retryLazy(() => import('src/pages/setting/Terms')));

const Theme = Loader(retryLazy(() => import('src/pages/setting/Theme')));

const settingRoutes = [
  {
    path: '',
    element: <Navigate to="account" replace />,
  },
  {
    path: 'account',
    element: <Account />,
  },
  {
    path: 'company',
    element: <Company />,
  },
  {
    path: 'password',
    element: <PasswordUpdate />,
  },
  {
    path: 'social-connection',
    element: <SocialConnection />,
  },
  {
    path: 'preference',
    element: <Preference />,
  },
  // {
  //   path: 'subscription',
  //   element: <Subscription />,
  // },
  {
    path: 'market',
    element: <Market />,
  },
  {
    path: 'terms',
    element: <Terms />,
  },
  {
    path: 'help',
    element: <Help />,
  },
  {
    path: 'theme',
    element: <Theme />,
  },
];

export default settingRoutes;
