import { makeAutoObservable, runInAction } from 'mobx';
import {
  postNewManufacturerLocation,
  getManufacturerLocation,
  postManufacturer,
  getManufacturer,
  patchManufacturer,
  deleteManufacturer,
  updateManufacturersLocationApi,
  deleteManufacturersLocationApi,
} from 'src/api/manufacturing';

class manufacturingStore {
  alertStore;

  products = [];

  manufacturers = [];

  storedManufacturerAddress = [];

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  createNewManufacturerLocation = async (newAddress) => {
    try {
      const id = await postNewManufacturerLocation(newAddress).then((res) => {
        this.alertStore.setAlertOpen(
          'success',
          '새로운 건물 추가가 완료되었습니다.',
        );
        if (res.code) throw res;
        return res.data.id;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  getManufacturerLocation = async () => {
    try {
      const res = await getManufacturerLocation();
      runInAction(() => {
        if (Array.isArray(res) && res.length) {
          res.splice(
            res.findIndex((eachRes) => eachRes.type === 'group'),
            1,
          );
          this.storedManufacturerAddress = res;
        }
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  createManufacturer = async (
    name,
    businessNumber,
    contactNumber,
    contactNumberCountryCode,
    exContactNumber,
    exContactNumberCountryCode,
    locationId,
    exLocationId,
    description,
  ) => {
    try {
      const manufacturerInfoRes = await postManufacturer(
        name,
        businessNumber,
        contactNumber,
        contactNumberCountryCode,
        exContactNumber,
        exContactNumberCountryCode,
        locationId,
        exLocationId,
        description,
      ).then((res) => {
        if (res.response) {
          throw res;
        }
        this.alertStore.setAlertOpen(
          'success',
          '제조업체 추가에 성공하였습니다',
        );
        return res;
      });
      return manufacturerInfoRes.data;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getAllManufacturerInfo = async () => {
    try {
      const res = await getManufacturer();
      this.manufacturers = res;
      return true;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getManufacturerInfoById = async (drawingId) => {
    try {
      const res = await getManufacturer(drawingId);
      runInAction(() => {
        this.drawingInfo = res;
      });
      return true;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateManufacturerInfo = async (modifyInfo) => {
    try {
      const res = await patchManufacturer(modifyInfo);
      if (res.code) {
        throw res;
      }
      this.alertStore.setAlertOpen('success', '제조업체 수정을 완료하였습니다');
      this.getAllManufacturerInfo();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteManufacturerInfoById = async (selectedManufacturers) => {
    try {
      const deleteManufacturerTasks = selectedManufacturers.map(
        (manufacturerInfo) => {
          return deleteManufacturer(manufacturerInfo.id);
        },
      );
      return Promise.all(deleteManufacturerTasks)
        .then(() => {
          this.getAllManufacturerInfo();
          this.alertStore.setAlertOpen(
            'success',
            `제조업체 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateManufacturersLocation = async (modifyInfo) => {
    try {
      const res = await updateManufacturersLocationApi(modifyInfo);
      if (res.code) {
        throw res;
      }

      this.getManufacturerLocation();
      this.getAllManufacturerInfo();
      this.alertStore.setAlertOpen('success', '주소 수정을 완료하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteManufacturersLocation = async (selectedManufacturerLocations) => {
    try {
      const deleteManufacturerLocationsTasks =
        selectedManufacturerLocations.map((locationInfo) => {
          return deleteManufacturersLocationApi(locationInfo.id);
        });
      return Promise.all(deleteManufacturerLocationsTasks)
        .then(() => {
          this.getAllManufacturerInfo();
          this.alertStore.setAlertOpen(
            'success',
            `제조업체 주소 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };
}

export default manufacturingStore;
