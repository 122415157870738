import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { HeroH1, HeroSpan, HeroP } from '../style';
import { observer } from 'mobx-react-lite';
import { useTheme, alpha, Button, useMediaQuery } from '@mui/material';
import PageTab from 'src/components/PageTab';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const FeaturesImagePage = observer(
  ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
      <section
        {...props}
        className={outerClasses}
        style={{ background: alpha(theme.palette.primary.main, 0.175) }}
      >
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content reveal-from-bottom">
              <HeroH1 className="mt-0 mb-8 ">
                <HeroSpan>Okomo</HeroSpan> platform features
              </HeroH1>
              <img
                style={{
                  width: mobile ? '15rem' : '20rem',
                  height: mobile ? '15rem' : '20rem',
                  marginTop: mobile ? '0rem' : '5rem',
                }}
                src={'/static/images/info/data-collection.png'}
                alt="FeaturesIcon"
              />
              <PageTab
                useScroll={!!mobile}
                tabList={[
                  'Device managements',
                  'Data collection',
                  'Configuration management',
                  'Comman execution',
                ]}
                renderingByIndex={(selectedTabIndex) => {
                  switch (selectedTabIndex) {
                    case 0:
                      return (
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          Manage devices and their credentials individually or
                          in groups. Maintain a register of digital twins so all
                          the device's data will be available even when it's
                          offline.
                        </HeroP>
                      );

                    case 1:
                      return (
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          Reliably collect data on a large scale. Configure data
                          processing pipelines. Optimize network and battery use
                          with batching. Okomo Enterprise Enegry Platform is
                          pre-integrated with production-ready databases like
                          Cassandra, MongoDB, InfluxDB, and others.
                        </HeroP>
                      );

                    case 2:
                      return (
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          Configuration management is essential for controlling
                          the device behavior, managing data processing
                          parameters, edge analytics, feature flagging, and
                          other functions. For example, you can configure an air
                          conditioner with the desired temperature, set
                          reporting period for a smart meter, etc.
                        </HeroP>
                      );

                    case 3:
                      return (
                        <HeroP style={{ marginTop: '2.5rem' }}>
                          Command execution allows you to deliver messages with
                          the arbitrary payload to connected devices, execute
                          commands, and receive near-real-time responses. For
                          example, you can remotely check the current
                          temperature on a home thermostat, open a vehicle
                          trunk, and so on.
                        </HeroP>
                      );

                    default:
                      return null;
                  }
                }}
              />
              <Button
                style={{ padding: '1rem', marginTop: '5rem' }}
                variant="contained"
                wideMobile
              >
                Learn more
              </Button>
            </div>
          </div>
        </div>
      </section>
    );
  },
);

FeaturesImagePage.propTypes = propTypes;
FeaturesImagePage.defaultProps = defaultProps;

export default FeaturesImagePage;
