import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';

export const postNewMaintenanceLog = async (newMaintenanceInfor) => {
  try {
    const formData = new FormData();
    formData.append('title', newMaintenanceInfor.title);
    formData.append('deviceId', newMaintenanceInfor.deviceId);
    formData.append('workerId', newMaintenanceInfor.workerId);
    formData.append('reporterId', newMaintenanceInfor.reporterId);
    formData.append('serviceType', newMaintenanceInfor.serviceType);
    formData.append('timeZone', newMaintenanceInfor.timeZone);
    formData.append('reportDate', newMaintenanceInfor.reportDate);
    formData.append('status', newMaintenanceInfor.status);

    if (newMaintenanceInfor.dispatchDate)
      formData.append('dispatchDate', newMaintenanceInfor.dispatchDate);

    if (newMaintenanceInfor.issueDescription)
      formData.append('issueDescription', newMaintenanceInfor.issueDescription);

    if (newMaintenanceInfor.actionTaken)
      formData.append('actionTaken', newMaintenanceInfor.actionTaken);

    if (newMaintenanceInfor.description)
      formData.append('description', newMaintenanceInfor.description);

    // if (newMaintenanceInfor.maintenanceImage.length) {
    //   newMaintenanceInfor.maintenanceImage.forEach((eachImageData) => {
    //     formData.append(
    //       'maintenanceImage',
    //       imageToBlob(eachImageData.image),
    //       eachImageData.name,
    //     );
    //   });
    // }

    const res = await axios.post('evss/device/maintenance-log', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getAllMaintenanceLog = async (requestInfor) => {
  const res = await axios.get('evss/device/maintenance-log', {
    params: {
      'device-id': requestInfor?.deviceId || null,
      'report-date[start]': requestInfor?.reportStartDate || null,
      'report-date[end]': requestInfor?.reportEndDate || null,
      'dispatch-date[start]': requestInfor?.dispatchStartDate || null,
      'dispatch-date[end]': requestInfor?.dispatchEndDate || null,
      'complete-date[start]': requestInfor?.completeStartDate || null,
      'complete-date[end]': requestInfor?.completeEndDate || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return res.data;
};

export const getMaintenanceLogById = async (requestInfor) => {
  const res = await axios.get('evss/device/maintenance-log', {
    params: {
      id: requestInfor?.logId,
      'device-id': requestInfor?.deviceId || null,
      'report-date[start]': requestInfor?.reportStartDate || null,
      'report-date[end]': requestInfor?.reportEndDate || null,
      'dispatch-date[start]': requestInfor?.dispatchStartDate || null,
      'dispatch-date[end]': requestInfor?.dispatchEndDate || null,
      'complete-date[start]': requestInfor?.completeStartDate || null,
      'complete-date[end]': requestInfor?.completeEndDate || null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return res.data;
};

export const patchMaintenanceLog = async (modifyInfo) => {
  const res = await axios.patch(
    `evss/device/maintenance-log?id=${modifyInfo.id}`,
    { ...modifyInfo.data, timeZone: modifyInfo.timeZone },
  );
  return res;
};

export const deleteMaintenanceLog = async (logId) => {
  const res = await axios.delete(`evss/device/maintenance-log?id=${logId}`);
  return res;
};

export const postMaintenanceImage = async (dataInfor) => {
  const formData = new FormData();
  if (dataInfor.images?.length) {
    dataInfor.images.forEach((eachImageData) => {
      formData.append(
        'image',
        imageToBlob(eachImageData.image),
        eachImageData.name,
      );
    });
  }

  const res = await axios.post(
    `evss/image/device-maintenance-log?type-id=${dataInfor.logId}`,
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );

  return res;
};

export const deleteMaintenanceImage = async (imageId) => {
  const res = await axios.delete(`evss/image?id=${imageId}`);
  return res;
};

export const postPartsApi = async (requestInfor) => {
  const res = await axios.post('evss/parts', {
    name: requestInfor.name,
    category: requestInfor.category,
    manufacturingCompany: requestInfor.manufacturingCompany,
    unit: requestInfor.unit,
  });
  return res.data;
};

export const getPartsListApi = async (requestInfor) => {
  const res = await axios.get('evss/parts', {
    params: {
      name: requestInfor?.name || null,
      category: requestInfor?.category || null,
      'manufacturing-company': requestInfor?.manufacturingCompany || null,
    },
  });
  return res.data;
};

export const patchPartsApi = async (modifyInfo) => {
  const res = await axios.patch(`evss/parts?id=${modifyInfo.id}`, {
    ...modifyInfo.data,
  });
  return res;
};

export const deletePartsApi = async (id) => {
  const res = await axios.delete(`evss/parts?id=${id}`);
  return res;
};

export const postPartsMaintenanceApi = async (requestInfor) => {
  const res = await axios.post('evss/parts/maintenance', {
    deviceMaintenanceLogId: requestInfor.deviceMaintenanceLogId,
    partsId: requestInfor.partsId,
    count: requestInfor.count,
    reason: requestInfor.reason,
    description: requestInfor.description,
  });
  return res.data;
};

export const patchPartsMaintenanceApi = async (modifyInfo) => {
  const res = await axios.patch(`evss/parts/maintenance?id=${modifyInfo.id}`, {
    ...modifyInfo.data,
  });
  return res;
};

export const deletePartsMaintenanceApi = async (id) => {
  const res = await axios.delete(`evss/parts/maintenance?id=${id}`);
  return res;
};

export const postPartsAutocompleteApi = async (requestInfor) => {
  const res = await axios.post(
    `evss/parts/autocomplete?type=${requestInfor.type}`,
    {
      name: requestInfor.name,
    },
  );
  return res;
};

export const getPartsAutocompleteListApi = async (type) => {
  const res = await axios.get('evss/parts/autocomplete', {
    params: {
      type,
    },
  });
  return res.data;
};

export const deletePartsAutocompleteApi = async (requestInfor) => {
  const res = await axios.delete(`evss/parts/autocomplete`, {
    params: {
      id: requestInfor.id,
      type: requestInfor.type,
    },
  });
  return res;
};
