export const ParameterInfo = {
  control: {
    PVV: {
      title: 'PV 전압',
      unit: 'V',
    },
  },
};

export default ParameterInfo;

export const FirebaseConfig = ['control'];
