import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import Image from './elements/Image';
import { useTheme } from '@mui/material';
import VersionChip from './elements/VersionChip';

const Logo = ({
  className,
  displayVersion = true,
  forceColor = null,
  ...props
}) => {
  const theme = useTheme();
  const classes = classNames('brand', className);

  let logoColor;

  if (forceColor) {
    logoColor = forceColor;
  } else {
    logoColor = theme.palette.mode;
  }

  return (
    <div {...props} className={classes}>
      <h1 className="m-0">
        <Link to={props.linkUrl ? `/${props.linkUrl}` : '/'}>
          {displayVersion && (
            <VersionChip label={`V.${process.env.REACT_APP_VERSION}`} />
          )}
          {logoColor === 'light' ? (
            <Image
              // eslint-disable-next-line global-require
              src={require('src/pages/information/assets/images/evssForLight.png')}
              alt="Open"
              width={150}
            />
          ) : (
            <Image
              // eslint-disable-next-line global-require
              src={require('src/pages/information/assets/images/evssForDark.png')}
              alt="Open"
              width={150}
            />
          )}
        </Link>
      </h1>
    </div>
  );
};

export default Logo;
