import React from 'react';
import classNames from 'classnames';
import { FooterA } from '../../style';

const FooterNav = ({ className, ...props }) => {
  const classes = classNames('footer-nav', className);

  return (
    <nav {...props} className={classes}>
      <ul className="list-reset">
        <li>
          <FooterA href={'https://www.unirobotics.co.kr/'}>Contact</FooterA>
        </li>
        <li>
          <FooterA href={'https://www.unirobotics.co.kr/'}>About us</FooterA>
        </li>
        <li>
          <FooterA href={'https://www.unirobotics.co.kr/'}>FAQ's</FooterA>
        </li>
        <li>
          <FooterA href={'https://www.unirobotics.co.kr/'}>Support</FooterA>
        </li>
      </ul>
    </nav>
  );
};

export default FooterNav;
