import { styled, Box } from '@mui/material';

export const OuterWrapper = styled(Box)(
  () => `
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
    `,
);

export const InnerWrapper = styled(Box)(
  () => `
    position: absolute;
    inset: 0px;
    overflow-y: scroll;
    overflow-x : hidden;
    margin-bottom: -15px;

    // Chrome, Safari 스크롤 삭제
    &::-webkit-scrollbar {
      display: none;
    }

    // Firefox스크롤 삭제
    scrollbar-width: none;

    // IE 스크롤 삭제
    -ms-overflow-style: none;
    `,
);
