import * as Sentry from '@sentry/react';
import { Component } from 'react';
import UnexpectedErrorPage from 'src/pages/exception/UnexpectedErrorPage';
import ThemeProvider from '../../theme/ThemeProvider';
import { CssBaseline } from '@mui/material';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setContext('Fatal Error Info', {
        errorInfo,
      });
    });
    Sentry.captureException(error, {
      level: 'fatal',
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <ThemeProvider>
          <CssBaseline />
          <UnexpectedErrorPage />
        </ThemeProvider>
      );
    }

    return this.props.children;
  }
}
export default ErrorBoundary;
