import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';
import ScrollTop from 'src/hooks/useScrollTop';

import 'nprogress/nprogress.css';
import App from 'src/App';
import { SidebarProvider } from 'src/contexts/SidebarContext';
import * as serviceWorker from 'src/serviceWorker';
import { AuthProvider } from './contexts/FirebaseAuthContext';

import * as Sentry from '@sentry/react';
import ErrorBoundary from './components/ErrorBoundary';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          'localhost',
          process.env.REACT_APP_IP,
          process.env.REACT_APP_AUTH0_DOMAIN,
        ],
      }),
      new Sentry.Replay(),
    ],

    tracesSampleRate: 1.0, // Sentry에 보내는 트랜잭션의 샘플 비율 (0.0 ~ 1.0)
    replaysSessionSampleRate: 0.1, // entry SDK에서 세션 리플레이 기능의 샘플링 비율을 제어
    replaysOnErrorSampleRate: 1.0, // 오류가 발생한 세션 중 어느 정도의 비율로 리플레이 데이터를 수집할지 결정
  });
}

// 전역 에러 핸들러 설정
window.onerror = function (message, source, lineno, colno, error) {
  Sentry.withScope((scope) => {
    scope.setContext('Error Info', {
      message,
      source,
      lineno,
      colno,
    });
    Sentry.captureException(error);
  });
};

window.onunhandledrejection = function (event) {
  Sentry.captureException(event.reason);
};

ReactDOM.render(
  <HelmetProvider>
    <SidebarProvider>
      <BrowserRouter>
        <ScrollTop />
        <AuthProvider>
          <ErrorBoundary>
            <App />
          </ErrorBoundary>
        </AuthProvider>
      </BrowserRouter>
    </SidebarProvider>
  </HelmetProvider>,
  document.getElementById('root'),
);

serviceWorker.unregister();
