import {
  Box,
  styled,
  Typography,
  Card,
  Container,
  FormControlLabel,
  FormHelperText,
} from '@mui/material';

export const Content = styled(Box)(
  () => `
    display: flex;
    flex: 1;
    width: 100%;
    margin-top : 80px;
   
`,
);

export const MainContent = styled(Box)(
  () => `
  background-image: url("/static/images/placeholders/covers/evss-bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  display: flex;
  align-items: center;
  .phone_label {
    margin-top: 0.5rem;
  }
`,
);

export const SidebarWrapper = styled(Box)(
  ({ theme }) => `

  @media (min-width: 0px) { display : none }
  @media (min-width: 960px) { display : flex}

  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background: ${theme.colors.alpha.white[100]};
  width: 440px;
`,
);

export const SidebarContent = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: ${theme.spacing(6)};

  .logo_box {
    width : 300px;
    height: 50px;
    margin-top: auto;
  }
`,
);

export const CardImg = styled(Card)(
  ({ theme }) => `
    border-radius: 100%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid ${theme.colors.alpha.black[10]};
    transition: ${theme.transitions.create(['border'])};
    position: absolute;

    &:hover {
      border-color: ${theme.colors.primary.main};
    }
`,
);

export const TypographyH1 = styled(Typography)(
  ({ theme }) => `
    font-size: ${theme.typography.pxToRem(33)};
`,
);

export const FormWrapper = styled(Container)(
  ({ theme }) => `
  display: flex
  align-items: center;
  flex-direction: column;
  .text_content {
    font-size: 0.9rem;

    @media (max-width: 599px) {
      font-size: 0.7rem;
    }

    .MuiFormHelperText-root {
      font-size: 0.8rem;

      @media (max-width: 599px) {
        font-size: 0.7rem;
      }
    }

    .MuiInputBase-input {
      font-size: 0.8rem;

      @media (max-width: 599px) {
        font-size: 0.7rem;
      }
    }
  }

  .link_divider{
    display : inline-block;
    margin : 4.5px;
    font-size: 0.9rem;
    color :  ${theme.colors.primary.main};


    @media (max-width: 599px) {
      font-size: 0.7rem;
    }
  }

  .find_button {
    margin: 4.5px;
  }

  .info_link {
    margin-right: 10px;
  }

  .submit_button {
    margin-top: 27px;
  }

  .checkbox_form_label {
    width: 100%;
  }
  `,
);

export const ContentWrapper = styled(Card)`
  padding: 36px;
  width: 100%;
  max-width: 600px;

  .main_title {
    font-size: 2rem;
    margin-bottom: 9px;

    @media (max-width: 599px) {
      font-size: 1.5rem;
    }
  }

  .sub_title {
    margin-bottom: 27px;
    font-size: 1rem;
    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
  }
`;

export const FooterWrapper = styled(Box)`
  margin-top: 36px;
`;

export const CheckBoxWrapper = styled(FormControlLabel)`
  font-size: 1rem;

  .MuiSvgIcon-root {
    width: 25px;
    height: 25px;

    @media (max-width: 599px) {
      width: 15px;
      height: 15px;
    }
  }

  .MuiTypography-root {
    font-size: 0.9rem;

    @media (max-width: 599px) {
      font-size: 0.7rem;
    }
  }
`;

export const LanguageSwitcherWrapper = styled(Box)(
  () => `
  
  .MuiIconButton-root{
    float : right;
    margin : 5px;
  }
  padding : 5px;
  
`,
);

/* RecoverEmail , RecoverPassword, ResetPassword */

export const MainWrapper = styled(Box)`
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .MuiPaper-root {
    min-height: 400px;
  }

  .logo_box {
    margin-top: 50px;
    width: 250px;
    height: 60px;
    @media (max-width: 599px) {
      width: 200px;
      height: 50px;
    }
  }
`;

export const AuthFormWrapper = styled(Card)(
  () => `
  margin-top: 27px;
  padding: 36px;
  white-space: pre-line;
  .title {
    margin-bottom: 9px;
    font-size: 1.9rem;
    text-align: center;
    @media (max-width: 599px) {
      font-size: 1.5rem;
    }
  }

  .sub_title {
    margin-bottom: 27px;
    font-size: 1rem;
    text-align: center;

    @media (max-width: 599px) {
      font-size: 0.8rem;
    }
  }

  .find_button {
    margin-left: 4.5px;
  }

  .submit_button {
    margin-top: 27px;
  }

  .form_content {
    min-height: 250px;
    flex-direction: column;
    justify-content: center;
    align-items: center; 
  }

  .link_button {
    width: 100%;

    .MuiButtonBase-root{
      
      margin-top : 45px;
      width: 100%; 
    }
  }

  .text_content {
    font-size: 0.9rem;

    @media (max-width: 599px) {
      font-size: 0.7rem;
    }

    .MuiFormHelperText-root {
      font-size: 0.8rem;

      @media (max-width: 599px) {
        font-size: 0.7rem;
      }
    }

    .MuiInputBase-input {
      font-size: 0.8rem;

      @media (max-width: 599px) {
        font-size: 0.7rem;
      }
    }
  }

  .icon_content {
    .MuiInputBase-root{
      padding : 0;

      .MuiInputAdornment-root  {
        padding : 0;

        .MuiButton-root{
          padding : 14px;
          border : none;
       

          @media (max-width: 599px) {
            padding : 16px 8px;
            font-size: 0.5rem;
          }
        }
      }
    }
  }
  
  .main_content {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;

    .MuiTypography-root {
      font-size: 1.5rem;
      @media (max-width: 599px) {
        font-size: 0.9rem;
      }
    }
  }


    `,
);

export const LoginHelperText = styled(FormHelperText)(
  () => `
  margin-left: 4.5px;
`,
);

export const LoadingWrapper = styled(Box)(
  () => `
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`,
);

export const PhoneNumberField = styled(Box)(
  () => `
  display: flex;
  align-items: center;
  width : 100%;
 
  .MuiButtonBase-root {
    margin-top : 10px;
  }
 
  .MuiInputBase-root {
    padding-left : 0;


    .MuiInputAdornment-root {
      position : absolute;
      left : 15px;
    }

    .MuiInputBase-input{
      padding-left : 60px;

      @media (max-width: 599px) {
        padding-left : 50px;
      }
    }
  }
  `,
);
