import { makeAutoObservable, runInAction } from 'mobx';
import {
  postNewMaintenanceLog,
  getAllMaintenanceLog,
  getMaintenanceLogById,
  patchMaintenanceLog,
  deleteMaintenanceLog,
  postMaintenanceImage,
  deleteMaintenanceImage,
  getPartsListApi,
  postPartsMaintenanceApi,
  postPartsAutocompleteApi,
  getPartsAutocompleteListApi,
  postPartsApi,
  deletePartsMaintenanceApi,
  deletePartsAutocompleteApi,
  patchPartsMaintenanceApi,
  deletePartsApi,
  patchPartsApi,
} from 'src/api/maintenance';

class maintenanceStore {
  accountStore;

  alertStore;

  deviceMaintenanceHistories = [];

  partsList = [];

  categoryList = [];

  manufacturingCompanyList = [];

  unitList = [];

  maintenanceInfo = {
    id: null,
    userId: null,
    title: null,
    deviceId: null,
    deviceData: {
      id: null,
      nickname: null,
      serialNumber: null,
      location: {
        id: null,
        name: null,
        type: null,
        address: null,
        addressDetail: null,
        lat: null,
        lon: null,
        zipCode: null,
      },
      type: {
        id: null,
        name: null,
      },
    },
    reporter: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countryCode: null,
      code: null,
    },
    worker: {
      id: null,
      name: null,
      email: null,
      phone: null,
      countryCode: null,
      code: null,
    },
    serviceType: null,
    reportDate: null,
    dispatchDate: null,
    issueDescription: null,
    actionTaken: null,
    description: null,
    images: [],
  };

  startIndex = 0;

  endIndex = 1;

  selectedReportDate = [null, null];

  selectedDispatchDate = [null, null];

  selectedCompleteDate = [null, null];

  constructor({ accountStore, alertStore }) {
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
  }

  initializeMaintenanceInfo = () => {
    this.maintenanceInfo = {
      id: null,
      userId: null,
      title: null,
      deviceId: null,
      deviceData: {
        id: null,
        nickname: null,
        serialNumber: null,
        location: {
          id: null,
          name: null,
          type: null,
          address: null,
          addressDetail: null,
          lat: null,
          lon: null,
          zipCode: null,
        },
        type: {
          id: null,
          name: null,
        },
      },
      reporter: {
        id: null,
        name: null,
        email: null,
        phone: null,
        countrtCode: null,
        code: null,
      },
      worker: {
        id: null,
        name: null,
        email: null,
        phone: null,
        countrtCode: null,
        code: null,
      },
      serviceType: null,
      reportDate: null,
      dispatchDate: null,
      issueDescription: null,
      actionTaken: null,
      description: null,
      images: [],
    };
  };

  createNewDeviceMaintenanceLog = async (newLog) => {
    try {
      const id = await postNewMaintenanceLog(newLog).then((res) => {
        if (res?.code) throw res;
        this.alertStore.setAlertOpen(
          'success',
          '새로운 수리 이력 추가가 완료되었습니다.',
        );
        return true;
      });
      return id;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  initMaintenanceLog = () => {
    this.deviceMaintenanceHistories = [];
  };

  getMaintenanceLog = async (requestInfor) => {
    try {
      let res;
      if (requestInfor?.logId) {
        res = await getMaintenanceLogById({
          ...requestInfor,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.maintenanceInfo = res?.[0];
        });
      } else {
        res = await getAllMaintenanceLog({
          ...requestInfor,
          timeZone: this.accountStore.account.timeZone,
        });
        runInAction(() => {
          this.deviceMaintenanceHistories = res;
        });
      }
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  updateMaintenanceLog = async (modifyInfo) => {
    try {
      if (modifyInfo.deleteImage?.length) {
        const imageDeleteRes = await this.deleteMaintenanceImageById(
          modifyInfo.deleteImage,
        );

        if (imageDeleteRes !== true) throw imageDeleteRes;
      }

      if (modifyInfo.newImage?.length) {
        const imageCreateRes = await this.createNewMaintenanceImage({
          logId: modifyInfo.id,
          images: modifyInfo.newImage,
        });

        if (imageCreateRes !== true) throw imageCreateRes;
      }

      if (modifyInfo.deletePartsMaintenanceData?.length) {
        const partsMaintenanceCreateRes = await this.deletePartsMaintenance(
          modifyInfo.deletePartsMaintenanceData,
        );

        if (partsMaintenanceCreateRes !== true) throw partsMaintenanceCreateRes;
      }

      if (modifyInfo.editPartsMaintenanceData?.length) {
        const partsMaintenanceCreateRes = await this.editPartsMaintenance(
          modifyInfo.editPartsMaintenanceData,
        );

        if (partsMaintenanceCreateRes !== true) throw partsMaintenanceCreateRes;
      }

      if (modifyInfo.newPartsMaintenanceData?.length) {
        const partsMaintenanceCreateRes = await this.createPartsMaintenance({
          logId: modifyInfo.id,
          newParts: modifyInfo.newParts,
          newPartsMaintenance: modifyInfo.newPartsMaintenanceData,
        });

        if (partsMaintenanceCreateRes !== true) throw partsMaintenanceCreateRes;
      }

      await patchMaintenanceLog({
        ...modifyInfo,
      }).then((res) => {
        if (res) {
          this.alertStore.setAlertOpen('success', '이력 수정이 완료되었습니다');
        }
      });
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deleteMaintenanceLogById = async (selectedMaintenanceLogs, deviceId) => {
    try {
      const deleteMaintenanceLogs = selectedMaintenanceLogs.map(
        (manufacturerInfo) => {
          return deleteMaintenanceLog(manufacturerInfo.id);
        },
      );
      return Promise.all(deleteMaintenanceLogs)
        .then(() => {
          this.getMaintenanceLog({
            deviceId,
          });
          this.alertStore.setAlertOpen(
            'success',
            `고장 이력 삭제를 완료했습니다.`,
          );
          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return false;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  createNewMaintenanceImage = async (logInfor) => {
    try {
      return await postMaintenanceImage(logInfor).then((res) => {
        if (res?.code) throw res;
        this.alertStore.setAlertOpen('success', '사진 추가가 완료되었습니다');
        return true;
      });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteMaintenanceImageById = async (selectedMaintenanceImages) => {
    try {
      const deleteMaintenanceImages = selectedMaintenanceImages.map(
        (imageInfo) => {
          return deleteMaintenanceImage(imageInfo.id);
        },
      );
      return Promise.all(deleteMaintenanceImages)
        .then(() => {
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      return error;
    }
  };

  setSelectedReportDate = (date) => {
    this.selectedReportDate = date;
  };

  setSelectedDispatchDate = (date) => {
    this.selectedDispatchDate = date;
  };

  setSelectedCompleteDate = (date) => {
    this.selectedCompleteDate = date;
  };

  createParts = async (newInfor) => {
    try {
      let res;
      await postPartsApi(newInfor).then((newPartsId) => {
        if (newPartsId.code) throw newPartsId;
        res = newPartsId;
      });
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getPartsList = async (requestInfor) => {
    try {
      const res = await getPartsListApi(requestInfor);
      runInAction(() => {
        this.partsList = res;
      });
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  editParts = async (modifyInfo) => {
    try {
      const res = await patchPartsApi(modifyInfo);
      if (res?.code) {
        throw res;
      }
      this.alertStore.setAlertOpen('success', '부품 수정을 완료하였습니다');
      this.getPartsList();
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deleteParts = async (selectedPartsList) => {
    try {
      const tasks = selectedPartsList.map((eachPartsInfor) => {
        return deletePartsApi(eachPartsInfor.id);
      });
      return Promise.all(tasks)
        .then(() => {
          this.getPartsList();
          this.alertStore.setAlertOpen('success', '부품 삭제에 성공하였습니다');
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createPartsMaintenance = async (newInfor) => {
    try {
      const tasks = [];
      const registrationDoneList = [];
      // eslint-disable-next-line no-restricted-syntax
      for await (let eachInfor of newInfor.newPartsMaintenance) {
        if (eachInfor.partsId === 'registering') {
          const registeringInfor = newInfor.newParts.find(
            (eachPartsData) => eachPartsData.name === eachInfor.name,
          );

          const existingIndex = registrationDoneList.findIndex(
            (eachRegistrationData) =>
              eachRegistrationData.name === eachInfor.name,
          );

          if (existingIndex > -1) {
            await postPartsMaintenanceApi({
              deviceMaintenanceLogId: newInfor.logId,
              partsId: registrationDoneList[existingIndex].partsId,
              count: eachInfor.count,
              reason: eachInfor.reason,
              description: eachInfor.description,
            })
              .then((res) => {
                if (res === 'OK') tasks.push(true);
                else tasks.push(false);
              })
              .catch((error) => {
                this.alertStore.setAlertOpen('error', error);
                tasks.push(false);
              });
          } else {
            await this.createParts({
              name: eachInfor.name,
              category: registeringInfor.category,
              manufacturingCompany: registeringInfor.manufacturingCompany,
              unit: registeringInfor.unit,
            })
              .then(async (newParts) => {
                registrationDoneList.push({
                  name: eachInfor.name,
                  partsId: newParts.id,
                });
                await postPartsMaintenanceApi({
                  deviceMaintenanceLogId: newInfor.logId,
                  partsId: newParts.id,
                  count: eachInfor.count,
                  reason: eachInfor.reason,
                  description: eachInfor.description,
                }).then((res) => {
                  if (res === 'OK') tasks.push(true);
                  else tasks.push(false);
                });
              })
              .catch((error) => {
                this.alertStore.setAlertOpen('error', error);
                tasks.push(false);
              });
          }
        } else {
          await postPartsMaintenanceApi({
            deviceMaintenanceLogId: newInfor.logId,
            partsId: eachInfor.partsId,
            count: eachInfor.count,
            reason: eachInfor.reason,
            description: eachInfor.description,
          })
            .then((res) => {
              if (res === 'OK') tasks.push(true);
              else tasks.push(false);
            })
            .catch((error) => {
              this.alertStore.setAlertOpen('error', error);
              tasks.push(false);
            });
        }
      }
      return Promise.all(tasks)
        .then((allResults) => {
          if (allResults.findIndex((result) => result === false) > -1) {
            return false;
          }

          return true;
        })
        .catch((error) => {
          this.alertStore.setAlertOpen('error', error);
          return error;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  editPartsMaintenance = async (selectedMaintenanceList) => {
    try {
      const tasks = selectedMaintenanceList.map((eachMaintenanceInfor) => {
        return patchPartsMaintenanceApi(eachMaintenanceInfor);
      });
      return Promise.all(tasks)
        .then(() => {
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  deletePartsMaintenance = async (selectedMaintenanceList) => {
    try {
      const tasks = selectedMaintenanceList.map((eachMaintenanceId) => {
        return deletePartsMaintenanceApi(eachMaintenanceId);
      });
      return Promise.all(tasks)
        .then(() => {
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };

  createPartsAutocomplete = async (newInfor) => {
    try {
      let message;
      let type;
      switch (newInfor.type) {
        case 'category':
          message = '부품 종류 추가에 성공하였습니다';
          type = newInfor.type;
          break;

        case 'unit':
          message = '부품 단위 추가에 성공하였습니다';
          type = newInfor.type;
          break;

        case 'manufacturing-company':
        case 'manufacturingCompany':
          message = '부품 업체 추가에 성공하였습니다';
          type = 'manufacturing-company';
          break;

        default:
          break;
      }

      await postPartsAutocompleteApi({ type, name: newInfor.name }).then(
        (res) => {
          if (res?.code) throw res;
        },
      );

      this.getPartsAutocompleteList(type);
      this.alertStore.setAlertOpen('success', message);
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  getPartsAutocompleteList = async (type) => {
    try {
      const res = await getPartsAutocompleteListApi(type);
      runInAction(() => {
        switch (type) {
          case 'category':
            this.categoryList = res;
            break;

          case 'unit':
            this.unitList = res;
            break;

          case 'manufacturing-company':
            this.manufacturingCompanyList = res;
            break;

          default:
            break;
        }
      });
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return false;
    }
  };

  deletePartsAutocomplete = async (selectedAutocompleteList) => {
    try {
      let type;

      const tasks = selectedAutocompleteList.map((eachAutocomplete) => {
        type = eachAutocomplete.type;
        return deletePartsAutocompleteApi(eachAutocomplete);
      });
      return Promise.all(tasks)
        .then(() => {
          let message;

          switch (type) {
            case 'category':
              message = '부품 종류 삭제에 성공하였습니다';
              break;

            case 'unit':
              message = '부품 단위 삭제에 성공하였습니다';
              break;

            case 'manufacturing-company':
              message = '부품 업체 삭제에 성공하였습니다';
              break;

            default:
              break;
          }

          this.getPartsAutocompleteList(type);
          this.alertStore.setAlertOpen('success', message);
          return true;
        })
        .catch((error) => {
          return error;
        });
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      return error;
    }
  };
}

export default maintenanceStore;
