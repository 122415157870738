import { makeAutoObservable } from 'mobx';
import { validationMessage } from 'src/utils/validationMessage';

class AlertStore {
  mode = 'snackbar';

  alertOpen = false;

  type = '';

  message = '에러가 발생했습니다.';

  interpolation = null;

  closeFunc = null;

  linkTo = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAlertClose = () => {
    this.alertOpen = false;
    if (this.closeFunc) this.closeFunc();
  };

  setAlertOpen = (
    type,
    message,
    extraOptions = {
      interpolation: null,
      mode: 'snackbar',
      linkTo: null,
      closeFunc: () => {},
    },
  ) => {
    if (typeof message === 'object' && message.name !== 'AxiosError') {
      return;
    }

    this.alertOpen = true;
    this.type = type;
    if (type === 'error' && !extraOptions.interpolation) {
      // 단순 errorCode 메시지만 띄우면 되는 경우
      if (typeof message === 'object') {
        const errorCode = message.response?.data?.[0]?.code;
        this.message = validationMessage(errorCode);
        this.interpolation = { errorCode: errorCode };
      }
      // error이지만, errorCode없이 메시지를 띄우는 경우
      else {
        this.message = message;
      }
    } else {
      // interpolation 처리가 필요한 경우
      this.message = message;
      this.interpolation = extraOptions?.interpolation;
    }
    this.linkTo = extraOptions?.linkTo;
    this.mode = extraOptions?.mode;
    this.closeFunc = extraOptions?.closeFunc;
  };
}

export default AlertStore;
