import axios from 'src/utils/axios';

export const getReverseGeocodingData = async (
  lat,
  lon,
  language,
  resultType = null,
) => {
  try {
    const res = await axios.get('evss/geo/reverse', {
      params: {
        lat,
        lon,
        language,
        resultType,
      },
    });

    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getSidoListApi = async () => {
  try {
    const res = await axios.get('evss/location/sido/list');

    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getSigunListApi = async (sidoId) => {
  try {
    const res = await axios.get('evss/location/sigun/list', {
      params: {
        'sido-id': sidoId,
      },
    });

    return res.data;
  } catch (error) {
    return error.response;
  }
};
