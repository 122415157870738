import { HeaderButtonsBox } from '../style';
import Clock from '../../../Clock';

const HeaderButtons = () => {
  return (
    <HeaderButtonsBox>
      <Clock />
    </HeaderButtonsBox>
  );
};

export default HeaderButtons;
