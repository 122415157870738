import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/mobx-store';
import SnackbarAlert from './SnackbarAlert';
import ModalAlert from './ModalAlert';
import { useTranslation } from 'react-i18next';

const Alert = observer(() => {
  const { t } = useTranslation();
  const { alertStore } = useStore();
  const { interpolation, message, mode } = alertStore;

  const messageWithInterpolation = (interpolation) => {
    if (!interpolation) {
      return t(message, null);
    }

    const translatedInterpolation = {};
    Object.entries(interpolation).forEach(([key, value]) => {
      translatedInterpolation[key] = t(value);
    });
    return t(message, translatedInterpolation);
  };

  const renderAlert = () => {
    if (mode === 'modal') {
      return <ModalAlert message={messageWithInterpolation(interpolation)} />;
    }
    return <SnackbarAlert message={messageWithInterpolation(interpolation)} />;
  };

  return renderAlert();
});

export default Alert;
