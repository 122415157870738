import { OuterWrapper, InnerWrapper } from './style';

const CustomScrollbar = ({ children }) => {
  return (
    <OuterWrapper>
      <InnerWrapper>{children}</InnerWrapper>
    </OuterWrapper>
  );
};

export default CustomScrollbar;
