import axios from 'src/utils/axios';

export const getDashboardData = async () => {
  const response = await axios.get(`/evss/dashboard/connection`);
  return response.data;
};

export const getDashboardLogData = async (
  checkedAlertValue,
  selectedDate,
  timezone,
) => {
  const response = await axios.get(`evss/log`, {
    params: {
      start: selectedDate.start,
      end: selectedDate.end,
      'alert-type': checkedAlertValue.join(','),
      'time-zone': timezone,
      limit: selectedDate.start && selectedDate.end ? null : 100,
    },
  });
  return response.data;
};
