import { makeAutoObservable, runInAction } from 'mobx';
import { onSnapshot } from 'firebase/firestore';
import { getBigQueryApiResult, getFirestoreQueryResult } from 'src/api/device';
import {
  getDrawingInfo,
  patchDrawingDevice,
  deleteDrawingInfo,
  postDrawingInfo,
  patchDrawingInfo,
  getDataKey,
  postDrawingDevice,
  deleteDrawingDevice,
  getDrawingLogData,
  getDrawingConnectionApi,
} from 'src/api/deviceDrawing';
import {
  GetDataConfig,
  FirebaseConfig,
} from 'src/assets/parameter/StatusBoard';
import moment from 'moment';

class DeviceDrawingStore {
  accountStore;

  alertStore;

  deviceStore;

  utilStore;

  allDrawingList = [];

  detailData = {
    bigquery: {
      data: [],
    },
    firestore: {
      data: [],
    },
  };

  drawingInfo = {
    deviceDrawingId: null,
    name: null,
    imageUrl: null,
    deviceDrawingInfo: [],
  };

  deviceStatistics = {
    totalCount: null,
    activeCount: null,
    unConnectedCount: null,
    utilizationRate: null,
    badConnectionCount: null,
  };

  //   initializeDashboard = async () => {
  //     this.deviceList = [];

  //     this.deviceFilter = {
  //       type: '',
  //       location: '',
  //       tag: [],
  //     };
  //   };

  constructor({ accountStore, alertStore, deviceStore, utilStore }) {
    makeAutoObservable(this);
    this.accountStore = accountStore;
    this.alertStore = alertStore;
    this.deviceStore = deviceStore;
    this.utilStore = utilStore;
  }

  initDrawingInfo = () => {
    this.drawingInfo.id = null;
    this.drawingInfo.name = null;
    this.drawingInfo.imageUrl = null;
    this.drawingInfo.deviceDrawingInfo = null;
  };

  initStatusBoard = () => {
    if (this.drawingInfo.id) {
      this.getDrawingInfoById(this.drawingInfo.id).then((res) => {
        if (
          res &&
          this.detailData.bigquery.data.length !==
            this.drawingInfo.deviceDrawingInfo.length &&
          this.detailData.firestore.data.length !==
            this.drawingInfo.deviceDrawingInfo.length
        ) {
          this.detailData.bigquery.data = [];
          this.detailData.firestore.data = [];
          this.drawingInfo.deviceDrawingInfo.forEach(() => {
            runInAction(() => {
              this.detailData.bigquery.data.push([]);
              this.detailData.firestore.data.push([]);
            });
          });
        }
      });
    } else {
      this.getAllDrawingInfoByGroupId().then((res) => {
        if (res?.length)
          this.getDrawingInfoById(res[0].id).then((res) => {
            if (
              res &&
              this.detailData.bigquery.data.length !==
                this.drawingInfo.deviceDrawingInfo.length &&
              this.detailData.firestore.data.length !==
                this.drawingInfo.deviceDrawingInfo.length
            ) {
              this.detailData.bigquery.data = [];
              this.detailData.firestore.data = [];
              this.drawingInfo.deviceDrawingInfo.forEach(() => {
                runInAction(() => {
                  this.detailData.bigquery.data.push([]);
                  this.detailData.firestore.data.push([]);
                });
              });
            }
          });
      });
    }
  };

  // eslint-disable-next-line class-methods-use-this
  createDrawingInfo = async (drawingImage, drawingName) => {
    try {
      const res = await postDrawingInfo(drawingImage, drawingName);
      return res;
    } catch (error) {
      console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateDrawingInfo = async (drawingImage, drawingName) => {
    try {
      await patchDrawingInfo(drawingImage, drawingName);
      this.initStatusBoard();
      return true;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getAllDrawingInfoByGroupId = async () => {
    try {
      const res = await getDrawingInfo();
      if (res.length) return res;
      this.initDrawingInfo();
      return false;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getDrawingInfoById = async (drawingId) => {
    try {
      const res = await getDrawingInfo(drawingId);
      runInAction(() => {
        this.drawingInfo = res;
      });
      return true;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteDrawingInfoById = async (drawingId) => {
    try {
      await deleteDrawingInfo(drawingId);
      return true;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getBigQueryData = async () => {
    const dateFormatting = (type = 'current', dateFormat = null) => {
      const dateFormatResult = dateFormat
        ? `${this.accountStore.account.dateFormat.string}${dateFormat}`
        : this.accountStore.account.dateFormat.string;
      if (type.includes('+') || type.includes('-')) {
        const PERIOD = 0;
        const VALUE = 1;
        if (type.includes('+')) {
          const adjustment = type.split('+');
          return moment()
            .tz(this.accountStore.account.timeZone)
            .add(adjustment[VALUE], adjustment[PERIOD])
            .format(dateFormatResult);
        }
        const adjustment = type.split('-');
        return moment()
          .tz(this.accountStore.account.timeZone)
          .subtract(adjustment[VALUE], adjustment[PERIOD])
          .format(dateFormatResult);
      }

      if (type === 'start') {
        return moment()
          .tz(this.accountStore.account.timeZone)
          .startOf('month')
          .format(dateFormatResult);
      }
      if (type === 'end') {
        return moment()
          .tz(this.accountStore.account.timeZone)
          .endOf('month')
          .format(dateFormatResult);
      }
      if (type === 'todayStart') {
        return moment()
          .tz(this.accountStore.account.timeZone)
          .startOf('day')
          .format(`${dateFormatResult}THH:mm`);
      }
      if (type === 'todayEnd') {
        return moment()
          .tz(this.accountStore.account.timeZone)
          .endOf('day')
          .format(`${dateFormatResult}THH:mm`);
      }
      return moment()
        .tz(this.accountStore.account.timeZone)
        .format(dateFormatResult);
    };

    if (this.drawingInfo.deviceDrawingInfo.length) {
      this.drawingInfo.deviceDrawingInfo.forEach(
        async (eachDrawingData, deviceIndex) => {
          let dataKeyList = [];
          eachDrawingData.deviceDataKeyData.forEach((eachDataKeyConfig) => {
            if (eachDataKeyConfig.outputNeedCalc)
              dataKeyList.push(eachDataKeyConfig);
          });
          if (dataKeyList.length) {
            dataKeyList.forEach(async (eachKeyData) => {
              const getInfo =
                GetDataConfig[eachDrawingData.deviceTypeName][eachKeyData.name];
              await getBigQueryApiResult({
                deviceSerial: eachDrawingData.serial,
                start: dateFormatting(
                  getInfo.statDate.type,
                  getInfo.statDate.format,
                ),
                end: dateFormatting(
                  getInfo.endDate.type,
                  getInfo.endDate.format,
                ),
                type: eachKeyData.keyType,
                data: getInfo.parameter,
                period: getInfo.period,
                processType: getInfo.processType,
                timezone: this.accountStore.account.timeZone,
                limit: getInfo.limit,
                sortType: getInfo.sortType,
                includeNull: getInfo.includeNull,
                reverse: getInfo.reverse,
              }).then((bigqueryApiResponse) => {
                runInAction(() => {
                  if (Object.keys(bigqueryApiResponse).length) {
                    const prevData = this.detailData.bigquery.data[deviceIndex];

                    if (!prevData[eachKeyData.keyType]) {
                      prevData[eachKeyData.keyType] = {};
                    }
                    switch (eachKeyData.name) {
                      case 'DGE':
                        prevData[eachKeyData.keyType][eachKeyData.name] =
                          bigqueryApiResponse[getInfo.parameter][
                            moment().format('D') - 1
                          ][1];
                        break;

                      default:
                        prevData[eachKeyData.keyType][eachKeyData.name] =
                          bigqueryApiResponse[getInfo.parameter][0][1];

                        break;
                    }

                    this.detailData.bigquery.data[deviceIndex] = {
                      ...prevData,
                    };
                  }
                });
              });
            });
          }
        },
      );
    }
  };

  getFirestoreData = async () => {
    if (this.drawingInfo.deviceDrawingInfo.length) {
      this.drawingInfo.deviceDrawingInfo.forEach(
        async (eachDrawingData, deviceIndex) => {
          getFirestoreQueryResult({
            parameter: GetDataConfig[eachDrawingData.deviceTypeName],
            firebaseConfig: FirebaseConfig[eachDrawingData.deviceTypeName],
            typeId: eachDrawingData.deviceTypeId,
            serial: eachDrawingData.serial,
          }).then((firestoreQueryRes) => {
            firestoreQueryRes.forEach((byEachKeyDetailData) => {
              onSnapshot(
                byEachKeyDetailData,
                { includeMetadataChanges: true },
                (snapshot) => {
                  const dataCategory =
                    snapshot.query._query.path.segments[
                      snapshot.query._query.path.segments.length - 1
                    ];
                  const snapshotResult = snapshot.docs.map((doc) => {
                    if (doc.data().deviceId === eachDrawingData.serial) {
                      return {
                        id: doc.id,
                        docData: doc.data(),
                      };
                    }
                    throw new Error('incorrect device id');
                  });

                  runInAction(() => {
                    if (snapshotResult.length) {
                      const newData = snapshotResult.shift().docData.data;
                      const prevData =
                        this.detailData.firestore.data[deviceIndex];
                      if (!prevData) {
                        this.detailData.firestore.data.push({
                          [dataCategory]: newData,
                        });
                      } else {
                        this.detailData.firestore.data[deviceIndex][
                          dataCategory
                        ] = newData;
                      }
                    }
                  });
                },
              );
            });
          });
        },
      );
    }
    return true;
  };

  // eslint-disable-next-line class-methods-use-this
  createDrawingDevice = async (drawingId, dataInfo) => {
    try {
      await postDrawingDevice(drawingId, dataInfo);
      this.alertStore.setAlertOpen('success', '장비 추가에 성공하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  updateDrawingDevice = async (deviceDrawingId, dataInfo) => {
    try {
      await patchDrawingDevice(deviceDrawingId, dataInfo);
      this.alertStore.setAlertOpen('success', '장비 수정을 완료하였습니다');
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  deleteDrawingDeviceById = async (deviceDrawingId) => {
    try {
      await deleteDrawingDevice(deviceDrawingId);
      this.alertStore.setAlertOpen('success', `충전기 삭제를 완료했습니다.`);
      return true;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getAllDeviceDataKeyByTypeId = async (typeId) => {
    try {
      const res = await getDataKey(typeId);
      return res;
    } catch (error) {
      // console.error('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  // eslint-disable-next-line class-methods-use-this
  getDrawingLogDataByRegisteredDevice = async () => {
    try {
      const RegisteredDeviceLength = this.drawingInfo.deviceDrawingInfo.length;
      let deviceSerialList = '';
      this.drawingInfo.deviceDrawingInfo.forEach((testData, index) => {
        deviceSerialList = deviceSerialList.concat(testData.serial);

        if (RegisteredDeviceLength - 1 !== index) {
          deviceSerialList = deviceSerialList.concat(',');
        }
      });

      if (deviceSerialList === '') {
        return [];
      }

      const res = await getDrawingLogData(
        ['alert', 'confirm', 'warning', 'notice'],
        deviceSerialList,
        this.utilStore.getTimezone(),
      );

      let logTableRows = [];

      res.logData.map((data, index) => {
        return logTableRows.push({
          id: index + 1,
          date: data.timestamp,
          deviceType: data.deviceData.type,
          deviceName: data.deviceData.nickname,
          deviceSerial: data.deviceId,
          type: data.alertType,
          logType: data.logType,
          eventCode: data.eventCode,
          content: data.message,
        });
      });

      return logTableRows;
    } catch (error) {
      console.log('Error: ', error);
      return [500, { message: 'Encountered a server error' }];
    }
  };

  getDrawingConnection = async (drawingId) => {
    try {
      const res = await getDrawingConnectionApi(drawingId);
      runInAction(() => {
        this.deviceStatistics = res;
      });
      return true;
    } catch (error) {
      return [500, { message: 'Encountered a server error' }];
    }
  };
}

export default DeviceDrawingStore;
