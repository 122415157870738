import axios from 'axios';
import { firebaseAuth } from 'src/utils/firebase';
import { signOut } from 'firebase/auth';
import { resetStore } from 'src/mobx-store';

import * as Sentry from '@sentry/react';
import { SentryLoggingError } from 'src/utils/sentry';

const BASEURL = `${process.env.REACT_APP_IP}/api/`;

const axiosInstance = axios.create({
  baseURL: BASEURL,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    if (error.response.status === 401) {
      let originalToken =
        originalRequest?.headers?.Authorization?.split(' ')[1] || null;
      let localStorageToken = localStorage.getItem('accessToken');

      if (originalToken !== localStorageToken) {
        originalRequest.headers.Authorization = `Bearer ${localStorage.getItem(
          'accessToken',
        )}`;

        return axiosInstance(originalRequest);
      }

      const refreshedToken = await getRefreshAccessToken();
      if (refreshedToken) {
        originalRequest.headers.Authorization = `Bearer ${refreshedToken}`;

        return axiosInstance(originalRequest);
      }
    }

    Sentry.withScope((scope) => {
      scope.setContext('API Request Info', {
        url: originalRequest.url,
        method: originalRequest.method,
        status: error.response?.status,
        params: originalRequest.params,
        headers: originalRequest.headers,
      });

      scope.setContext('API Response Info', {
        data: JSON.stringify(error.response?.data, null, 2),
        headers: error.response?.headers,
      });

      scope.setFingerprint([
        originalRequest.method,
        error.response?.status,
        originalRequest.url,
      ]);

      Sentry.captureException(new SentryLoggingError(error));
    });
    return Promise.reject(error);
  },
);

async function getRefreshAccessToken() {
  try {
    const response = await firebaseAuth.currentUser.getIdToken(true);
    localStorage.setItem('accessToken', response);
    return response;
  } catch (error) {
    await signOut(firebaseAuth);
    localStorage.removeItem('accessToken');
    resetStore();
    return null;
  }
}

export default axiosInstance;
