import { Navigate } from 'react-router-dom';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const BuildingList = Loader(
  retryLazy(() => import('src/pages/location/BuildingList')),
);

const BuildingDetail = Loader(
  retryLazy(() => import('src/pages/location/BuildingDetail')),
);

const locationRoutes = [
  {
    path: '',
    element: <Navigate to="building" replace />,
  },
  {
    path: 'building',
    element: <BuildingList />,
  },
  {
    path: 'building/:id',
    element: <BuildingDetail />,
  },
];

export default locationRoutes;
