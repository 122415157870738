import Guest from 'src/components/Guest';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const LoginCover = Loader(retryLazy(() => import('src/pages/account/Login')));
// const LoginFirebaseAuth = Loader(
//   retryLazy(() =>
//     import('src/pages/account/Login/components/LoginFirebaseAuth'),
//   ),
// );

// const RegisterCover = Loader(
//   retryLazy(() => import('src/pages/account/Register')),
// );

// const RecoverEmail = Loader(
//   retryLazy(() => import('src/pages/account/RecoverEmail')),
// );

// const RecoverPassword = Loader(
//   retryLazy(() => import('src/pages/account/RecoverPassword')),
// );

const accountRoutes = [
  {
    path: 'login',
    element: (
      <Guest>
        <LoginCover />
      </Guest>
    ),
  },
];

export default accountRoutes;
