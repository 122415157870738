import moment from 'moment';
import { useStore } from 'src/mobx-store';
import { useTranslation } from 'react-i18next';
import { MomentClock } from './style';

const Clock = () => {
  const { accountStore } = useStore();
  const { account } = accountStore;
  const { i18n } = useTranslation();

  moment.locale(i18n.language);

  return (
    <MomentClock
      tz={account.timeZone}
      format={`${account.dateFormat.string} (dd) HH:mm`}
      interval={1000}
    />
  );
};

export default Clock;
