import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { HeroH1, HeroH3, HeroSpan, HeroP } from '../style';
import { observer } from 'mobx-react-lite';
import { Grid, useTheme, alpha, Button, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const ServicesPage = observer(
  ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
    const theme = useTheme();
    const mobile = useMediaQuery('(max-width: 940px)');

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm" style={{ marginTop: '-3rem' }}>
          <div className={innerClasses}>
            <div className="hero-content reveal-from-bottom">
              <HeroH3>
                <HeroSpan style={{ fontWeight: 'bold' }}>
                  B2B ENEGRY SERVICES
                </HeroSpan>
              </HeroH3>
              <HeroH1 className="mt-0 mb-8 ">Professional services</HeroH1>
              <HeroP style={{ marginTop: '3.5rem', marginBottom: '3.5rem' }}>
                As a full-service enegry company, we offer enegry development
                and integration services based on our Okomo Enterprise Enegry
                Platform and popular industry-specific enegry technologies. In
                addition to enegry, we have a deep expertise in Big Data, Cloud,
                Embedded, Blockchain, Machine Learning, and other modern
                domains. Okomo Enterprise Enegry Platform was initially created
                as one of the CyberVision, Inc projects and rapidly evolved to
                the point when we decided to found a separate company with a
                strict focus on the Okomo. Combining more than 25 years of
                experience from CyberVision and Okomo enegry we offer full-stack
                enegry implementation services to help you go to market fast.
              </HeroP>
              <HeroSpan>
                Feel free to contact us with your specific problem or need and
                we'll be glad to help you out!
              </HeroSpan>
              <ul style={{ marginTop: '2.5rem' }}>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  <span style={{ fontWeight: 'bold' }}>
                    Business case assessment
                  </span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  <span style={{ fontWeight: 'bold' }}>
                    Building a proof of concept
                  </span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  <span style={{ fontWeight: 'bold' }}>
                    Fulfilling the project milestones
                  </span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  <span style={{ fontWeight: 'bold' }}>
                    Rolling out the final solution
                  </span>
                </li>
                <li
                  style={{
                    display: 'flex',
                    color: theme.palette.text.primary,
                    background: alpha(theme.palette.primary.main, 0.13),
                    alignItems: 'center',
                    padding: '12px',
                    margin: mobile ? '0 0 16px -24px' : '0 0 16px -16px',
                    fontSize: mobile ? '1rem' : '1.5rem',
                    borderRadius: '4px',
                  }}
                >
                  <div>
                    <CheckCircleIcon
                      style={{
                        color: theme.colors.primary.main,
                        background: alpha(theme.palette.primary.main, 0.5),
                        borderRadius: '0.8rem',
                        borderWidth: '1rem',
                        marginRight: '1rem',
                      }}
                    />
                  </div>
                  <span style={{ fontWeight: 'bold' }}>
                    Post-production support
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <Grid
            container
            style={{
              width: mobile ? '100%' : '102%',
              background: alpha(theme.palette.primary.main, 0.13),
            }}
          >
            {mobile ? (
              <>
                <Grid item xs={12}>
                  <img
                    style={{
                      width: '35rem',
                      height: '14rem',
                    }}
                    src={'/static/images/info/laptop.png'}
                    alt="FeaturesIcon"
                  />
                </Grid>
                <Grid item xs={12}>
                  <HeroH3
                    style={{
                      marginTop: '2rem',
                    }}
                    className="mt-8 mb-8"
                  >
                    Ready to get started?
                  </HeroH3>
                  <HeroP
                    style={{
                      marginTop: '2rem',
                      marginBottom: '3.5rem',
                    }}
                  >
                    Create a free Okomo Cloud account or apply for a free trial
                    with the professional assistance.
                  </HeroP>
                  <Button
                    style={{ padding: '1rem', marginBottom: '2rem' }}
                    variant="contained"
                    wideMobile
                  >
                    Use Okomo for free
                  </Button>
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={6}>
                  <img
                    style={{
                      margin: '-2rem 0rem 0rem -2rem',
                      position: 'absolute',
                      width: '37.5rem',
                      height: '20rem',
                    }}
                    src={'/static/images/info/laptop.png'}
                    alt="FeaturesIcon"
                  />
                </Grid>
                <Grid item xs={6}>
                  <HeroH3
                    style={{
                      marginLeft: '2rem',
                    }}
                    className="mt-8 mb-8"
                  >
                    Ready to get started?
                  </HeroH3>
                  <HeroP
                    style={{
                      marginTop: '3.5rem',
                      marginBottom: '3.5rem',
                      marginLeft: '2rem',
                    }}
                  >
                    Create a free Okomo Cloud account or apply for a free trial
                    with the professional assistance.
                  </HeroP>
                  <Button
                    style={{ padding: '1rem', marginBottom: '2rem' }}
                    variant="contained"
                    wideMobile
                  >
                    Use Okomo for free
                  </Button>
                </Grid>
              </>
            )}
          </Grid>
        </div>
      </section>
    );
  },
);

ServicesPage.propTypes = propTypes;
ServicesPage.defaultProps = defaultProps;

export default ServicesPage;
