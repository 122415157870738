import React from 'react';
import { InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { PasswordFieldWrapper } from './style';

const PasswordField = ({ showPassword, handleToggle, ...rest }) => {
  return (
    <PasswordFieldWrapper
      type={showPassword ? 'text' : 'password'}
      InputProps={{
        shrink: true,
        endAdornment: (
          <InputAdornment onClick={handleToggle}>
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default PasswordField;
