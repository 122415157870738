import React from 'react';
import Portal from '@mui/base/Portal';
import { observer } from 'mobx-react-lite';
import { useStore } from 'src/mobx-store';
import { SnackbarWrapper, AlertWrapper } from './style';

const SnackbarAlert = observer(({ message }) => {
  const { alertStore } = useStore();
  const { type, setAlertClose } = alertStore;

  const snackbarOption = {
    className: '',
    durationTime: 3000,
  };

  const Alert = React.forwardRef(function Alert(props, ref) {
    return (
      <AlertWrapper elevation={6} ref={ref} variant="filled" {...props}>
        {props.children}
      </AlertWrapper>
    );
  });

  return (
    <Portal>
      <SnackbarWrapper
        open
        onClick={setAlertClose}
        className={snackbarOption.className}
        autoHideDuration={snackbarOption.durationTime}
        disableWindowBlurListener
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        onClose={setAlertClose}
      >
        <Alert onClose={setAlertClose} severity={type}>
          {message}
        </Alert>
      </SnackbarWrapper>
    </Portal>
  );
});

export default SnackbarAlert;
