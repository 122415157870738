import axios from 'src/utils/axios';

export const getCurrentWeatherData = async (lat, lon, language) => {
  try {
    const res = await axios.get('/evss/weather/current', {
      params: {
        lat,
        lon,
        language,
      },
    });
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getForecastWeatherData = async (lat, lon) => {
  try {
    const res = await axios.get('/evss/weather/forecast', {
      params: {
        lat,
        lon,
      },
    });
    return res.data;
  } catch (error) {
    return error.response;
  }
};

export const getGeocodeData = async (lat, lon, language) => {
  try {
    const res = await axios.get('/evss/geo/cityName', {
      params: {
        lat,
        lon,
        language,
      },
    });
    return res.data;
  } catch (error) {
    return error.response;
  }
};
