import {
  IconButton,
  Avatar,
  styled,
  alpha,
  Box,
  Typography,
} from '@mui/material';

export const DataIcon = styled(Avatar)(
  ({ theme }) => `
        margin: ${theme.spacing(0, 0, 1, -0.5)};
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: ${theme.spacing(1)};
        padding: ${theme.spacing(0.5)};
        border-radius: 60px;
        height: ${theme.spacing(5.5)};
        width: ${theme.spacing(5.5)};
        background: ${
          theme.palette.mode === 'dark'
            ? theme.colors.alpha.trueWhite[30]
            : alpha(theme.colors.alpha.black[100], 0.07)
        };
        color: ${theme.colors.primary.main};
        img {
          background: ${theme.colors.alpha.trueWhite[100]};
          padding: ${theme.spacing(0.5)};
          display: block;
          border-radius: inherit;
          height: ${theme.spacing(4.5)};
          width: ${theme.spacing(4.5)};
        }
    `,
);

export const SectionHeading = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
        padding: ${theme.spacing(2, 2, 0)};
`,
);

export const IconButtonWrapper = styled(IconButton)(
  ({ theme }) => `
  width: ${theme.spacing(4)};
  height: ${theme.spacing(4)};
  border-radius: ${theme.general.borderRadiusLg};
  background: ${alpha(theme.colors.error.main, 0.1)};
  margin-left: 0rem;
  margin-right: 18px;
  transition: ${theme.transitions.create(['background'])};
  &:hover {
    background: ${alpha(theme.colors.error.main, 0.2)};
  },
`,
);

export const CountryIconWrapper = styled('div')(
  () => `
  width: 100%;
  height: 100%;
  display: flex;
  vertical-align: middle;
  svg {
    width : 26px;
  }
`,
);

export const ModalWrapper = styled(Box)`
  width: 240px;

  .button_box {
    padding: 1rem;
    svg {
      width: 26px;
      margin-right: 0.25rem;
    }

    .list_text {
      padding-left: 0.5rem;
    }
  }
`;
