import { Navigate } from 'react-router-dom';
import { Loader, retryLazy } from 'src/utils/lazyUtil';

const Jaeyoon = Loader(retryLazy(() => import('src/pages/playground/Jaeyoon')));

const playgroundRoutes = [
  {
    path: '',
    element: <Navigate to="jaeyoon" replace />,
  },
  {
    path: 'jaeyoon',
    element: <Jaeyoon />,
  },
];

export default playgroundRoutes;
