import { styled, Snackbar, Dialog } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

export const SnackbarWrapper = styled(Snackbar)(
  `
  &.popup{
    position: fixed;
    z-index: 9000;
    right: 0;
    width: 100%;
    height: 100%;
    bottom :0;
    background: rgba(0, 0, 0, 0.5);


    .MuiPaper-root{
      position: fixed;
      bottom : 35px;
      width : 100%;
      max-width : 500px;

      @media(max-width : 599px) {
        bottom:20px;
      }
    }
  }
  
`,
);

export const AlertWrapper = styled(MuiAlert)(
  `
 width: 500px;
 align-items: center;
 white-space: pre-line;

 & .MuiAlert-action {
   padding : 0;
 }
`,
);

const timelineMessageColors = {
  alert: 'crimson',
  error: 'crimson',
  warning: 'darkorange',
  confirm: 'limeGreen',
  success: 'limeGreen',
  notice: '#5e90cc',
};

export const AlertDialog = styled(Dialog)(
  ({ type }) => `
    align-items: center;
    text-align: center;
    white-space: pre-line;

    .alert_type_icon {
      display: flex;
      height: 60px;
      width: 60px;
      font-size: 30px;
      border-color: ${timelineMessageColors[type]};
      box-shadow : 0 1px 3.5px 0 rgba(0, 0, 0, 1), 0 1px 5px 0 ${timelineMessageColors[type]};
      margin : auto;
      border-radius: 50%;
      border-width : 2px;
      justify-content: center;
      align-items: center;
      margin-top : 4px;
      margin-bottom : 16px;
   }

    .alert_message {
      font-size: 20px;
      font-weight: bold;
      margin-bottom : 8px;
    }
`,
);
