import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import { HeroH1, HeroSpan, HeroButton, HeroButtonGroup, HeroP } from '../style';
import { observer } from 'mobx-react-lite';
// import { useTranslation } from 'react-i18next';
import { Link } from '@mui/material';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const Hero = observer(
  ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props
  }) => {
    // const { t } = useTranslation();

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    return (
      <section {...props} className={outerClasses}>
        <div className="container-sm">
          <div className={innerClasses}>
            <div className="hero-content reveal-from-bottom">
              <HeroH1 className="mt-0 mb-8 ">
                Use our{' '}
                <HeroSpan>
                  modulized systems111111111111111111111111111111111111111111111
                </HeroSpan>
              </HeroH1>
              <HeroH1 className="mt-0 mb-16">
                for your <HeroSpan>all services</HeroSpan>
              </HeroH1>
              <div className="container-xs">
                <HeroP>Make your services effective</HeroP>
                <div className="reveal-from-bottom" data-reveal-delay="450">
                  <Link href={'/account/register'}>
                    <HeroButtonGroup>
                      <HeroButton wideMobile>Live preview</HeroButton>
                    </HeroButtonGroup>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  },
);

Hero.propTypes = propTypes;
Hero.defaultProps = defaultProps;

export default Hero;
