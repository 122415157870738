import { Box, styled } from '@mui/material';

export const MainContent = styled('div')(
  () => `
      height: 100%;
      display: flex;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .coming_soon_container {
        margin-top: 60px;
        
        .MuiCard-root {
          text-align: center;
          padding : 36px;
        }

        .MuiTypography-root {
          margin-top: 30px;
          margin-bottom: 30px;
        }

        @media (max-width: 599px) {
          margin-top: 20px;
          margin-bottom: 20px;
          
          img {
            height: 150px;
          }

          .description {
            white-space: pre-wrap;
          }
        }
      }


      .card_box {
          margin-top : 27px;
          padding : 36px;
      }

      .title {
        font-size: 2rem;
        margin-top : 18px;
        margin-bottom : 18px;
        @media (max-width: 599px) {
            font-size: 1.5rem;
        }
      }
     
    .text_content {
        font-size: 0.9rem;
        white-space: pre-line;
        @media (max-width: 599px) {
            font-size: 0.7rem;
        }
     }   
        
      .main_image{
        height : 200px;
        @media (max-width: 599px) {
          height: 180px;
        }
      }

      .network_error_img {
        @media (max-width: 599px) {
          width: 350px;
        }
      }

      .logo_box {
        margin-top: 50px;
        width: 250px;
        height: 60px;
        @media (max-width: 599px) {
          width: 200px;
          height: 50px;
        }
      }
  `,
);

export const LanguageSwitcherWrapper = styled(Box)(
  () => `
  
  .MuiIconButton-root{
    float : right;
    margin : 5px;
  }
  padding : 5px;
  
`,
);
