import { makeAutoObservable } from 'mobx';
import {
  getCurrentWeatherData,
  getForecastWeatherData,
  getGeocodeData,
} from 'src/api/weather';

class weatherStore {
  alertStore;

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  getCurrentWeatherDataByCoordinates = async (lat, lon, language) => {
    try {
      const res = await getCurrentWeatherData(lat, lon, language);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  getForecastDataByCoordinates = async (lat, lon) => {
    try {
      const res = await getForecastWeatherData(lat, lon);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  getGeocodeDataByCoordinates = async (lat, lon, language) => {
    try {
      const res = await getGeocodeData(lat, lon, language);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };
}

export default weatherStore;
