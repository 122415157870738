import React from 'react';
import { Box, Card, styled, Button, Grid, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { CoverHeroH2, CoverP } from '../style';

const CoverImage = styled(Box)(
  () => `
      background-position: 0;
      background-repeat: no-repeat;
      background-size: cover;
      overflow: hidden;
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: 70vw;
      @media (max-width: 599px) {
        width: 135vw;
        height: 67%;
      }
  `,
);

const TemporaryInfoBox = styled(Card)(
  ({ theme }) => `
      padding: ${theme.spacing(4)};
      overflow: hidden;
      position: relative;
      height: 30rem;
      @media (max-width: 599px) {
        height: 46rem;
      }
`,
);

const CoverImagePage = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  pushLeft,
  ...props
}) => {
  const outerClasses = classNames(
    'features-tiles section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className,
  );
  const tablet = useMediaQuery('(max-width: 1172px)');
  const mobile = useMediaQuery('(max-width: 599px)');

  return (
    <section {...props} className={outerClasses}>
      <div className="reveal-from-bottom" data-reveal-delay="300">
        <TemporaryInfoBox>
          <CoverImage
            sx={{
              backgroundImage:
                'url("/static/images/placeholders/covers/info-bg.png")',
            }}
          />
          <div
            style={{
              marginTop: '-3rem',
              marginBottom: '3rem',
              marginLeft: 'auto',
              marginRight: 'auto',
              maxWidth: '1300px',
            }}
          >
            <CoverHeroH2
              style={{
                marginTop: mobile && '1.5rem',
                marginLeft: '0.025rem',
              }}
            >
              Start building yor solution
            </CoverHeroH2>
            <CoverHeroH2 style={{ marginTop: mobile ? '-2.5rem' : '-1rem' }}>
              with Okomo Energy Platform
            </CoverHeroH2>
            {tablet ? (
              mobile ? (
                <div style={{ paddingTop: '24rem' }}>
                  <CoverP>
                    Need help setting up your device? Apply for Engineering
                    support,
                  </CoverP>
                  <CoverP>
                    and our expert will assist you in configuring your device.
                  </CoverP>
                  <div style={{ padding: '2rem' }}>
                    <Grid container justifyContent={'center'}>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          style={{
                            minWidth: '7.5rem',
                            fontSize: '0.75rem',
                            padding: '0.85rem',
                          }}
                          variant="outlined"
                          wideMobile
                        >
                          REQUEST SUPPORT
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          fullWidth
                          style={{
                            minWidth: '7.5rem',
                            fontSize: '0.75rem',
                            padding: '0.85rem',
                            marginLeft: '1rem',
                          }}
                          variant="contained"
                          wideMobile
                        >
                          LIVE PREVIEW OKOMO
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              ) : (
                <div style={{ width: '19.5rem' }}>
                  <CoverP>
                    Need help setting up your device? Apply for Engineering
                    support,
                  </CoverP>
                  <CoverP>
                    and our expert will assist you in configuring your device.
                  </CoverP>
                  <div style={{ padding: '2rem', marginLeft: '-2rem' }}>
                    <Grid container>
                      <Grid item xs={6}>
                        <Button
                          style={{
                            minWidth: '7.5rem',
                            fontSize: '0.7rem',
                            padding: '1rem',
                          }}
                          variant="outlined"
                          wideMobile
                        >
                          REQUEST SUPPORT
                        </Button>
                      </Grid>
                      <Grid item xs={6}>
                        <Button
                          style={{
                            minWidth: '7.8rem',
                            fontSize: '0.7rem',
                            padding: '1rem',
                            marginLeft: '1rem',
                          }}
                          variant="contained"
                          wideMobile
                        >
                          LIVE PREVIEW OKOMO
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              )
            ) : (
              <>
                <CoverP>
                  Need help setting up your device? Apply for Engineering
                  support,
                </CoverP>
                <CoverP>
                  and our expert will assist you in configuring your device.
                </CoverP>
                <div className="reveal-from-bottom" data-reveal-delay="450">
                  <div style={{ marginTop: '2.5rem' }}>
                    <Button
                      style={{
                        padding: '0.75rem',
                        fontSize: '1.1rem',
                      }}
                      variant="outlined"
                      wideMobile
                    >
                      REQUEST SUPPORT
                    </Button>
                    <Button
                      style={{
                        padding: '0.75rem',
                        fontSize: '1.1rem',
                        marginLeft: '1rem',
                      }}
                      variant="contained"
                      wideMobile
                    >
                      LIVE PREVIEW OKOMO
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
        </TemporaryInfoBox>
      </div>
    </section>
  );
};

export default CoverImagePage;
