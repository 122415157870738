import { collection, query, where, orderBy, limit } from 'firebase/firestore';
import { firebaseDatabase } from 'src/utils/firebase.js';
import axios from 'src/utils/axios';

export const getDisplayCardData = async (serial) => {
  const response = await axios.get('evss/device-type/card', {
    params: {
      'device-serial': serial,
    },
  });
  return response.data;
};

export const getFieldControlKey = async (typeId) => {
  const response = await axios.get('evss/device-type/key', {
    params: {
      'key-type': 'control',
      type: typeId,
      'include-info': true,
    },
  });
  return response.data;
};

export const getFieldControlStatus = async (deviceSerial) => {
  const response = await axios.get('evss/device/control/loading-keys', {
    params: {
      'device-serial': deviceSerial,
    },
  });

  return response.data;
};

export const getBigQueryApiResult = (apiInfo) => {
  return new Promise((resolve) => {
    axios
      .get(`evss/device/data/${apiInfo.deviceSerial}`, {
        params: {
          start: apiInfo.start,
          end: apiInfo.end,
          type: apiInfo.type,
          data: apiInfo.data,
          period: apiInfo.period,
          'process-type': apiInfo.processType,
          'time-zone': apiInfo.timezone,
          limit: apiInfo.limit,
          'sort-type': apiInfo.sortType,
          'include-null': apiInfo.includeNull,
          reverse: apiInfo.reverse,
        },
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch(() => {
        resolve([]);
      });
  });
};

export const getFirestoreQueryResult = async (deviceInfo) => {
  if (!deviceInfo.parameter) return [];
  const dataTypeKeyList = deviceInfo.firebaseConfig;
  const indexesQueryReuslt = dataTypeKeyList.map((dataTypeKeyString) => {
    return query(
      collection(
        firebaseDatabase,
        `device/${deviceInfo.typeId}/${dataTypeKeyString}`,
      ),
      where('deviceId', '==', deviceInfo.serial),
      orderBy('serverTimestamp', 'desc'),
      limit(10),
    );
  });
  return indexesQueryReuslt;
};

export const getDevicesApi = async (requestInfor) => {
  const response = await axios.get(`evss/device/list`, {
    params: {
      id: requestInfor?.deviceId || null,
      type: requestInfor?.devicetype || null,
      'is-bookmarked': requestInfor?.isBookmarked || null,
      'location-id': requestInfor?.locationId || null,
      'tag-id': requestInfor?.tagId || null,
      'router-serial': requestInfor?.routerSerial || null,
      'switchboard-id': requestInfor?.switchboardId || null,
      'installed-date[start]': requestInfor?.installedStartDate || null,
      'installed-date[end]': requestInfor?.installedEndDate || null,
      'time-zone': requestInfor?.timeZone || null,
      'order-by': requestInfor?.orderBy || null,
    },
  });
  return response.data;
};

export const addBookmarkDevice = async (deviceId) => {
  await axios.post(`evss/device/bookmark`, null, {
    params: {
      id: deviceId,
    },
  });
};

export const deleteBookmarkDevice = async (deviceId) => {
  await axios.delete(`evss/device/bookmark`, {
    params: {
      id: deviceId,
    },
  });
};

export const getDeviceLogData = async (timelineSetting, serial, timezone) => {
  const response = await axios.get(`evss/log`, {
    params: {
      start: timelineSetting.selectedDate.start,
      end: timelineSetting.selectedDate.end,
      'alert-type': timelineSetting.checkedValues.join(','),
      'time-zone': timezone,
      'device-serial': serial,
      limit:
        timelineSetting.selectedDate.start && timelineSetting.selectedDate.end
          ? null
          : 20,
      'event-group':
        'deviceConnectionEvent,deviceTimelineEvent,routerInvalidData',
    },
  });
  return response.data;
};

export const verifyManyDevices = async (deviceList) => {
  const res = await axios.post(`/evss/device/many/verify`, {
    deviceData: deviceList,
  });
  return res;
};

export const createManyNewDeviceInfo = async (deviceList) => {
  const res = await axios.post(`/evss/device/many`, {
    deviceData: deviceList,
  });
  return res;
};

export const getLogEvenTypes = async () => {
  try {
    const res = await axios.get(`/evss/log/event/type`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllDeviceTypes = async () => {
  try {
    const res = await axios.get(`/evss/device-type`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const getAllDeviceTags = async () => {
  try {
    const res = await axios.get(`/evss/tag`);
    return res.data;
  } catch (error) {
    return error;
  }
};

export const createNewDeviceTag = async (tagName) => {
  try {
    const res = await axios.post(`/evss/tag`, {
      name: tagName,
    });
    return res.data.id;
  } catch (error) {
    return error;
  }
};

export const createNewDeviceInfo = async (newDeviceInfo) => {
  try {
    const res = await axios.post(`/evss/device`, {
      serial: newDeviceInfo.serialNumber,
      typeId: newDeviceInfo.typeId,
      nickname: newDeviceInfo.name,
      tagId: newDeviceInfo.tagId,
      deviceModelId: newDeviceInfo.product.id,
      timeZone: newDeviceInfo.timeZone,
      installedDate: newDeviceInfo.installedDate,
      installedEnvironment: newDeviceInfo.installedEnvironment,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const getStoredDeviceLocation = async () => {
  const response = await axios.get(`evss/device/location`);
  return response.data;
};

export const createNewDeviceLocation = async (newDeviceLocationInfo) => {
  try {
    const res = await axios.post(`/evss/device/location`, {
      name: newDeviceLocationInfo.name,
      address: newDeviceLocationInfo.address,
      addressDetail: newDeviceLocationInfo.addressDetail,
      zipCode: newDeviceLocationInfo.zipCode,
      lat: newDeviceLocationInfo.lat,
      lon: newDeviceLocationInfo.lon,
      sido: newDeviceLocationInfo.sido,
      sigun: newDeviceLocationInfo.sigun,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const updateDeviceLocationApi = async (modifyInfo) => {
  try {
    await axios.patch(`/evss/device/location?id=${modifyInfo.id}`, {
      ...modifyInfo.data,
    });
    return true;
  } catch (error) {
    return error;
  }
};

export const deleteDeviceLocationApi = async (locationId) => {
  await axios.delete(`/evss/device/location?id=${locationId}`);
};

export const deleteDevice = async (deviceId, companyPassword) => {
  await axios.post(`/evss/device/delete?id=${deviceId}`, {
    password: companyPassword,
  });
};

export const updateDevice = async (modifyInfo) => {
  try {
    const res = await axios.patch(`/evss/device?id=${modifyInfo.id}`, {
      ...modifyInfo.data,
    });
    return res;
  } catch (error) {
    return error;
  }
};

export const controlDevice = async (serial, password, controlData) => {
  try {
    const res = await axios.post(`/evss/device/control`, {
      serial: serial,
      password: password,
      controlData: controlData,
    });
    return res;
  } catch (error) {
    return error;
  }
};
export const getDeviceConnection = async (deviceSerial) => {
  try {
    const response = await axios.get(`evss/device/connection/${deviceSerial}`);
    const commStatus = response.data;
    return commStatus;
  } catch (error) {
    return error;
  }
};

export const getDevicesSensors = async (typeId) => {
  const response = await axios.get('evss/device-type/key', {
    params: {
      'key-type': 'sensors',
      type: typeId,
      'include-info': false,
      'graph-type': 'multiAxis',
    },
  });
  return response.data;
};

export const getWebsocketEventByAll = async (deviceSerial) => {
  const response = await axios.get(`evss/device/control/loading-keys`, {
    params: {
      'device-serial': deviceSerial,
    },
  });
  return response.data;
};

export const postDeviceDetailInforApi = async (newInfor) => {
  const response = await axios.post(`evss/device/detail-info`, {
    deviceId: newInfor.deviceId,
    detailData: newInfor.detailData,
  });
  return response;
};

export const patchDeviceDetailInfoApi = async (detailInfo) => {
  const response = await axios.patch(`evss/device/detail-info`, {
    deviceId: detailInfo.id,
    detailData: detailInfo.data,
  });
  return response.data;
};

export const deleteDeviceDetailInfoApi = async (detailInfo) => {
  const response = await axios.delete(`evss/device/detail-info`, {
    params: {
      id: detailInfo.dataId,
      'device-id': detailInfo.deviceId,
    },
  });
  return response.data;
};

export const getSlaveDataErrorApi = async (routerSerial) => {
  const response = await axios.get(`evss/router/check/slave-data`, {
    params: {
      'router-serial': routerSerial,
    },
  });
  return response.data;
};

export const postRouterSerialOfSlavesApi = async (newInfor) => {
  const response = await axios.post(`evss/router/accept/slave-data`, {
    routerSerial: newInfor.routerSerial,
    deviceId: newInfor.deviceId,
  });
  return response;
};

export const postSwitchboardApi = async (locationId, newInfor, deviceArray) => {
  const response = await axios.post(
    `evss/location/switchboard`,
    deviceArray.length
      ? {
          locationId,
          name: newInfor.name,
          description: newInfor.description,
          deviceId: deviceArray,
        }
      : {
          locationId,
          name: newInfor.name,
          description: newInfor.description,
        },
  );
  return response;
};

export const getSwitchboardApi = async (getInfor) => {
  const response = await axios.get(`evss/location/switchboard`, {
    params: {
      id: getInfor?.id,
      'location-id': getInfor?.locationId,
    },
  });
  return response.data;
};

export const patchSwitchboardApi = async (modifyInfo) => {
  const response = await axios.patch(
    `evss/location/switchboard?id=${modifyInfo.id}`,
    {
      ...modifyInfo.data,
    },
  );
  return response;
};

export const deleteSwitchboardApi = async (switchboardId) => {
  const response = await axios.delete(
    `evss/location/switchboard?id=${switchboardId}`,
  );
  return response;
};

export const postDeviceOnSwitchboardApi = async (switchboardId, deviceId) => {
  const response = await axios.post(
    `evss/location/switchboard/register/device`,
    {
      switchboardId: switchboardId,
      deviceId: `${deviceId}`,
    },
  );
  return response;
};

export const unpostDeviceOnSwitchboardApi = async (switchboardId, deviceId) => {
  const response = await axios.post(
    `evss/location/switchboard/unregister/device`,
    {
      switchboardId: switchboardId,
      deviceId: `${deviceId}`,
    },
  );
  return response;
};

export const getInitializeExcelFormApi = async () => {
  const response = await axios({
    url: `evss/excel/download/template/initial`,
    method: 'GET',
    responseType: 'blob',
  });
  return response;
};

export const getDeviceRegistExcelFormApi = async () => {
  const response = await axios({
    url: `evss/excel/download/template/device`,
    method: 'GET',
    responseType: 'blob',
    params: {
      'type-id': 7,
    },
  });
  return response;
};

export const getLocationRegistExcelFormApi = async () => {
  const response = await axios({
    url: `evss/excel/download/template/location`,
    method: 'GET',
    responseType: 'blob',
  });
  return response;
};

export const getSwitchboardRegistExcelFormApi = async (getInfor) => {
  const response = await axios({
    url: `evss/excel/download/template/switchboard`,
    method: 'GET',
    responseType: 'blob',
    params: {
      'location-id': getInfor.locationId,
    },
  });
  return response;
};

export const postInitializeExcelInsertApi = async (registInfor) => {
  const formData = new FormData();
  formData.append('excelFile', registInfor.excelFile);

  const res = await axios.post(
    'evss/excel/upload/insert/initialize',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res.data;
};

export const postDeviceExcelInsertApi = async (registInfor) => {
  const formData = new FormData();
  formData.append('typeId', 7);
  formData.append('excelFile', registInfor.excelFile);

  const res = await axios.post('evss/excel/upload/insert/device', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const postLocationExcelInsertApi = async (registInfor) => {
  const formData = new FormData();
  formData.append('excelFile', registInfor.excelFile);

  const res = await axios.post('evss/excel/upload/insert/location', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return res.data;
};

export const postSwitchboardExcelInsertApi = async (registInfor) => {
  const formData = new FormData();
  formData.append('locationId', registInfor.locationId);
  formData.append('excelFile', registInfor.excelFile);

  const res = await axios.post(
    'evss/excel/upload/insert/switchboard',
    formData,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return res.data;
};

export const getParkingControlHistoriesApi = async (requestInfor) => {
  const response = await axios.get('evss/parking-control', {
    params: {
      id: requestInfor?.id || null,
      'device-id': requestInfor?.deviceId || null,
      'violation-id': requestInfor?.violationId || null,
      'park-start[start]': requestInfor?.parkStartFrom
        ? `${requestInfor?.parkStartFrom}T00:00:00`
        : null,
      'park-start[end]': requestInfor?.parkStartTo
        ? `${requestInfor?.parkStartTo}T23:59:59`
        : null,
      'park-end[start]': requestInfor?.parkEndFrom
        ? `${requestInfor?.parkEndFrom}T00:00:00`
        : null,
      'park-end[end]': requestInfor?.parkEndTo
        ? `${requestInfor?.parkEndTo}T23:59:59`
        : null,
      'charge-start[start]': requestInfor?.chargeStartFrom
        ? `${requestInfor?.chargeStartFrom}T00:00:00`
        : null,
      'charge-start[end]': requestInfor?.chargeStartTo
        ? `${requestInfor?.chargeStartTo}T23:59:59`
        : null,
      'charge-end[start]': requestInfor?.chargeEndFrom
        ? `${requestInfor?.chargeEndFrom}T00:00:00`
        : null,
      'charge-end[end]': requestInfor?.chargeEndTo
        ? `${requestInfor?.chargeEndTo}T23:59:59`
        : null,
      'time-zone': requestInfor?.timeZone || null,
      'sort-type': requestInfor?.sortType || null,
      limit: requestInfor?.limit || null,
    },
  });
  return response.data;
};

export const getParkingControlViolationApi = async () => {
  const response = await axios.get('evss/parking-control/violation/list');
  return response.data;
};
