import axios from 'src/utils/axios';
import imageToBlob from 'src/utils/imageToBlob';
import { AUTH_STATUS } from 'src/assets/constants';

export const createNewUser = async (
  email,
  password,
  username,
  phone,
  language,
  countryCode,
  timeZone,
) => {
  const response = await axios.post(`evss/user-service/signup`, {
    email: email,
    newPassword: password,
    confirmPassword: password,
    username: username,
    phone: phone,
    language: language,
    countryCode: countryCode,
    timeZone: timeZone,
  });

  return response.data.accessToken;
};

export const generateVerificationEmail = async (
  language,
  type = 'email',
  email = null,
) => {
  await axios.post(`evss/user-service/verify/email`, {
    language: language,
    type: type,
    email: type === 'email' ? null : email,
  });
};

export const verifyEmail = async (verificationCode, type = 'email') => {
  await axios.patch(`evss/user-service/check/email`, {
    verificationCode: verificationCode,
    type: type,
  });
};

export const deleteSubEmail = async (password) => {
  await axios.post(`evss/user/sub-email/delete`, {
    password: password,
  });
};

export const getAccessToken = async (phone, password) => {
  const response = await axios.post('evss/user-service/login', {
    phone: phone,
    password: password,
  });

  const accessToken = response.data.accessToken;
  return accessToken;
};

export const generateChangePasswordEmail = async (email, language) => {
  await axios.post('evss/user-service/find/password', {
    email: email,
    language: language,
  });
};

export const updateUserPasswordByEmail = async (
  password,
  verificationCode,
  email,
) => {
  await axios.patch('evss/user-service/check/password', {
    newPassword: password,
    confirmPassword: password,
    verificationCode: verificationCode,
    email: email,
  });
};

export const getUserEmail = async (username, phone, countryCode) => {
  const response = await axios.get('evss/user-service/find/email', {
    params: {
      username: username,
      phone: phone,
      countryCode: countryCode,
    },
  });

  const userEmail = response.data.email;
  return userEmail;
};

export const getUserInfo = async () => {
  const response = await axios.get('evss/user/profile');
  const profileInfo = {
    ...response.data,
    isEmailVerified: response.data.isEmailVerified
      ? AUTH_STATUS.VERIFIED
      : AUTH_STATUS.UNVERIFIED,
  };
  return profileInfo;
};

export const updatePasswordByPassword = async (password, newPassword) => {
  await axios.patch('evss/user/password', {
    password: password,
    newPassword: newPassword,
    confirmPassword: newPassword,
  });
};

export const updateUserInfo = async (
  name,
  // phone,
  profileImage,
  countryCode,
  address,
  zipCode,
  addressDetail,
  lat,
  lon,
  sido,
  sigun,
  jobTitle,
  timeZone,
  dateFormatOrder,
  dateFormatDelimiter,
) => {
  const formData = new FormData();

  if (profileImage && profileImage !== -1) {
    formData.append('profileImage', imageToBlob(profileImage));
  } else if (profileImage === -1) {
    formData.append('profileImage', null);
  }
  formData.append('username', name);
  // formData.append('phone', phone);
  formData.append('countryCode', countryCode);
  if (address) {
    formData.append('location[address]', address);
    formData.append('location[lat]', lat);
    formData.append('location[lon]', lon);
    formData.append('location[zipCode]', zipCode);
    formData.append('location[addressDetail]', addressDetail);
    formData.append('location[sido]', sido);
    formData.append('location[sigun]', sigun);
  }
  formData.append('jobTitle', jobTitle);
  formData.append('timeZone', timeZone);
  formData.append('dateFormatOrder', dateFormatOrder);
  formData.append('dateFormatDelimiter', dateFormatDelimiter);

  await axios.patch('evss/user/profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const deleteUser = async (password) => {
  await axios.post('evss/user/withdrawal', {
    password: password,
  });
};

export const verifyCuKey = async (cuKey) => {
  const response = await axios.post(`evss/company/check/password`, {
    password: cuKey,
  });
  return response;
};
