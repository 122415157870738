import axios from 'src/utils/axios';

export const getDeviceSummaryData = async (type, locationId, tags) => {
  try {
    const res = await axios.get(
      `/evss/dashboard?type=${type}&location-id=${locationId}&tag-id=${tags}`,
    );
    return res.data;
  } catch (error) {
    return error.response;
  }
};
