import {
  Box,
  Card,
  Typography,
  Container,
  Divider,
  Button,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MainContent } from './style';
import NoDataRobot from 'src/assets/images/NoData.png';
import NotFound from 'src/assets/images/NotFound.png';
import NoImage from 'src/assets/images/NoImage.png';

import { useNavigate } from 'react-router-dom';

const ERRORMESSAGE = [
  {
    code: 'E400',
    title: '잘못된 요청입니다.',
    image: NoDataRobot,
  },
  {
    code: 'E404',
    title: '찾으려는 페이지의 데이터가 존재하지 않습니다.',
    image: NoDataRobot,
  },
  {
    code: 'E405',
    title: '찾으려는 페이지의 이미지가 존재하지 않습니다.',
    image: NoImage,
  },
];

const ErrorMessageCard = ({
  code = '',
  buttonText = '메인으로',
  mainContent = '동일한 오류가 반복되면 관리자에게 문의하세요.',
  subContent = '서비스 이용에 불편을 드려 죄송합니다.',
  clickEvent,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getMessageByType = (code) => {
    const errorMessage = ERRORMESSAGE.find((error) => error.code === code);

    if (errorMessage) {
      return {
        title: errorMessage.title,
        errorCode: errorMessage.code,
        image: errorMessage.image,
      };
    }

    return {
      title: '알 수 없는 오류가 발생하였습니다',
      errorCode: 'E000',
      image: NotFound,
    };
  };

  const handleClick = () => {
    if (clickEvent) {
      clickEvent();
    } else {
      navigate('/');
    }
  };

  const { title, errorCode, image } = getMessageByType(code);

  return (
    <MainContent>
      <Container maxWidth="md">
        <Box className="image_box" textAlign="center">
          <img alt="404" src={image} />
          <Typography className="title" variant="h2">
            {`${t(title)}\n(${errorCode})`}
          </Typography>
        </Box>
        <Container maxWidth="sm">
          <Card className="card_wrapper">
            <Typography
              className="text_content content"
              variant="h4"
              color="text.secondary"
              fontWeight="normal"
            >
              {t(subContent)}
            </Typography>
            <Typography
              className="text_content content"
              variant="h4"
              fontWeight="bold"
            >
              {t(mainContent)}
            </Typography>
            <Divider className="divider" />
            <Button
              className="text_content"
              onClick={handleClick}
              variant="outlined"
            >
              {t(buttonText)}
            </Button>
          </Card>
        </Container>
      </Container>
    </MainContent>
  );
};

export default ErrorMessageCard;
