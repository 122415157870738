import i18n from 'i18next';

import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enJSON from './translations/en';
import koJSON from './translations/ko';

const resources = {
  ko: { translation: koJSON },
  en: { translation: enJSON },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    keySeparator: '_',
    nsSeparator: '__',
    lng: localStorage.getItem('language') || 'ko',
    react: {
      useSuspense: true,
    },
    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', localStorage.getItem('i18nextLng'));
    }
  });

export default i18n;
