import { makeAutoObservable } from 'mobx';
import {
  getReverseGeocodingData,
  getSidoListApi,
  getSigunListApi,
} from 'src/api/location';

class locationStore {
  alertStore;

  sidoList = [];

  sigunList = [];

  constructor({ alertStore }) {
    makeAutoObservable(this);
    this.alertStore = alertStore;
  }

  getReverseGeocodingDataByCoordinates = async (
    lat,
    lon,
    language,
    resultType,
  ) => {
    try {
      const res = await getReverseGeocodingData(lat, lon, language, resultType);
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  getSidoList = async () => {
    try {
      const res = await getSidoListApi();
      const renamingLocationList = res.map((eachSidoData) => {
        switch (eachSidoData.name) {
          case '제주특별자치도':
            return { ...eachSidoData, name: '제주' };

          case '세종특별자치시':
            return { ...eachSidoData, name: '세종' };

          case '강원특별자치도':
            return { ...eachSidoData, name: '강원' };

          case '전북특별자치도':
            return { ...eachSidoData, name: '전북' };

          default:
            return eachSidoData;
        }
      });
      this.sidoList = renamingLocationList;
      return renamingLocationList;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };

  initSigunList = () => {
    this.sigunList = [];
  };

  getSigunList = async (id) => {
    try {
      const res = await getSigunListApi(id);
      this.sigunList = res;
      return res;
    } catch (error) {
      this.alertStore.setAlertOpen('error', error);
      throw error;
    }
  };
}

export default locationStore;
